.iconbs-s1,
.iconbs-s2,
.iconbs-s3 {
  height: 13px;
  width: 20px;
  position: relative;
  float: left;
  border: 1px solid #9b9fb1;
  text-align: center;
  font-size: 8px;
  font-weight: 500;
  color: #9b9fb1;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 2px;

  &:before {
    content: "S1";
    width: 16px;
    //height: 11px;
    position: relative;
    text-align: center;
    color: #9b9fb1;
  }
}
.iconbs-s2 {
  &:before {
    content: "S2";
  }
}
.iconbs-s3 {
  &:before {
    content: "S3";
  }
}

@font-face {
  font-family: "builder-tracker";
  src: url("../images/builder-tracker.eot");
  src: url("../images/builder-tracker.eot?#iefix") format("embedded-opentype"),
    url("../images/builder-tracker.woff") format("woff"),
    url("../images/builder-tracker.ttf") format("truetype"),
    url("../images/builder-tracker.svg#builder-tracker") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "builder-tracker" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="iconb-"]:before,
[class*=" iconb-"]:before {
  font-family: "builder-tracker" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconb-accepted:before {
  content: "\61";
}
.iconb-attach:before {
  content: "\64";
}
.iconb-delete-icon:before {
  content: "\67";
}
.iconb-download:before {
  content: "\6a";
}
.iconb-expand:before {
  content: "\6b";
}
.iconb-hrs:before {
  content: "\6e";
}
//.iconb-s2:before {
//  content: "\6f";
//}
//.iconb-s1:before {
//  content: "\70";
//}
.iconb-feature-name:before {
  content: "\71";
}
.iconb-label-name:before {
  content: "\74";
}
.iconb-view-tasks:before {
  content: "\76";
}
.iconb-side-effect:before {
  content: "\44";
}
//.iconb-s3:before {
//  content: "\45";
//}
.iconb-timer:before {
  content: "\47";
}
.iconb-mail:before {
  content: "\4a";
}
.iconb-review:before {
  content: "\4e";
}
.iconb-pass:before {
  content: "\52";
}
.iconb-owner:before {
  content: "\53";
}
.iconb-more-vert:before {
  content: "\55";
}
.iconb-message:before {
  content: "\56";
}
.iconb-duplicate:before {
  content: "\62";
}
.iconb-dd-down:before {
  content: "\63";
}
.iconb-add-story:before {
  content: "\69";
}
.iconb-copy-link:before {
  content: "\6c";
}
.iconb-pause-small:before {
  content: "\75";
}
.iconb-play-circle:before {
  content: "\77";
}
.iconb-unstarted:before {
  content: "\78";
}
.iconb-tasks:before {
  content: "\7a";
}
.iconb-tag:before {
  content: "\42";
}
.iconb-closeSmall:before {
  content: "\68";
}
.iconb-edit:before {
  content: "\6d";
}
.iconb-featurecounter:before {
  content: "\72";
}
.iconb-alert:before {
  content: "\79";
}
.iconb-total-activeuser:before {
  content: "\43";
}
.iconb-total-project:before {
  content: "\46";
}
.iconb-total-user:before {
  content: "\48";
}
.iconb-home:before {
  content: "\49";
}
.iconb-search:before {
  content: "\4b";
}
.iconb-history:before {
  content: "\4c";
}
.iconb-historyProject:before {
  content: "\4d";
}
.iconb-nmember:before {
  content: "\4f";
}
.iconb-nstory:before {
  content: "\50";
}
.iconb-velocity:before {
  content: "\51";
}
.iconb-double-check:before {
  content: "\52";
}
.iconb-rating-default:before {
  content: "\54";
}
.iconb-thumb-up:before {
  content: "\57";
}
.iconb-thumb-down:before {
  content: "\58";
}
.iconb-unassigned:before {
  content: "\59";
}
.iconb-progress-marker:before {
  content: "\5a";
}
.iconb-check-circle:before {
  content: "\30";
}
.iconb-glasstime:before {
  content: "\31";
}
.iconb-notification:before {
  content: "\32";
}
.iconb-members:before {
  content: "\33";
}
.iconb-reports:before {
  content: "\34";
}
.iconb-settings:before {
  content: "\35";
}
.iconb-timings:before {
  content: "\36";
}
.iconb-brief:before {
  content: "\37";
}
.iconb-featureEpic:before {
  content: "\38";
}
.iconb-bug:before {
  content: "\39";
}
.iconb-side-effect:before {
  content: "\21";
}
.iconb-product-roadmap:before {
  content: "\22";
}
.iconb-full-build-frontend:before {
  content: "\23";
}
.iconb-full-build:before {
  content: "\24";
}
.iconb-mvp:before {
  content: "\e036";
}
.iconb-full-build-qa:before {
  content: "\26";
}
.iconb-project-dashboard:before {
  content: "\24";
}
.iconb-blocker:before {
  content: "\27";
}
.iconb-add-member:before {
  content: "\28";
}
.iconb-label:before {
  content: "\29";
}
.iconb-changemember:before {
  content: "\2a";
}
.iconb-move:before {
  content: "\2b";
}
.iconb-action:before {
  content: "\2c";
}
.iconb-activity:before {
  content: "\2d";
}
.iconb-add-comment:before {
  content: "\2e";
}
.iconb-attachment:before {
  content: "\2f";
}
.iconb-bell:before {
  content: "\3a";
}
.iconb-bin:before {
  content: "\3b";
}
.iconb-brief:before {
  content: "\3c";
}
.iconb-chat:before {
  content: "\3d";
}
.iconb-check:before {
  content: "\3e";
}
.iconb-close:before {
  content: "\3f";
}
.iconb-comment:before {
  content: "\40";
}
.iconb-design:before {
  content: "\5b";
}
.iconb-desktop:before {
  content: "\5d";
}
.iconb-done:before {
  content: "\5e";
}
.iconb-clock:before {
  content: "\5f";
}
.iconb-double-tap:before {
  content: "\60";
}
.iconb-down-2-1:before {
  content: "\7b";
}
.iconb-download:before {
  content: "\7c";
}
.iconb-drop:before {
  content: "\7d";
}
.iconb-drop-design:before {
  content: "\7e";
}
.iconb-email:before {
  content: "\5c";
}
.iconb-eye:before {
  content: "\e000";
}
.iconb-fade:before {
  content: "\e001";
}
.iconb-filter:before {
  content: "\e002";
}
.iconb-flow:before {
  content: "\e003";
}
.iconb-full-screen:before {
  content: "\e004";
}
.iconb-help:before {
  content: "\e005";
}
.iconb-history:before {
  content: "\e006";
}
.iconb-hold:before {
  content: "\e007";
}
.iconb-home:before {
  content: "\e008";
}
.iconb-hotspost:before {
  content: "\e009";
}
.iconb-hover:before {
  content: "\e00a";
}
.iconb-info:before {
  content: "\e00b";
}
.iconb-left:before {
  content: "\e00c";
}
.iconb-left-2:before {
  content: "\e00d";
}
.iconb-left-3:before {
  content: "\e00e";
}
.iconb-minus:before {
  content: "\e00f";
}
.iconb-mobile:before {
  content: "\e010";
}
.iconb-more:before {
  content: "\e011";
}
.iconb-pass:before {
  content: "\e012";
}
.iconb-pause:before {
  content: "\e013";
}
.iconb-pinch-in:before {
  content: "\e014";
}
.iconb-pinch-out:before {
  content: "\e015";
}
.iconb-play:before {
  content: "\e016";
}
.iconb-plus:before {
  content: "\e017";
}
.iconb-read:before {
  content: "\e018";
}
.iconb-restart:before {
  content: "\e019";
}
.iconb-right:before {
  content: "\e01a";
}
.iconb-right-2:before {
  content: "\e01b";
}
.iconb-search:before {
  content: "\e01c";
}
.iconb-share:before {
  content: "\e01d";
}
.iconb-stories:before {
  content: "\e01e";
}
.iconb-subtask:before {
  content: "\e01f";
}
.iconb-summary:before {
  content: "\e020";
}
.iconb-swipe-down:before {
  content: "\e021";
}
.iconb-swipe-left:before {
  content: "\e022";
}
.iconb-swipe-right:before {
  content: "\e023";
}
.iconb-swipe-up:before {
  content: "\e024";
}
.iconb-tap:before {
  content: "\e025";
}
.iconb-tick:before {
  content: "\e026";
}
.iconb-transition:before {
  content: "\e027";
}
.iconb-up:before {
  content: "\e028";
}
.iconb-prototype:before {
  content: "\77";
}
.iconb-info-extra:before {
  content: "\e029";
}
.iconb-full-build-backend:before {
  content: "\e02a";
}
.iconb-release:before {
  content: "\e02b";
}
.iconb-chore:before {
  content: "\e02c";
}
.iconb-custom-phase:before {
  content: "\e02d";
}
.iconb-star-outline:before {
  content: "\e02d";
}
.iconb-star-fill:before {
  content: "\e038";
}
.iconb-task:before {
  content: "\e035";
}
.iconb-plusIcon:before {
  content: "\41";
}
.iconb-flow-view:before {
  content: "\e02e";
}
.iconb-filter:before {
  content: "\e030";
}
.iconb-logout:before {
  content: "\e02f";
}
.iconb-calendar:before {
  content: "\e031";
}
.iconb-projectfolder:before {
  content: "\e032";
}
.iconb-rework:before {
  content: "\e033";
}
.iconb-dd-down-fill:before {
  content: "\e034";
}
.iconb-info2:before {
  content: "\e037";
}
.iconb-url-link:before {
  content: "\e039";
}
.iconb-copyid:before {
  content: "\e03a";
}
.iconb-desktop:before {
  content: "\e03b";
}
.iconb-tv:before {
  content: "\e03c";
}
.iconb-mobile:before {
  content: "\e03d";
}
.iconb-tablet:before {
  content: "\e03e";
}
.iconb-watch:before {
  content: "\e03f";
}
.iconb-Desktop:before {
  content: "\e03c";
}
.iconb-Smartphone:before {
  content: "\e03d";
}
.iconb-Tablet:before {
  content: "\e03e";
}
.iconb-Watch:before {
  content: "\e03f";
}
.iconb-uiwdesign:before {
  content: "\e040";
}
.iconb-uiwsketch:before {
  content: "\e041";
}
.iconb-unarchive:before {
  content: "\e042";
}
.iconb-devices-platefrom:before {
  content: "\e043";
}
.iconb-whiteboard:before {
  content: "\e044";
}

.iconb-releases:before {
  content: "\e045";
}
.iconb-release-planning:before {
  content: "\e046";
}
.iconb-sprint-planning:before {
  content: "\e047";
}
.iconb-sorting:before {
  content: "\e048";
}
.iconb-three-arrow-nav:before {
  content: "\e049";
}

.iconb-restrict:before {
  content: "\e04a";
}
.iconb-thick-arrow-up:before {
  content: "\e04b";
}
.iconb-circle-withdot:before {
  content: "\e04c";
}
.iconb-doublechevron-up:before {
  content: "\e04d";
}
.iconb-doublechevron-down:before {
  content: "\e04e";
}
.iconb-archive:before {
  content: "\e04f";
}
.iconb-edit-2:before {
  content: "\e050";
}
.iconb-start-sprint:before {
  content: "\e051";
}
.iconb-download-sketch:before {
  content: "\e052";
}
.iconb-duplicate-outline:before {
  content: "\e053";
}
.iconb-flow2:before {
  content: "\e054";
}
.iconb-open-story:before {
  content: "\e055";
}
.iconb-close-story:before {
  content: "\e056";
}
.iconb-offline:before {
  content: "\e057";
}
.iconb-uiw:before {
  content: "\e059";
}
.iconb-bulk-accept:before {
  content: "\e05a";
}
.iconb-checklist:before {
  content: "\e05b";
}
.iconb-feature-notes:before {
  content: "\e05c";
}
.iconb-add-circle:before {
  content: "\e05d";
}
.iconb-remove-circle:before {
  content: "\e05e";
}
.iconb-left-arrow:before {
  content: "\e05f";
}
.iconb-story:before {
  content: "\e063";
}
.iconb-user-group:before {
  content: "\e061";
}
.iconb-notes:before {
  content: "\e060";
}
.iconb-mail-new:before {
  content: "\e062";
}
.iconb-file-folder:before {
  content: "\e064";
}
.iconb-move-left:before {
  content: "\e065";
}
.iconb-caret-left:before {
  content: "\e066";
}
.iconb-activity:before {
  content: "\e067";
}
.iconb-bin:before {
  content: "\e068";
}
.iconb-chat-bubble:before {
  content: "\e069";
}
.iconb-rating:before {
  content: "\e06a";
}
.iconb-user:before {
  content: "\e06b";
}
.iconb-certified-ribbon:before {
  content: "\e073";
}
.iconb-archive-new:before {
  content: "\e06c";
}
.iconb-subtask-new:before {
  content: "\e06d";
}
.iconb-comment-new:before {
  content: "\e06e";
}
.iconb-commits:before {
  content: "\e06f";
}
.iconb-copy-new:before {
  content: "\e070";
}
.iconb-priority-flag:before {
  content: "\e071";
}
.iconb-alarm-bell:before {
  content: "\e072";
}
.iconb-clock1:before {
  content: "\e074";
}
.iconb-send:before {
  content: "\e07c";
}
.iconb-send-thick:before {
  content: "\e07d";
}
.iconb-corner-tick:before {
  content: "\e07e";
}
.iconb-edit-tick-pointer:before {
  content: "\e07f";
}