/* You can add global styles to this file, and also import other style files */

@import "assets/stylesheets/application";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mainApp{
  font-family: 'Rubik', sans-serif;
}
.d-none {
  display: none !important;
}
.maintenancePage{
  width:100%;
  height: 100%;
  position: fixed;
  z-index:1111;
  text-align: center;
  background-color: #256188;
  padding-top: 14%;

  .maintenanceIcon{
    width: 140px;
    height: 140px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background-color: #fff;

    img{
      max-width: 90px;
      margin-top: 24px;
      height: auto;
    }
  }

  h1, h2, h3, h4, h5, p {
    color: #ffffff;
    font-weight: 500;
  }
}

.validation-msg{
  color: #f00;
  margin-top: 8px;
  display: inline-block;
}

.validation-color {
  color: #df0000;
}

.add-member-btns {
  button {
    margin-bottom: 15px;
  }
}

.ck.ck-balloon-panel{
  z-index: 1111!important;
}
.ck{
  &.ck-toolbar {
    background: #fff;
    padding: 0 10px !important;
    border: 0 !important;
    border-bottom: solid 1px #e9e9f0 !important;
    //width: calc(100% + 26px) !important;
    //margin-left: -13px !important;
  }
}
.descriptionInput{
  .ck{
    &.ck-toolbar {
      .ck-toolbar__grouped-dropdown{
        .ck-dropdown__button{
          .ck-tooltip_s{
            display: none !important;
          }
        }
      }
    }
  }
}
.ck.ck-editor__editable_inline {
  padding: 0 3px !important;
  border: 0 !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  padding: 0;
  border: 0!important;
  box-shadow: none !important;
}
.ck.ck-balloon-panel {
  box-shadow: none !important;
  background: #fafafa !important;
  border: none !important;
}

.descriptionInput, .descriptionView{
  .ck.ck-content{
    padding:0 13px !important;
    max-height: 340px;

    &:hover{
      &::-webkit-scrollbar {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
      border-radius:2px;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
  }
  .ck-read-only{
    opacity: .3;
    cursor: not-allowed;
  }

  &.coreRestriction{
    .ck-read-only{
      opacity: .7;
    }
  }
}

.descriptionView{
  .ck .ck-widget.ck-widget_with-selection-handle .ck-widget__selection-handle {
    display: none !important;
  }
  .ck .ck-widget.ck-widget_with-selection-handle {
    position: relative;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    outline: none !important;
  }
  .ck .ck-widget:hover {
    outline-color: #e9e9f0 !important;
  }
  .ck .ck-widget{
    outline-color: #fff !important;
  }
  .ck .ck-widget .ck-widget__type-around__button.ck-widget__type-around__button_before,
  .ck .ck-widget .ck-widget__type-around__button.ck-widget__type-around__button_after{
    display: none;
  }
}

.breifContent{
  img{
    height: auto;
    max-width: 900px !important;
  }
}



.selectDate-wrapper{
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 25px;

  &.filterCal .md-drppicker{
    top: auto !important;
    left: calc(100% - 693px) !important;
    right: auto !important;
    position: fixed !important;
    width: 655px !important;
  }
  .md-drppicker {
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16), 0 2px 8px 0 rgba(0,0,0,.12);
}

  .md-drppicker .buttons {
    text-align: right;
    margin: 0 5px 5px 0;
    position: relative;
    float: right;
    clear: both;
    padding: 7px 20px;

    .buttons_input{
      .btn {
        position: relative;
        overflow: hidden;
        border-width: 0;
        outline: 0;
        padding: 0 26px;
        cursor: pointer;
        border-radius: 4px;
        box-shadow: none;
        background-color: #6C00EA;
        color: #fff;
        height: auto;
        text-transform: uppercase;
        line-height: 32px;
        border: none;
        font-size: 12px;
      }
    }
  }

  .md-drppicker.ltr .ranges{
    float: right;
    margin-top: 13px;
    padding: 0 20px 0 30px;

    ul li{
      margin-bottom: 9px;

      button {
        padding: 8px 12px;
        width: 100%;
        background: #ffffff;
        cursor: pointer;
        text-align: center;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        font-size: 12px;
        color: #262626;

        &.active {
          background-color: #6C00EA;
          color: #fff;
        }
      }
    }
  }
  .md-drppicker.ltr .calendar.left, .md-drppicker.ltr .calendar.right {
    padding-top: 34px;
    position: relative;
  }
  .md-drppicker.ltr .calendar.left:before, .md-drppicker.ltr .calendar.right:before{
    content: "From";
    position: absolute;
    top: 7px;
    left: 14px;
    font-size: 14px;
    color: #000000;
    font-weight: 500;
  }
  .md-drppicker.ltr .calendar.right:before{
    content: "To";
  }
}

.storyLoader{
  width: 100%;
  padding: 2px 0 0 13px;
  z-index: 11;
  text-align: center;

  img{
    width: 25px;
  }
}

.project-alias-info-wrapper {
  padding: 0 15px 10px;
  display: flex;
  align-items: center;
  .alias-tag {
    background: #E5E7FF;
    border-radius: 4px;
    padding: 0 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-right: 4px;
    min-width: 39px;
    text-align: center;
    color: #3C3E49;
  }
  .alias-text {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: #3C3E49;
    width: calc(100% - 42px);
  }
}

.diffAdded{
  background-color: #e2fcea;
}  
.diffRemoved{
  background-color: #ffe3e3;
}

.flip{
  .diffAdded{
    background-color: #ffe3e3;
  }  
  .diffRemoved{
    background-color: #e2fcea;
  }
}

.sprintDemoDate {
  .myDpSelector{
    left: -65px !important;
  }
}