$userInitialsBackgroundColors: #e6f7ff, #fff0f6, #ecedf0, #f4eaff;
$userInitialsBackgroundColorsLength: length($userInitialsBackgroundColors);
$userInitialsColors: #6200ea, #f759ab, #83889e, #9f4dff;
$userInitialsColorsLength: length($userInitialsColors);
$userInitialsBorderColor: #91d5ff, #fbcbde, #d8dbe6, #e5cffd;
$userInitialsBorderColorLenght: length($userInitialsBorderColor);

.status-icon{
  &.draft{
    background-color: #536DFE;
  }
  &.deployed_to_staging{
    background-color: #00E676;
  }
  &.qa_rejected{
    background-color: #D50000;
  }
  &.deployed_to_pre_prod{
    background-color: #00C853;
  }
  &.rolled_back{
    background-color: #FF8A80;
  }
  &.ready_for_demo{
    background-color: #8C9EFF;
  }
  &.demo_completed{
    background-color: #FADB14;
  }
  &.ready_to_ship{
    background-color: #304FFE;
  }
  &.shipped_to_customer{
    background-color: #00C853;
  }
  &.accepted{
    background-color: #00E676;
  }
  &.rejected{
    background-color: #D50000;
  }
  &.deployed_to_production{
    background-color: #651FFF;
  }
  &.qa_signed_off{
    background-color: #00E676;
  }
}

.selected-release-list-wrapper{
  width: 100%;
  position: relative;
  display: block;
}

.selected-release-list{
  width: 100%;
  position: relative;
  display: block;
  font-size: 12px;
  padding: 13px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);

  &:last-of-type{
    border-bottom: none;
    margin-bottom: 20px;
  }

  .selected-release-version{
    display: inline-flex;
    background: #ecf2fc;
    border: 1px solid #b6cff3;
    border-radius: 32px;
    width: auto;
    padding: 2px 12px;
    color: #0b5cd7;
    font-size: 12px;
    position: relative;
  }
  .selected-release-name{
    display: inline-flex;
    width: auto;
    padding: 2px 12px;
    color: #0b5cd7;
    font-weight: 400;
    font-size: 12px;
    color: #3c3e49;
    position: relative;
  }
}

.projectRelease-wrapper {
  width: 100%;
  position: relative;
  display: block;

  .topInfo-section {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 13px 20px 0 40px;
    color: #3c3e49;
    border-bottom: 1px solid #ecedf0;
  
    .topTitle {
      display: flex;
      align-items: center;
      position: relative;
      //margin-bottom: 18px;
      margin-bottom: 12px;
  
      .topLeft {
        width: 30%;
        position: relative;
  
        .maintitle {
          width: auto;
          position: relative;
          display: inline-block;
          font-size: 24px;
          font-weight: 500;
          color: #000;
        }
        .releaseVersion-filter {
          //width: 200px;
          position: relative;
          display: inline-block;
          margin-left: 20px;
        }
        .selectedFilter {
          position: relative;
          display: inline-block;
          font-size: 13px;
          //font-weight: 500;
          color: #83889e;
          cursor: pointer;
  
          em {
            display: inline-block;
            margin-left: 7px;
            font-size: 9px;
          }
        }
        .releaseVersion-options {
          position: absolute;
          width: 200px;
          display: inline-block;
          top: 20px;
          left: 0;
          padding: 5px 15px;
          background: #ffffff;
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
          border-radius: 4px;
          z-index: 1;
  
          .releaseVersion-option {
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 12px;
            color: #83889e;
            cursor: pointer;
            padding: 10px 3px 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
            em {
              color: #651fff;
              float: right;
              height: 11px;
              margin-top: 2px;
              font-size: 10px;
            }
  
            &:last-of-type {
              border-bottom: 0;
            }
          }
        }
      }
  
      .topRight {
        width: 70%;
        position: relative;
        text-align: right;
  
        .start-end-date {
          display: flex;
          justify-content: flex-end;
          padding: 0;
          margin: 0;
          list-style: none;
          color: #83889e;
          font-size: 12px;
          line-height: 20px;
          li {
            padding-right: 12px;
            margin-right: 12px;
            position: relative;
            &:last-child {
              padding-right: 0;
              margin-right: 0;
              &::after {
                display: none;
              }
            }
            &:after {
              content: "";
              position: absolute;
              right: 0;
              width: 1px;
              height: 16px;
              background-color: #dfe0e6;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  
    .bottomTitle {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 48px;
      .bottomLeft {
        .releaseTypeMenu {
          display: flex;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            margin-right: 32px;
            &:last-child {
              margin-right: 0;
            }
            a {
              font-size: 16px;
              line-height: 28px;
              color: #595959;
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              padding-bottom: 8px;
              border-bottom: 2px solid transparent;
              &.active {
                border-color: #6200ea;
                color: #6200ea;
                font-weight: 500;
                .no {
                  background-color: #651fff;
                  color: #fff;
                  font-weight: 400;
                }
              }
              .no {
                margin-left: 8px;
                padding: 0 6px;
                border-radius: 4px;
                background-color: transparent;
                color: #83889e;
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }
      .bottomRight {
        padding-bottom: 7px;
        display: flex;
        align-items: center;
  
        .fliterButton-wapper {
          top: -1px;
        }
  
        .integrationSection-search {
          width: 240px;
          position: relative;
          display: inline-block;
          margin-left: 18px;
  
          .integrationSection-searchInput {
            width: 100%;
            height: 36px;
            position: relative;
            display: inline-block;
            font-size: 12px;
            font-weight: normal;
            color: #3c3e49;
            padding: 0 15px 0 40px;
            border-radius: 3px;
            border: 1px solid #dfe0e6;
            background-color: transparent;
  
            &:focus,
            &:active {
              outline: none;
              box-shadow: none;
            }
            &::-webkit-input-placeholder {
              color: #afb2c0;
            }
            &:-ms-input-placeholder {
              color: #afb2c0;
            }
            &::placeholder {
              color: #afb2c0;
            }
          }
          .integrationSection-searchButton {
            width: 34px;
            height: 33px;
            position: absolute;
            top: 2px;
            left: 2px;
            z-index: 1;
            background-color: #fff;
            text-align: center;
            border: none;
  
            &:focus,
            &:active {
              outline: none;
            }
  
            em {
              color: #83889e;
              line-height: 36px;
            }
          }
        }
  
        .addNewProject-release {
          // width: 137px;
          position: relative;
          display: inline-block;
          padding: 5px 12px;
          font-size: 12px;
          line-height: 19px;
          font-weight: 500;
          color: #6200ea;
          text-align: center;
          margin-left: 19px;
          border-radius: 3px;
          background-color: #fff;
          border: 1px solid #6200ea;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #6200ea;
            color: #fff;
          }
          &:focus,
          &:active {
            outline: none;
          }
        }
      }
    }
  }
  
  .releaseVersion-table {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0;
    overflow: hidden;
  
    .releaseVersion-tableHead,
    .releaseVersion-tableContent {
      width: 100%;
      position: relative;
      display: inline-block;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 13px;
    }
  
    .releaseVersion-tableHead {
      background: #f8f9fa;
      padding: 11px 20px 11px 40px;
      color: #000;
      line-height: 20px;
      display: flex;
      flex-wrap: wrap;
    }
  
    .releaseVersion-tableContent {
      padding-bottom: 0;
      border-bottom: none;
      overflow-y: auto;
      height: calc(100vh - 240px);
  
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #f8f9fa;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity: 0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity: 0.2;
      }
    }
  
    .releaseVersion-tableRow {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 15px 20px 10px 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-wrap: wrap;
  
      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: #FBF8FF;
        .releaseDetails {
          .notes {
            .readMore {
              color: #9f4dff;
              border: 1px solid #d3bced;
              background-color: #f4eaff;
            }
          }
        }
      }
    }
  
    .releaseVersion-tableContent {
      //padding: 10px 0;
      //border-bottom: 1px solid rgba(0,0,0,.05);
      color: #83889e;
      font-size: 12px;
      line-height: 20px;
    }
  
    .tableHead-col1,
    .tableHead-col2,
    .tableHead-col3,
    .tableHead-col4,
    .tableHead-col5,
    .tableHead-col21 {
      position: relative;
      display: inline-block;
      vertical-align: top;
    }
    .tableHead-col8 {
      width: 5%;
      text-align: center;
    }
    .tableHead-col7 {
      width: 10%;
    }
    .tableHead-col6 {
      width: calc(21% - 30px);
    }
    .tableHead-col5 {
      width: 10%;
    }
    .tableHead-col4 {
      width: 10%;
    }
    .tableHead-col3 {
      width: 15%;
    }
    .tableHead-col2 {
      width: 15%;
    }
    .tableHead-col1 {
      width: 14%;
    }

    .isProdEnv{
      width: calc(14% + 30px);
    }

    .tableHead-col21{
      width: 30px;
      &.noCheck{
        width: 24px;
      }
    }
  
    .story-progress,
    .versionStatus,
    .noOfFeatures,
    .shippingDate {
      width: 100%;
      position: relative;
      display: block;
      margin-bottom: 5px;
    }
  
    .versionStatus {
      width: 100%;
      position: relative;
      padding: 5px 0;
      color: #9b9fb1;

      .update-loader {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        padding: 5px 15px;
        background-color: #fff;
        z-index: 1;
        img {
          width: 24px;
          position: relative;
          display: inline-block;
        }
      }

      .status-icon{
        width: 11px;
        height: 11px;
        position: relative;
        display: inline-block;
        border-radius: 50%;
        vertical-align: text-top;
        margin-right: 5px;
        line-height: normal;
        margin-top: 1px;
      }
      
      .selected-status {
        width: 100%;
        position: relative;
        display: inline-block;
        color: #3c3e49;

        em{
          width: 10px;
          height: 10px;
          position: relative;
          display: inline-block;
          color: #3c3e49;
          font-size: 9px;
          line-height: 10px;
          margin-left: 5px;
        }
      }
      .status-list{
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
        z-index: 1;
        // padding: 5px 0;
        
        .status-list-item{
          width: 100%;
          position: relative;
          display: inline-block;
          padding: 7px 10px;
          font-size: 12px;
          line-height: 20px;
          border: 0;
          color: #3c3e49;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          background-color: transparent;
          text-align: left;
          cursor: pointer;
          
          &:disabled{
            opacity: 0.5;
            cursor: not-allowed;
          }
          &:hover{
            background-color: #f8f9fa;
          }
          &.active{
            color: #651fff;
          }
        }
      }
    }
  
    .actionWrapper {
      position: relative;
      display: inline-block;
      .actionTrigger {
        height: 24px;
        width: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid transparent;
        transition: all 0.3s ease-in-out;
        color: #3c3e49;
        &:hover,
        &.active {
          color: #651fff;
          border-color: #651fff;
          background-color: #f3ebff;
        }
      }
      .actionDropdown {
        position: absolute;
        right: 0;
        width: 141px;
        background: #ffffff;
        border: 1px solid #dfe0e6;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
        border-radius: 4px;
        z-index: 1;
        top: 100%;
        text-align: left;
        padding: 4px 0;
        .actionLink {
          padding: 8px 10px;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
          position: relative;
          &:hover {
            background-color: #f3ebff;
            color: #651fff;
            .submenu-dropdown {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
          &:after {
            content: "";
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 0;
            height: 1px;
            background-color: #dfe0e6;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          em {
            height: 12px;
            width: 12px;
            font-size: 12px;
            margin-right: 8px;
          }
          .submenu-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .submenu-dropdown {
            position: absolute;
            right: calc(100% + 1px);
            top: 0;
            width: 113px;
            filter: drop-shadow(4px -4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(-4px 4px 8px rgba(0, 0, 0, 0.08));
            background: #fff;
            border-radius: 4px;
            padding: 0 10px;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
            .submenu-link {
              padding: 3px 0;
              font-size: 13px;
              line-height: 24px;
              border-bottom: 1px solid #dfe0e6;
              color: #9b9fb1;
              &:last-child {
                border: 0;
              }
            }
          }
        }
      }
    }
  
    .story-progress {
      width: calc(100% - 25px);
      cursor: pointer;

      .progresscount{
        width: 100%;
        position: relative;
        display: block;
        border-radius: 10px;
        overflow: hidden;
        height: 6px;
        margin: 10px 0 0;
      }
  
      .status-type {
        height: 6px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        border-right: 1px solid #fff;
  
        &:first-of-type {
          border-radius: 10px 0 0 10px;
        }
  
        &:last-of-type {
          border-right: none;
          border-radius: 0 10px 10px 0;
        }
        &.only-one {
          border-radius: 10px; 
        }
      }
  
      .story-breakup {
        width: 200px;
        display: block;
        position: absolute;
        top: 30px;
        left: 0;
        padding: 9px 15px;
        background-color: #3c3e49;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        z-index: 11;
        transition: all 0.2s ease-in-out;
  
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: -6px;
          left: 30px;
          background-color: #3c3e49;
          transform: rotate(45deg);
        }
  
        .story-breakup-title {
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 10px;
          color: #fff;
          text-transform: uppercase;
        }
        .story-breakup-list {
          width: 100%;
          position: relative;
          display: inline-block;
          padding: 6px 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  
          &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .story-status-color {
          width: 12px;
          height: 12px;
          float: left;
          position: relative;
          border-radius: 50%;
          margin-right: 10px;
          margin-top: 3px;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
        .story-status-name {
          width: calc(100% - 60px);
          position: relative;
          float: left;
          color: #fff;
          font-size: 12px;
        }
        .story-count {
          width: 35px;
          position: relative;
          float: left;
          color: #fff;
          font-size: 10px;
          text-align: right;
        }
      }
  
      &:hover {
        .story-breakup {
          top: 20px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  
    .releaseVersion-tableRow {
      &:last-of-type {
        border-bottom: none;
      }
      &:nth-last-of-type(1),
      &:nth-last-of-type(4),
      &:nth-last-of-type(2),
      &:nth-last-of-type(3) {
        .story-progress {
          .story-breakup {
            top: -304px;
  
            &:before {
              top: 278px;
            }
          }
  
          &:hover {
            .story-breakup {
              top: -294px;
            }
          }
        }
      }
  
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        .story-progress {
          .story-breakup {
            top: 30px;
  
            &:before {
              top: -6px;
            }
          }
  
          &:hover {
            .story-breakup {
              top: 20px;
            }
          }
        }
      }
    }
  
    .release-name-wrapper {
      .release-name{
        color: #3c3e49;
        display: block;
        margin-bottom: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 24px;
        padding-right: 12px;
      }
      .release-phase{
        width: 100%;
        display: inline-block;
        position: relative;
        margin-bottom: 0;
        margin-top: 5px;
        text-transform: capitalize;
        color: #3c3e49;
      }
      .version-no {
        display: inline-flex;
        background: #ecf2fc;
        border: 1px solid #b6cff3;
        border-radius: 32px;
        width: auto;
        padding: 2px 12px;
        color: #0b5cd7;
        font-weight: 500;
        position: relative;
        &:hover {
          .elementHover {
            visibility: visible;
            opacity: 1;
            bottom: -32px;
            transform: translateX(-50%);
          }
        }
      }
      .stories-status-values {
        display: block;
        color: #83889e;
      }
    }
  
    .releaseDate {
      color: #3c3e49;
    }
  
    .updatedOn {
      color: #3c3e49;
      .time {
        display: block;
      }
    }
  
    .ratingHead {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        line-height: 0;
        margin-right: 8px;
      }
    }
    .ratingBlock {
      text-align: center;
      color: #3c3e49;
      .currentRating {
        color: #83889e;
      }
      .feedback-link {
        display: block;
        margin-top: 3px;
        color: #6200ea;
      }
    }
  
    .featureNames,
    .featureCounts {
      width: auto;
      position: relative;
      display: inline-block;
      padding: 4px 8px;
      background-color: #f8f9fa;
      border-radius: 4px;
      margin-right: 11px;
      margin-bottom: 6px;
      cursor: pointer;
  
      &:last-of-type {
        margin-right: 0;
      }
    }
    .featureCounts {
      background-color: transparent;
      color: #651fff;
    }
    .releaseDetails {
      width: 100%;
      position: relative;
      display: flex;
  
      .iconb-check-circle {
        font-size: 13px;
        color: #3c3e49;
        position: relative;
        top: 3px;
        line-height: 0;
      }
      .notes {
        width: calc(100% - 10px);
        padding-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: #595959;
        .release_detail_text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
        .readMore {
          display: inline-block;
          color: #9f4dff;
          cursor: pointer;
          font-size: 10px;
          line-height: normal;
          border: 1px solid #f4eaff;
          padding: 3px 9px;
          border-radius: 20px;
          margin-left: -19px;
          margin-top: 3px;
          background-color: #f4eaff;
        }
      }
    }
    .release-addStory,
    .deleteVersion {
      width: auto;
      position: relative;
      display: inline-block;
      padding: 6px 20px;
      font-size: 12px;
      font-weight: 500;
      color: #6200ea;
      text-align: center;
      margin-left: 16px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #6200ea;
      transition: all 0.3s ease-in-out;
  
      &:hover {
        background-color: #6200ea;
        color: #fff;
      }
      &:focus,
      &:active {
        outline: none;
      }
    }
    .deleteVersion {
      margin-left: 10px;
      padding: 6px 8px;
      border-color: #afb2c0;
      em {
        display: inline-block;
        font-size: 12px;
        color: #afb2c0;
        height: 14px;
        vertical-align: middle;
      }
      &:hover {
        border-color: #6200ea;
        background-color: #fff;
        color: #6200ea;
  
        em {
          color: #6200ea;
        }
      }
    }
  }

  .rowCheckBox {
    width: 16px;
    height: 18px;
    position: relative;
    display: inline-block;
    visibility: visible;
    opacity: 1;
  }
  
  .customCheckFor-moveSelection {
    width: 22px;
    height: 20px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  
    .check-toggleMoveSelection {
      width: 14px;
      height: 14px;
      position: relative;
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #fff;
      border-radius: 2px;
      cursor: pointer;
      margin-top: 5px;
  
      &:before {
        content: "";
        width: 8px;
        height: 4px;
        position: absolute;
        top: 3px;
        left: 2px;
        border-left: 2px solid rgba(0, 0, 0, 0.015);
        border-bottom: 2px solid rgba(0, 0, 0, 0.015);
        display: block;
        transform: rotate(-47deg);
      }
    }
  
    input[type="checkbox"].move-selected-checkbox:checked + .check-toggleMoveSelection {
      border-color: #6c00ea;
      background-color: #6c00ea;
  
      &:before {
        content: "";
        width: 8px;
        height: 4px;
        position: absolute;
        top: 3px;
        left: 2px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        display: block;
        transform: rotate(-47deg);
      }
    }
  }

  .customCheckForRowSelection {
    width: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 2px;
    font-weight: 400;

    .check-toggle {
      width: 13px;
      height: 13px;
      position: relative;
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, .15);
      background-color: #fff;
      border-radius: 2px;
      cursor: pointer;

      &:before {
        content: '';
        width: 8px;
        height: 4px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-left: 1px solid rgba(0, 0, 0, .015);
        border-bottom: 1px solid rgba(0, 0, 0, .015);
        display: block;
        transform: rotate(-47deg);
      }
    }

    input[type='checkbox'].story-selected-checkbox:checked + .check-toggle {
      border-color: #6C00EA;
      background-color: #6C00EA;

      &:before {
        content: "";
        width: 8px;
        height: 4px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        display: block;
        transform: rotate(-47deg);
      }
    }

    input[type='checkbox'].story-selected-checkbox:disabled + .check-toggle {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .check-toggle:hover + .elementHover-horizontal {
      visibility: visible;
      opacity: 1;
      left: calc(100% + 10px);
    }

    .elementHover-horizontal{
      padding: 5px 9px 5px;
      top: -9px;
      left: calc(100% + 10px);
      border-radius: 4px;

      &:before{
        content: '';
        width: 7px;
        height: 7px;
        display: block;
        position: absolute;
        top: 11px;
        left: -4px;
        transform: rotate(45deg);
        background-color: #3C3E49;
      }
    }
  }
}

.release-commonPopUp {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 124;
}

.release-commonPopUp-backdrop, .releaseDetail-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.65);
}

.changeLayout{
  .release-commonPopUp {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    z-index: auto;
  }
  .close-release-planner-popup {
    display: none;
  }
  .release-commonPopUp-backdrop{
    display:none;
  }
  .release-commonPopUp-wrapper {
    width: 100%;
    max-width: 100%;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    z-index: auto;

    .release-commonPopUp-title-block{
      display: none;
    }
  }
}

.empty-search-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  .icon {
    display: inline-flex;
    margin-bottom: 16px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin: 0;
    color: #83889e;
  }
}

.releaseAttachmentOnly {
  width: 100%;
  height: 165px;
  position: relative;
  display: inline-block;
  margin-top: 12px;
  background-color: #fff;
  overflow: hidden;
  overflow-x: auto;
  z-index: 1;
  padding: 20px 0;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f8f9fa;
  }
  &::-webkit-scrollbar-thumb {
    background: #b4b2b2;
    opacity: 0.2;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b4b2b2;
    opacity: 0.2;
  }

  .toBeUploaded-list {
    width: 142px;
    height: 145px;
    vertical-align: top;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #ecedf0;
    border: 1px solid #ecedf0;
    border-radius: 3px;
    margin-right: 10px;

    img {
      max-width: 130px;
      max-height: 130px;
    }
    .removeDocument {
      width: 32px;
      height: 32px;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0;
      margin-right: 0;
      padding: 2px 4px;
      border-radius: 50%;
      background-color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      em {
        font-size: 18px;
        color: #000;
        height: 16px;
        display: inline-block;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.releaseDetail-backdrop{
  background-color: rgba(0, 0, 0, 0);
}
.release-state-selected {
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: left;

  .pointerNotAllowed {
    width: 100%;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111;
    display: inline-block;
    padding: 9px 10px;
    margin: 0;
    cursor: not-allowed;
  }

  .ifReadOnly {
    height: calc(100% - 20px);
    top: 10px;
  }

  .selected-state {
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 9px 10px;
    border: none;
    border-radius: 0;
    margin: 0;
    &.default-cursor {
      cursor: inherit;
    }

    .stateSelected-icon {
      width: 15px;
      height: 15px;
      position: relative;
      float: left;
      cursor: pointer;
      margin-top: 1px;
      border-radius: 50%;
      margin-right: 7px;
    }

    .iconb-dd-down {
      position: relative;
      display: inline-block;
      font-size: 9px;
      height: 14px;
      color: #262626;
      margin-left: 0;
      vertical-align: middle;
      float: right;
      margin-top: 5px;
      margin-right: -5px;
    }
  }
  .stateSelection-DD {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 0;
    border: 1px solid #d9d9d9;
    border-radius: 0 0 3px 3px;

    &.openStateSelection-DD {
      top: 40px;
      z-index: 111;
      visibility: visible;
      opacity: 1;
    }

    .stateSelection-list {
      width: 100%;
      position: relative;
      float: left;
      padding: 10px 10px 8px;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;

      &:hover .elementHover-horizontal {
        visibility: visible;
        opacity: 1;
        left: 100%;
      }

      .storyFaded {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .stateSelection-icon {
        width: 15px;
        height: 15px;
        position: relative;
        float: left;
        cursor: pointer;
        vertical-align: text-bottom;
        margin-right: 8px;
        border-radius: 50%;
      }

      .selectedState-name {
        position: relative;
        float: left;
        font-size: 14px;
        font-weight: normal;
        color: #262626;
        cursor: pointer;
        line-height: normal;
      }
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

.phaseDeliveables-selected {
  width: 100%;
  position: relative;
  display: block;
  text-align: left;

  .elementHover-table {
    transform: translateX(-85%);
    &:before {
      left: 17px;
    }
    &.make_left{
      padding: 6px 9px 5px;
      transform: none;
      left: 0;
    }
  }
  &:hover {
    .elementHover-table {
      visibility: visible;
      opacity: 1;
      bottom: -32px;
      transform: translateX(-85%);
      &.make_left{
        transform: none;
      }
    }
  }

  .ifReadOnly {
    height: 100%;
    top: 0;
  }

  .selected-type {
    width: 100%;
    height: 34px;
    position: relative;
    display: block;
    padding: 7px 5px;
    cursor: pointer;
    text-align: right;
    transition: background-color 0.2s ease-in-out;
    border: 1px solid #dfe0e6;
    border-radius: 3px;
    text-transform: capitalize;

    &:hover {
      background-color: #e8e8e8;
    }

    .storyId-i {
      position: relative;
      float: left;
      font-size: 13px;
      font-weight: 400;
      color: #000;
      cursor: pointer;
      margin-left: 3px;
    }
    .iconb-copyid {
      cursor: pointer;
      font-style: normal;
      &:hover {
        .elementHover {
          visibility: visible;
          opacity: 1;
          bottom: -32px;
          transform: translateX(-50%);
        }
      }
    }

    .selectedType-name {
      position: relative;
      float: left;
      font-size: 13px;
      font-weight: 400;
      color: #000;
      cursor: pointer;
      vertical-align: baseline;
      margin-top: 1px;

      &.priorityName {
        margin-left: 3px;
      }
    }

    .iconb-dd-down {
      position: relative;
      font-size: 9px;
      height: 14px;
      color: #262626;
      margin-left: 3px;
      float: right;
      margin-top: 4px;
      margin-right: 5px;
    }
  }

  .storySelection-DD {
    width: 100%;
    min-width: 170px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    border: 1px solid #d9d9d9;
    border-radius: 0 0 3px 3px;

    &.openDD {
      top: 37px;
      z-index: 111;
      visibility: visible;
      opacity: 1;
    }

    .storySelection-list {
      width: 100%;
      position: relative;
      float: left;
      padding: 12px 10px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      .selectedType-name {
        position: relative;
        float: left;
        font-size: 13px;
        font-weight: normal;
        color: #000;
        cursor: pointer;
        vertical-align: middle;
        height: 14px;
        margin-top: 1px;
        line-height: normal;
      }
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
}

.close-release-planner-popup {
  width: 53px;
  height: 53px;
  position: fixed;
  top: 0;
  left: calc(100% - 679px);
  z-index: 111;
  text-align: center;
  padding: 0;
  background-color: #6200ea;
  border: none;
  cursor: pointer;
  color: #fff;
  transition: left 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  em {
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
.release-commonPopUp-wrapper {
    width: 100%;
    max-width: 625px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.05);
    border-radius: 0;
    z-index: 12;
  
    &.forAddStory {
      // max-width: 920px;
    }

    .addEstimation-input{
      width: 100%;
      height: 34px;
      position: relative;
      display: inline-block;
      font-size: 13px;
      padding: 0 12px;
      border-radius: 3px;
      border: 1px solid #DFE0E6;
      background-color: transparent;
      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
    }
    .disableCal{
      width: 100%;
      height: 38px;
      position: absolute;
      display: inline-block;
      z-index: 1;
      top: 35px;
      left: 0;
      cursor: not-allowed;
    }
    .cal-btn{
      position: absolute;
      display: inline-block;
      left: 0;
      top: 35px;
      text-align: right;
      width: 100%;
      height: 34px;

      .calIcon{
        position: relative;
        display: inline-block;
        cursor: pointer;
      }

      em.iconb-calendar{
        font-size: 18px;
        display: inline-block;
        height: 22px;
        vertical-align: middle;
        color: #83889E;
        margin: 2px 10px 0 0;
      }
      &.calendarDisabled{
        cursor: not-allowed;
      }
    }
  
    .release-closeCommonPopup {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 12px;
      cursor: pointer;
      color: #83889e;
    }
  
    .release-commonPopUp-title-block {
      padding: 10px 20px;
      border-bottom: 1px solid #dfe0e6;
      display: flex;
      align-items: center;
      height: 57px;
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        &.for-release-note{
          padding-right: 150px;
        }
      }
      .user-storyStateDD-selected {
        max-width: 160px;
        margin-left: 16px;
        padding-left: 8px;
        &::before {
          content: "";
          width: 1px;
          height: 20px;
          background-color: #dfe0e6;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .version-type {
        margin-left: 16px;
        padding-left: 16px;
        position: relative;
        &::before {
          content: "";
          width: 1px;
          height: 20px;
          background-color: #dfe0e6;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .version-name {
          display: inline-flex;
          background: #ecf2fc;
          border: 1px solid #b6cff3;
          border-radius: 32px;
          width: auto;
          padding: 2px 12px;
          color: #0b5cd7;
          font-weight: 500;
          position: relative;
          font-size: 12px;
          line-height: 16px;
          &:hover {
            .elementHover {
              visibility: visible;
              opacity: 1;
              bottom: -32px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  
    .release-commonPopUp-title2 {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 0 20px 16px;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.39;
      text-align: left;
      color: #000;
      //border-bottom: solid 1px rgba(0,0,0,.1);
    }
  
    .release-commonPopUp-text {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 16px 0 35px;
      font-size: 14px;
      line-height: 1.67;
      text-align: left;
      color: #262626;
    }
    .commonPopUp-featurecontent,
    .commonPopUp-createVersion,
    .commonPopUp-storyListWrapper {
      width: 100%;
      position: relative;
      display: block;
      max-height: 440px;
      overflow: auto;
      padding: 0 20px;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity: 0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity: 0.2;
      }
    }
    .commonPopUp-featurecontent {
      max-height: calc(100% - 57px);
      height: inherit;
      padding: 24px 20px;
      &.releaseDetailsContent {
        padding: 30px 20px 90px;
        .releaseDetailBlock {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .releaseTitle {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.01em;
            color: #000000;
            margin-bottom: 12px;
            position: relative;

            sup {
              color: #ff1744;
              margin-left: 2px;
            }

            .add-btn {
              width: 24px;
              height: 24px;
              position: absolute;
              top: 2px;
              right: 6px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              border: 1px solid #6200ea;
              border-radius: 50%;
              white-space: nowrap;
              color: #6200ea;
              cursor: pointer;
              transition: all 0.2s ease-in-out;

              &.hide-it{
                visibility: hidden;
                opacity: 0;
              }
              
              &:hover {
                background-color: #6200ea;
                color: #fff;
              }
              em {
                line-height: 0;
                font-size: 10px;
                // margin-right: 8px;
              }
              .hidden-input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
                &::-webkit-file-upload-button {
                  cursor: pointer;
                }
              }
            }
          }
          .video-link {
            position: relative;
            display: block;
            font-size: 12px;
            line-height: 20px;
            color: #651fff;
            text-decoration: underline;
            cursor: pointer;
            margin-bottom: 0.5rem;

            &.for_feature_note {
              font-size: 12px;
              padding-left: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }

            .downloadAttachment{
              top: 4px;
              right: 0;
              transform: none;
              margin-top: 0;

              em{
                font-size: 14px;
              }
            }

            .deleteDocument{
              top: 2px;
              right: 0;
              transform: none;
              padding-top: 0;

              em{
                font-size: 11px;
                color: #83889E;
              }
            }

            .replaceAttachment {
              width: 32px;
              position: relative;
              display: inline-block;
              text-align: center;
              cursor: pointer;
              vertical-align: text-bottom;
              margin-left: 3px;
              margin-bottom: 0;
              font-weight: 400;
              em{
                font-size: 10px;
                color: #83889E;
                display: inline-block;
                margin-left: 5px;
                padding: 3px 5px;
                display: inline-block;
                height: 22px;
                vertical-align: bottom;
                border: 1px solid #83889E;
                border-radius: 3px;
              }
              .elementHover{
                padding: 5px 11px 4px;
              }
            
              &:hover{
                .elementHover{
                  visibility: visible;
                  opacity: 1;
                  bottom: -32px;
                  transform: translateX(-50%);
                }
              }
            }
          }
          .releaseDetail-content {
            margin: 0;
            resize: none;
            height: auto;
            font-size: 12px;
            line-height: 20px;
            color: #000;
            padding: 12px 20px;
            display: block;

            &.no-data{
              height: 48px;
              padding: 14px;
              color: #83889E;

              &::before{
                content: 'No data available';
                display: inline-block;
              }
            }

            &::-webkit-scrollbar {
              width: 4px;
              border-radius: 4px;
            }
            &::-webkit-scrollbar-track {
              background: #fff;
            }
            &::-webkit-scrollbar-thumb {
              background: #b4b2b2;
              opacity: 0.2;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #b4b2b2;
              opacity: 0.2;
            }
            &.lg {
              height: 12.4rem;
            }
          }
          .relase-video-block {
            margin-top: 8px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .left-title {
              display: inline-flex;
              align-items: center;
              color: #3c3e49;
              font-size: 12px;
              cursor: pointer;
              position: relative;
              padding-left: 10px;
              margin-bottom: 5px;
              .hidden-input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
                &::-webkit-file-upload-button {
                  cursor: pointer;
                }
              }
              em {
                line-height: 0;
                transform: rotate(37deg);
                margin-left: 5px;
                color: #83889e;
                font-size: 11px;
              }
            }
            .video-listing {
              display: flex;
              justify-content: flex-end;
              flex-wrap: wrap;
              margin-bottom: -5px;
              width: 75%;
              .video-chip {
                display: inline-block;
                color: #6200ea;
                border: 1px solid #ecedf0;
                border-radius: 12.5px;
                font-size: 12px;
                line-height: 20px;
                padding: 1px 7px;
                text-align: center;
                margin-right: 8px;
                margin-bottom: 5px;
                cursor: pointer;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
          .feature-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .feature-chip {
              padding: 8px;
              font-size: 13px;
              line-height: 20px;
              color: #1d39c4;
              background: #f0f5ff;
              border-radius: 4px;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
          .release-feature-outer {
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      &.activity-logs {
        .featureListTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .comments-btn {
            display: inline-flex;
            align-items: center;
            color: #000000;
            font-size: 13px;
            line-height: 20px;
            padding: 8px 12px;
            border: 1px solid #000000;
            border-radius: 3px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }
      .featureListTitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #000;
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        .count {
          font-size: 16px;
          line-height: 28px;
          color: #83889e;
          letter-spacing: -0.01rem;
          margin-left: 0.5rem;
        }
      }
    }
    .commonPopUp-storyListWrapper {
      padding: 0;
      .addStory-search {
        margin: 0;
        padding: 20px;
        .search-input {
          position: relative;
        }
      }
    }
    .commonPopUp-createVersion {
      height: calc(100vh - 57px);
      max-height: inherit;
      padding: 30px 20px 80px;
  
      .releaseDetail-content {
        height: 90px;
        margin-bottom: 0;
      }
      .attachments-add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label-type1 {
          margin: 0;
          width: calc(100% - 80px);
        }
        .add-btn {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          border: 1px solid #6200ea;
          border-radius: 3px;
          padding: 5px 16px;
          white-space: nowrap;
          color: #6200ea;
          width: 75px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          &:hover {
            background-color: #6200ea;
            color: #fff;
          }
          em {
            line-height: 0;
            font-size: 12px;
            margin-right: 8px;
          }
          .hidden-input {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
            &::-webkit-file-upload-button {
              cursor: pointer;
            }
          }
        }
      }
    }
    .commonPopUp-storyListWrapper {
      max-height: inherit;
  
      .releaseVersion-table {
        padding: 13px 0 0;
        &.addStory-table {
          padding: 0;
          .releaseVersion-tableContent {
            padding: 0;
          }
          .tableHead-col6 {
            width: 12%;
            text-align: left;
            .customCheckFor-moveSelection {
              height: 14px;
              width: 14px;
              margin-right: 5px;
              .check-toggleMoveSelection {
                margin-top: 0;
              }
            }
          }
          .tableHead-col7 {
            width: 6%;
            text-align: center;
            justify-content: center;
          }
          .tableHead-col8 {
            width: 6%;
            text-align: center;
            justify-content: center;
          }
          .tableHead-col9 {
            width: 12%;
            text-align: center;
            justify-content: center;
          }
          .tableHead-col10 {
            width: 33%;
            text-align: left;
            padding: 0 10px;
          }
          .tableHead-col11 {
            width: 31%;
          }
          .releaseVersion-tableHead {
            padding: 8px 20px;
            color: #83889e;
            font-size: 12px;
          }
          .releaseVersion-tableRow {
            padding: 15px 20px;
            border-bottom-color: #ecedf0;
            > * {
              display: flex;
              align-items: center;
            }
          }
          .story-summary {
            color: #83889e;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              color: #000;
            }
          }
          .story-type {
            line-height: 0;
          }
          .story-statusWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            .story-statusIcon {
              margin-right: 0;
            }
          }
          .story-priority {
            border: none;
            border-radius: 0;
            display: inline-flex;
            padding: 1px 4px 0;
            font-weight: 600;
            font-size: 8px;
            line-height: 11px;
            letter-spacing: 0.1em;
            text-align: center;

            em{
              display: inline-block;
              height: 14px;
              margin-right: 5px;
              font-size: 14px;
              vertical-align: top;
      
              &.iconb-restrict{ color: #D50000;}
              &.iconb-thick-arrow-up{color: #FF1744;}
              &.iconb-doublechevron-up{color: #FF6D00;}
              &.iconb-doublechevron-down{color: #4CAF50;}
              &.iconb-circle-withdot{color: #536DFE;}
            }

            .elementHover{
              line-height: initial;
              font-weight: 400;
              padding: 6px 9px 5px;
            }

            &:hover{
              .elementHover{
                visibility: visible;
                opacity: 1;
                bottom: -35px;
                line-height: initial;
                font-weight: 400;
                padding: 6px 9px 5px;
              }
            }
          }
          .name-btn-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            .nameBlock {
              margin-top: 0;
              display: flex;
              align-items: center;
              .userInitials {
                margin-left: 0;
              }
              .nameWithInitials {
                margin-top: 0;
                width: 120px;
              }
            }
            .story-addStory {
              white-space: nowrap;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              width: 32px;
              padding: 0;
              min-width: 32px;
              em {
                line-height: 0;
              }
            }
          }
        }

        &.projectRelease-detail {
          // padding: 13px 0 0;
          .releaseVersion-tableContent {
            height: calc(100vh - 290px);
          }
          .tableHead-col6 {
            // width: 12%;
            text-align: left;
            .customCheckFor-moveSelection {
              height: 14px;
              width: 14px;
              margin-right: 5px;
              .check-toggleMoveSelection {
                margin-top: 0;
              }
            }
          }
          .tableHead-col7 {
            // width: 6%;
            text-align: center;
            justify-content: center;
          }
          .tableHead-col8 {
            // width: 6%;
            text-align: center;
            justify-content: center;
          }
          .tableHead-col9 {
            // width: 12%;
            text-align: center;
            justify-content: center;
          }
          .tableHead-col10 {
            // width: 33%;
            text-align: left;
            padding: 0 10px;
          }
          .releaseVersion-tableHead {
            padding: 11px 20px 11px 40px;
            color: #83889e;
            font-size: 12px;
          }
          .releaseVersion-tableRow {
            padding: 15px 20px 15px 40px;
          }
          .story-summary {
            color: #83889e;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              color: #000;
            }
          }
          .story-type {
            line-height: 0;
            font-size: 13px;
            padding-top: 3px;
            .elementHover{
              text-transform: capitalize;
              line-height: initial;
              padding: 5px 9px 5px;
            }
            &:hover{
              .elementHover{
                text-transform: capitalize;
                visibility: visible;
                opacity: 1;
                bottom: -35px;
                line-height: initial;
              }
            }
          }
          .story-statusWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            padding-top: 3px;
            .story-statusIcon {
              margin-right: 0;
              margin-left: 15px;
            }
          }
          .story-priority {
            border: none;
            border-radius: 0;
            display: inline-flex;
            padding: 3px 4px 0;
            font-weight: 600;
            font-size: 8px;
            line-height: 11px;
            letter-spacing: 0.1em;
            text-align: center;
    
            .elementHover{
              line-height: initial;
              font-weight: 400;
              padding: 6px 9px 5px;
            }
    
            &:hover{
              .elementHover{
                visibility: visible;
                opacity: 1;
                bottom: -35px;
                line-height: initial;
                font-weight: 400;
                padding: 6px 9px 5px;
              }
            }
          }
          .name-btn-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            .nameBlock {
              margin-top: 0;
              display: flex;
              align-items: center;
              .userInitials {
                margin-left: 0;
              }
              .nameWithInitials {
                margin-top: 0;
                width: 120px;
              }
            }
            .story-addStory {
              white-space: nowrap;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              width: 32px;
              padding: 0;
              min-width: 32px;
              em {
                line-height: 0;
              }
            }
          }
    
          .releaseVersion-tableRow {
            // padding: 15px 0 10px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    
            @for $i from 1 through $userInitialsColorsLength {
              &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}) {
                .userInitials {
                  background-color: nth($userInitialsBackgroundColors, $i);
                  color: nth($userInitialsColors, $i);
                  border-color: nth($userInitialsBorderColor, $i);
                }
              }
            }
          }
    
          .tableHead-col6,
          .tableHead-col7,
          .tableHead-col8,
          .tableHead-col9,
          .tableHead-col10,
          .tableHead-col11,
          .tableHead-col12 {
            position: relative;
            display: inline-block;
            vertical-align: top;
          }
          .tableHead-col6 {
            width: 7%;
          }
          .tableHead-col7 {
            width: 5%;
            text-align: center;
          }
          .tableHead-col8 {
            width: 6%;
          }
          .tableHead-col9 {
            width: 9%;
          }
          .tableHead-col10 {
            width: 47%;
          }
          .tableHead-col11 {
            width: 26%;
          }
        }
  
        .releaseVersion-tableRow {
          padding: 15px 0 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
          @for $i from 1 through $userInitialsColorsLength {
            &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}) {
              .userInitials {
                background-color: nth($userInitialsBackgroundColors, $i);
                color: nth($userInitialsColors, $i);
                border-color: nth($userInitialsBorderColor, $i);
              }
            }
          }
        }
  
        .tableHead-col6,
        .tableHead-col7,
        .tableHead-col8,
        .tableHead-col9,
        .tableHead-col10,
        .tableHead-col11,
        .tableHead-col12 {
          position: relative;
          display: inline-block;
          vertical-align: top;
        }
        .tableHead-col6 {
          width: 5%;
        }
        .tableHead-col7 {
          width: 5%;
          text-align: center;
        }
        .tableHead-col8 {
          width: 10%;
        }
        .tableHead-col9 {
          width: 35%;
        }
        .tableHead-col10 {
          width: 25%;
        }
        .tableHead-col11 {
          width: 20%;
        }
      }
  
      .nameBlock {
        width: 100%;
        position: relative;
        //display: inline-block;
        margin-top: -3px;
      }
  
      .userInitials {
        width: 24px;
        height: 24px;
        position: relative;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 10px;
        border-radius: 50%;
        margin-left: 5px;
        padding-top: 2px;
      }
      .nameWithInitials {
        width: calc(100% - 30px);
        position: relative;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: #83889e;
        vertical-align: middle;
        padding-left: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        line-height: 14px;
        margin-top: 5px;
  
        &:focus,
        &:active {
          outline: none;
        }
      }
  
      .tableCell-block {
        width: 100%;
        position: relative;
        float: left;
        margin-top: -5px;
      }
  
      .story-statusWrapper {
        width: calc(100% - 60px);
        position: relative;
        display: inline-block;
  
        .story-statusIcon {
          width: 12px;
          height: 12px;
          position: relative;
          display: inline-block;
          border-radius: 50%;
          margin-right: 7px;
        }
        .story-statusType {
          width: calc(100% - 20px);
          position: relative;
          display: inline-block;
          font-size: 12px;
          color: #83889e;
        }
      }
      .story-priority {
        //width: calc(100% - 20px);
        position: relative;
        display: inline-block;
        font-size: 12px;
        color: #83889e;
      }
      .story-addStory {
        width: auto;
        position: relative;
        display: inline-block;
        padding: 5px 9px 5px;
        font-size: 12px;
        font-weight: 500;
        color: #6200ea;
        text-align: center;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #6200ea;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          background-color: #6200ea;
          color: #fff;
        }
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
    .featuresList {
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 13px;
      line-height: 19px;
      color: #83889e;
      padding: 12px 10px;
      border-bottom: 1px solid #dfe0e6;
    }
    .releaseDetail-content {
      width: 100%;
      height: 160px;
      position: relative;
      display: inline-block;
      padding: 10px 12px;
      font-size: 13px;
      color: #83889e;
      border-radius: 4px;
      border: 1px solid #dfe0e6;
      margin-bottom: 40px;
      outline: none;
      &:focus {
        border-color: #651fff;
      }
    }
    .createVersion {
      width: 100%;
      position: relative;
      display: inline-block;
    }
    .add-sprint-story {
      .links-list {
        display: flex;
        margin-bottom: 8px;
        .link {
          font-size: 13px;
          line-height: 30px;
          color: #595959;
          cursor: pointer;
          margin-right: 32px;
          border-bottom: 2px solid transparent;
          display: inline-block;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            color: #6200ea;
            border-bottom-color: #6200ea;
          }
        }
      }
      .search-input {
        .iconb-search {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 14px;
          line-height: 0;
          top: 10px;
          left: 12px;
          color: #83889e;
          z-index: 1;
        }
        .versionName {
          padding-left: 34px;
        }
      }
      .addStoryDD {
        bottom: 100%;
        z-index: 1;
      }
      .formBlock2 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .formBlock,
    .formBlock2 {
      width: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 20px;
    }
    .formBlock2 {
      min-height: 190px;
      max-height: 280px;
      margin-top: -18px;
      margin-bottom: 28px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity: 0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity: 0.2;
      }
      .empty-search-block {
        margin-top: 35px;
        p {
          font-size: 13px;
        }
      }
    }
    .label-type1 {
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 13px;
      line-height: 20px;
      color: #000;
      font-weight: normal;
      padding: 0;
      margin: 0 0 8px;
  
      sup {
        color: #ff1744;
        margin-left: 2px;
      }
    }
    .versionName {
      width: 100%;
      height: 34px;
      position: relative;
      display: inline-block;
      font-size: 13px;
      color: #000;
      padding: 0 12px;
      border: 1px solid #dfe0e6;
      box-sizing: border-box;
      border-radius: 3px;
  
      &:focus,
      &:active {
        outline: none;
        border: 1px solid #651fff;
        box-shadow: 0 0 4px rgba(101, 31, 255, 0.2);
      }
    }
  
    .storyReqDD-selected {
      width: 100%;
      position: relative;
      display: inline-block;
      text-align: left;
      //z-index:11;
  
      .selected-name {
        width: 100%;
        position: relative;
        display: inline-block;
        padding: 8px 10px 6px;
        font-size: 13px;
        //color: #262626;
        //margin: 10px 0;
        border: 1px solid #dfe0e6;
        border-radius: 3px;
        transition: background-color 0.2s ease-in-out;
  
        &:hover {
          background-color: #e8e8e8;
        }
  
        .iconb-devices-platefrom {
          font-size: 18px;
          height: 18px;
          width: 18px;
        }
  
        .selectedReq-name {
          position: relative;
          float: left;
          font-size: 13px;
          font-weight: normal;
          color: #262626;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          height: 14px;
          margin-top: 0;
        }
        .selectedDevicePlatform-name {
          width: calc(100% - 54px);
          position: relative;
          float: left;
          font-size: 13px;
          font-weight: normal;
          color: #262626;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          margin-top: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        .iconb-dd-down {
          position: relative;
          font-size: 9px;
          height: 14px;
          color: #262626;
          margin-left: 7px;
          float: right;
          margin-top: 5px;
          margin-right: -5px;
        }
      }
      .reqSelection-DD {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        z-index: 1;
        border: 1px solid #d9d9d9;
        border-radius: 0 0 3px 3px;
  
        &.forDevicePlatform {
          //width: 350px;
          max-height: 150px;
          overflow-y: auto;
  
          &::-webkit-scrollbar {
            width: 2px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-track {
            background: #f8f9fa;
          }
          &::-webkit-scrollbar-thumb {
            background: #b4b2b2;
            opacity: 0.2;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #b4b2b2;
            opacity: 0.2;
          }
        }
  
        &.openReqDD {
          top: 35px;
          z-index: 1;
          visibility: visible;
          opacity: 1;
        }
  
        .selectDeviceList-wrapper {
          width: 100%;
          position: relative;
          float: left;
          margin-bottom: 10px;
  
          .storyReqSelection-list {
            padding: 11px 10px 11px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            width: calc(100% - 50px);
            margin-left: 26px;
          }
          .selectedType-name {
            width: calc(100% - 34px);
            height: 18px !important;
          }
          &:last-of-type {
            .storyReqSelection-list {
              &:last-of-type {
                border-bottom: none;
              }
            }
          }
        }
        .deviceType {
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 13px;
          color: #3c3e49;
          font-weight: 500;
          margin: 20px 0 0;
          padding-left: 11px;
  
          em {
            font-size: 18px;
            display: inline-block;
            height: 18px;
            margin-right: 8px;
            vertical-align: text-bottom;
            margin-top: 0;
          }
        }
  
        .storyReqSelection-list {
          width: 100%;
          position: relative;
          float: left;
          padding: 9px 10px 8px;
          font-size: 13px;
          font-weight: normal;
          color: #262626;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
  
          &:hover {
            background-color: #e8e8e8;
          }
  
          .selectedType-name {
            position: relative;
            float: left;
            font-size: 13px;
            font-weight: normal;
            color: #262626;
            cursor: pointer;
            vertical-align: middle;
            height: 14px;
            margin-top: 4px;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
  
          .moveSelection-check {
            width: 25px;
            position: relative;
            float: left;
            vertical-align: middle;
            padding: 0;
            margin-right: 0;
          }

          .moveSelection-check{
            // width: 25px;
            // position: relative;
            // display: inline-block;
            // vertical-align: middle;
            // padding: 16px 0;
            // margin-right: 0;
    
            .customCheckFor-moveSelection{
              width: 22px;
              height: 20px;
              position: relative;
              display: inline-block;
              margin-bottom: 0;
              vertical-align: middle;
    
              .check-toggleMoveSelection{
                width: 14px;
                height: 14px;
                position: relative;
                display: inline-block;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background-color: #fff;
                border-radius: 2px;
                cursor: pointer;
                margin-top: 5px;
    
                &:before{
                  content: '';
                  width:8px;
                  height: 4px;
                  position: absolute;
                  top: 3px;
                  left: 2px;
                  border-left: 2px solid rgba(0,0,0,.015);
                  border-bottom: 2px solid rgba(0,0,0,.015);
                  display: block;
                  transform: rotate(-47deg);
                }
              }
    
              input[type='checkbox'].move-selected-checkbox:checked + .check-toggleMoveSelection{
                border-color: #6C00EA;
                background-color: #6C00EA;
    
                &:before{
                  content: '';
                  width: 8px;
                  height: 4px;
                  position: absolute;
                  top: 3px;
                  left: 2px;
                  border-left: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  display: block;
                  transform: rotate(-47deg);
                }
              }
            }
          }
        }
      }
  
      em {
        height: 14px;
        position: relative;
        float: left;
        font-size: 14px;
        margin-right: 5px;
        margin-top: 2px;
        color: #83889e;
        width: 16px;
      }
  
      .iconb-design,
      .iconb-prototype,
      .iconb-mobile,
      .iconb-tablet,
      .iconb-desktop,
      .iconb-tv,
      .iconb-watch {
        font-size: 16px;
      }
      .iconb-full-build {
        margin-left: 2px;
        font-size: 14px;
        //margin-top: 1px;
      }
  
      .iconbs-s1,
      .iconbs-s2,
      .iconbs-s3 {
        margin-top: 2px;
        margin-right: 5px;
      }
      .iconb-custom-phase {
        font-size: 15px;
        margin-top: 0;
      }
    }
  
    .addStoryDD {
      width: 100%;
      max-height: 195px;
      display: inline-block;
      position: absolute;
      bottom: 40px;
      left: 0;
      padding: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      overflow: hidden;
      overflow-y: auto;
  
      &::-webkit-scrollbar {
        width: 3px;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity: 0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity: 0.2;
      }
    }
    .searchedStoryList {
      width: 100%;
      display: inline-block;
      position: relative;
      padding: 10px;
      border-bottom: 1px solid #dfe0e6;
    }
    .searched-storyStatus {
      width: 12px;
      height: 12px;
      position: relative;
      display: inline-block;
      border-radius: 50%;
      margin:0 10px;
      vertical-align: middle;

      &.draft{
        background-color: #FF6D00;
      }
      &.running{
        background-color: #40C4FF;
      }
      &.paused{
        background-color: #FFAB00;
      }
      &.completed{
        background-color: #4CAF50;
      }
    }
    .searched-storyName {
      width: calc(100% - 52px);
      display: inline-block;
      position: relative;
      font-size: 12px;
      color: #83889e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      span {
        color: #000;
        font-weight: 400;
      }
    }
    .searched-addStory {
      width: 10px;
      position: relative;
      display: inline-block;
      padding: 0;
      font-size: 18px;
      font-weight: 500;
      color: #0091ea;
      text-align: center;
      margin-left: 10px;
      border-radius: 2px;
      background-color: #fff;
      border: none;
  
      &:focus,
      &:active {
        outline: none;
      }
    }
    .addStory-search {
      width: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 15px;

      &.in-release-version{
        width: 100%;
        max-width: 250px;
        justify-content: space-between;
        font-size: 12px;
        line-height: 20px;
        margin-top: 0;
        position: fixed;
        right: 18px;
        top: 176px;
        background: #fff;
        padding: 0;

        .bulk-selection{
          position: fixed;
          top: 165px;
          right: 274px;
          z-index: 111;
          background: #fff;
          width: calc(100% - 531px);
          margin: 0;
          padding: 13px 20px 7px 0;

          .infoHoverIcon {
            margin-left: 15px;
            display: inline-block;
            position: relative;
            padding-left: 15px;
            em {
              display: inline-block;
              width: 16px;
              height: 16px;
              vertical-align: sub;
              border-radius: 50%;
              line-height: 16px;
              text-align: center;
              color: #6200ea;
              font-size: 16px;
            }
            &:after {
              content: "";
              width: 1px;
              height: 20px;
              background-color: #dfe0e6;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
          }
          }
        }
      }
  
      .bulk-selection {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 20px;
        margin-top: 15px;
        .story-addStory {
          display: inline-flex;
          align-items: center;
          background: #651fff;
          border-radius: 4px;
          color: #fff;
          em {
            line-height: 0;
            margin-right: 6px;
          }
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .bulk-left {
          display: flex;
          align-items: center;
          color: #83889e;
          .deselect {
            display: inline-flex;
            align-items: center;
            .customCheckFor-moveSelection {
              width: auto;
              height: 14px;
              font-weight: 400;
              display: inline-flex;
              align-items: center;
              cursor: pointer;
              .check-toggleMoveSelection {
                margin-top: 0;
                margin-right: 8px;
              }
            }
          }
          .story-selected {
            display: inline-flex;
            align-items: center;
            position: relative;
            padding-right: 8px;
            margin-right: 8px;
            &::after {
              content: "";
              width: 1px;
              height: 20px;
              background-color: #dfe0e6;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            .count {
              padding: 0 10px;
              background: #6c00ea;
              border-radius: 30px;
              color: #fff;
              line-height: 18px;
              font-size: 13px;
              margin-right: 6px;
            }
          }
        }
      }
  
      .addStory-searchTitle {
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 12px;
        margin-bottom: 10px;
      }
  
      .addStory-searchInput {
        width: 100%;
        height: 36px;
        position: relative;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        color: #3c3e49;
        padding: 0 15px 0 40px;
        border-radius: 3px;
        border: 1px solid #dfe0e6;
        background-color: transparent;
  
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
  
        &:focus {
          border-color: #651fff;
        }
  
        &::-webkit-input-placeholder {
          color: #afb2c0;
        }
        &:-ms-input-placeholder {
          color: #afb2c0;
        }
        &::placeholder {
          color: #afb2c0;
        }
      }
      .addStory-searchButton {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 1;
        background-color: #fff;
        text-align: center;
        border: none;
  
        &:focus,
        &:active {
          outline: none;
        }
  
        em {
          color: #83889e;
          line-height: 36px;
        }
      }
    }
  
    .release-commonPopUp-actionBtn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0;
      line-height: normal;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 625px;
      padding: 20px 32px;
      background: #ffffff;
      box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.08);

      .validationMsg{
        display: inline-block;
        position: absolute;
        left: 28px;
        top: 33px;
        color: red;
    }
  
      .release-commonPopUp-cancel {
        width: 85px;
        height: 38px;
        color: #000;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        padding: 8px 20px;
        background-color: transparent;
        border-radius: 3px;
        border: 1px solid #000;
        cursor: pointer;
        margin-right: 17px;
  
        &:focus,
        &:active {
          outline: none;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
  
      .release-commonPopUp-submit {
        // width: 85px;
        height: 38px;
        color: #fff;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        padding: 8px 20px;
        border-radius: 3px;
        background-color: #651fff;
        border: 1px solid #651fff;
        cursor: pointer;
  
        &:focus,
        &:active {
          outline: none;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
      .release-commonPopUp-cancel-s {
        width: 48%;
        height: 38px;
        position: relative;
        float: right;
        color: #6c00ea;
        font-size: 14px;
        text-align: center;
        padding: 11px 10px;
        background-color: transparent;
        border-radius: 2px;
        border: solid 1px #6c00ea;
        cursor: pointer;
        margin-right: 15px;
  
        &:focus,
        &:active {
          outline: none;
        }
      }
  
      .release-commonPopUp-submit-s {
        width: 48%;
        height: 38px;
        position: relative;
        float: right;
        color: #fff;
        font-size: 14px;
        text-align: center;
        padding: 11px 10px;
        border-radius: 2px;
        background-color: #6c00ea;
        cursor: pointer;
  
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
}

.commentListWrapper-common {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 0 0 50px 0;
  //border-radius: 4px;
  // background-color: #ffffff;

  .note {
    margin-bottom: 0;
  }

  .note_form {
    width: 625px;
    // height: 54px;
    position: fixed;
    display: inline-block;
    //margin-bottom: 25px;
    right: 0;
    bottom: 0;
    z-index: 11;
    background: #fff;
    border-top: solid 1px #e9e9f0;
    border-left: solid 1px #e9e9f0;
    .flex-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      .commentAreaBtn {
        margin-bottom: 8px;
        margin-top: 0;
      }
    }
  }

  .textAreaForComment {
    width: calc(100% - 140px);
    min-height: 50px;
    max-height: 300px;
    position: relative;
    display: inline-block;
    padding: 10px 10px 10px;
    z-index: auto;
    line-height: 18px;
    font-size: 13px;
    border: none;
    border-radius: 0;
    color: #000;
    resize: none;
    overflow-y: auto !important;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #ffffff;
    }

    &:focus {
      &::-webkit-input-placeholder {
        color: #fff !important;
      }
      &:-ms-input-placeholder {
        color: #fff !important;
      }
      &::placeholder {
        color: #fff !important;
      }
    }

    // placeholder
    &::-webkit-input-placeholder {
      line-height: 30px !important;
    }
    &:-ms-input-placeholder {
      line-height: 30px !important;
    }
    &::placeholder {
      line-height: 30px !important;
    }

    // textarea scrollbar
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f8f9fa;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity: 0.4;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity: 0.4;
    }
  }
  .commentAreaBtn {
    position: relative;
    display: inline-block;
    background: #fff;
    border-top: 0;
    vertical-align: top;
    float: right;
    margin-top: 6px;

    .uplForImage {
      position: relative;
      display: inline-block;
      padding-left: 15px;
      margin-top: 5px;
      font-size: 11px;
      color: #555;
      font-weight: 600;
    }

    .noteActionWrapper {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 4px 10px 0;
      //border-top: 1px solid rgba(0,0,0,.1);

      .addingTag-btn {
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        text-align: center;
        border: none;
        background-color: transparent;
        vertical-align: middle;
        padding: 0;
        margin-right: 7px;
        margin-top: 1px;

        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }

        em {
          font-size: 18px;
          color: #555555;
        }
      }
      .attach-file {
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        text-align: center;
        border: none;
        background-color: transparent;
        vertical-align: middle;
        left: -15px;
        top: 7px;

        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }

        em {
          font-size: 18px;
          color: #83889e;
          transform: rotate(45deg);
          display: block;
        }
      }

      .add-note {
        width: auto;
        position: relative;
        float: right;
        margin-top: 0;

        .postComment {
          width: 80px;
          height: 36px;
          position: relative;
          float: right;
          margin-top: 0;
          text-align: center;
          font-size: 12px;
          color: #fff;
          padding-top: 2px;
          border: none;
          font-weight: 500;
          background-color: #651fff;
          border-radius: 3px;

          &:disabled {
            background: #f5f5f5;
            color: #bfbfbf;
            cursor: not-allowed;
          }

          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .attach-file {
      width: 100%;
      position: relative;
      display: inline-block;
      text-align: right;

      .labelForFileType {
        width: auto;
        position: relative;
        display: inline-block;
        background-color: #fff;
        border-color: transparent;
        text-align: center;
        cursor: pointer;
        margin: 0;

        em {
          &.iconDisabled {
            cursor: not-allowed;
            color: #bfbfbf;
          }
        }
      }
      .forFiletype {
        width: 1px;
        height: 1px;
        position: absolute;
        left: -100%;
        top: 0;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
      }
    }
  }
  .attachments {
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    position: static;
    left: 0;
    bottom: 54px;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    overflow-y: auto;
    max-height: 180px;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f8f9fa;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity: 0.4;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity: 0.4;
    }

    &:empty {
      display: none;
    }

    a {
      color: #4a4a4a;
      text-decoration: none;
    }

    .attachment {
      display: inline-block;
      width: 100%;
      font-weight: 600;
      font-size: 12px;
      position: relative;
      border-radius: 4px;
      padding: 10px;
      background-color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-of-type {
        border-bottom: none;
      }
      .uploading-listing {
        width: 100%;
        position: relative;
        display: inline-block;

        .uploading-listing-thumbnail {
          width: 50px;
          height: 40px;
          position: relative;
          display: inline-flex;
          vertical-align: middle;
          text-align: center;
          justify-content: center;
          align-items: center;
          background-color: #ababab;

          img {
            max-width: 34px;
            height: auto;
          }
        }
        .uploading-file-description {
          width: calc(100% - 52px);
          position: relative;
          display: inline-block;
          padding: 0 7px 0;
          vertical-align: middle;

          .docuName {
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 12px;
            font-weight: 400;
            color: #000;
            word-wrap: break-word;
            margin-bottom: 4px;
          }
          .docuSize {
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            color: #555;
          }
        }
      }

      .removeDocument {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 0;
        margin-right: 0;
        padding: 2px 4px;
        border-radius: 2px;

        em {
          font-size: 18px;
          color: #999;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &:hover {
        background-color: transparent;

        .removeDocument {
          display: block;
          background-color: transparent;
        }
      }

      .file_description {
        .size {
          margin-bottom: 0;
        }

        .filename {
          margin-right: 25px;
          margin-top: 0;

          a {
            color: #4a4a4a;
            font-size: 12px;
            font-weight: 600;
            text-decoration: none;
            line-height: 16px;
          }
          span {
            color: #4a4a4a;
            font-size: 12px;
            font-weight: 600;
            text-decoration: none;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.action-on-release-note{
  position: absolute;
  top: 13px;
  right: 11px;
  font-size: 12px;
  padding: 7px 13px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #6200ea;
  color: #6200ea;
  display: block;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .iconb-edit{
    position: relative;
    display: inline-block;
    font-size: 11px;
    color: #6200ea;
    margin-right: 5px;
    cursor: pointer;
    height: 12px;
  }

  &:hover{
    background: #6200ea;
    border: 1px solid #6200ea;
    color: #fff;

    .iconb-edit{
      color: #fff;
    }
  }
}

.empty-block, .empty-block-feature {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: start;
  .icon {
    display: inline-flex;
    margin-bottom: 16px;
    img{
      width: 20px;
    }
  }
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    margin: 0;
    color: #83889e;
  }
}
.empty-block-feature {
  border-radius: 4px;
  border: 1px solid #dfe0e6;
  padding: 12px 20px;
  height: 48px;
}