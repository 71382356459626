@import "~compass-sass-mixins/lib/compass";
@import "~dragula/dist/dragula.css";

$userInitialsBackgroundColors: #E6F7FF, #FFF0F6, #ECEDF0, #F4EAFF;
$userInitialsBackgroundColorsLength: length($userInitialsBackgroundColors);
$userInitialsColors: #1890FF, #F759AB, #83889E, #9F4DFF;
$userInitialsColorsLength: length($userInitialsColors);
$userInitialsBorderColor:#91D5FF, #fbcbde, #d8dbe6, #e5cffd;
$userInitialsBorderColorLenght: length($userInitialsBorderColor);

.topHead-label{
  position: relativel;
  display: block;
  &:hover{
    .elementHover{
      visibility: visible;
      opacity: 1;
      bottom: -32px;
      transform: translateX(-50%);
    }
  }
}

.epicPopupView{
  .topNavigation {
    z-index: 0;
  }
  .trackerNav .trackerNavigation-wrapper {
    z-index: inherit;
  }
  .trackerNav .navCollapseExpand {
    z-index: 3;
  }
  .trackerContent-wrapper{
    .trackerContent{
      z-index: 150;
    }
  }
}
.deletePopupView{
  .trackerContent-wrapper{
    .trackerContent{
      z-index: 150;
      .topNavigation{
        z-index: 1;
      }
    }
  }
}
.adjustLayer{
  .trackerContent-wrapper{
    .trackerContent{
      // z-index: 150;
      .topNavigation{
        z-index: 1;
      }
    }
  }
}
.collapseNav{
  .trackerContent{
    width: calc(100% - 60px);

    .allocationAlert{
      width: calc(100% - 60px);
      left: 60px;
    }
    .freezePanel{
      width: calc(100% - 60px);
      left: 60px;
    }
    .changeLayout{
      .bulk-selection {
        width: calc(100% - 371px) !important;
      }
    }
  }
  .trackerNav{
    width: 60px;
    .trackerNavigation-wrapper{
      width: 60px;
      padding: 20px 12px;
      .trackerLogo{
        text-align: center;
      }
      .navigationBlock{
        .navigationBlock-links{
          em{
            &.iconb-projectfolder{
              margin-left: 1px !important;
            }
          }
        }
        .projectSelection-DD{
          .projectSelection-selected {
            padding: 10px 0 9px;
          }
          .projectProgress-wrapper{
            display:none;
          }
        }
      }
      .navigation-listName, .ddarw{
        display: none !important;
      }
      .planningDD{
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
    .c-dd-menu.c-dd-menu-active{
      left: 60px;
    }
  }
  .menuListHover{
    display: inline-block;
  }
}

.navbar-brand {
  padding-top: 10px;
}

body{
  font-family: 'Rubik', sans-serif;
}
::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #bbb;
  min-height: 40px;
}

body {
  margin: 0;
  padding: 0;
}


.column, div.epiccolumn{
  position: relative;
}

.blocker-block{
  mention-list{
    top: 35px!important;
  }
}
mention-list{
  width: 100%;
  left: 0 !important;

  .scrollable-menu{
    width: 100%;
  }
}

.customCheck_default{
  width: 16px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  height: 14px;
  vertical-align: text-top;

  .check-toggle{
    width: 13px;
    height: 13px;
    position: relative;
    display: inline-block;
    border: 1px solid rgba(0,0,0,.15);
    background-color: #fff;
    border-radius: 2px;
    cursor: pointer;

    &:before{
      content: '';
      width: 8px;
      height: 4px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-left: 1px solid rgba(0,0,0,.015);
      border-bottom: 1px solid rgba(0,0,0,.015);
      display: block;
      transform: rotate(-47deg);
    }
  }

  input[type='checkbox'].selected-checkbox:checked + .check-toggle{
    border-color: #6C00EA;
    background-color: #6C00EA;

    &:before{
      content: "";
      width: 8px;
      height: 4px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      display: block;
      transform: rotate(-47deg);
    }
  }
}

.makeFullLoader{
  width: 625px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;

  &.invoice_cycle{
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    img{
      width: 90px;
      height: auto;
    }
  }
  &.searched-user{
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);

    img{
      width: 90px;
      height: auto;
    }
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 999;
    top: -50%;
    left: -250%;
    display: block;
    width: 60%;
    height: 220%;
    background: linear-gradient(
        -270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.52) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(20deg);
    animation: makeFullLoader 2.5s infinite;
  }

  @-webkit-keyframes makeFullLoader {
    0% {
      left: -250%;
    }
    100% {
      left: 100%;
    }
  }
  @keyframes makeFullLoader {
    0% {
      left: -250%;
    }
    100% {
      left: 100%;
    }
  }

  img{
    width: 32px;
    height: auto;
  }
}

.storiesList-Loader{
  width: 100%;
  height: 90px;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 90px;
    height: auto;
  }
}

.common-Loader{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 200px;
    height: auto;
  }

  &.commonPopup-Loader{
    border-radius: 6px;
    opacity: .8;
    img{
      max-width: 90px;
      height: auto;
    }
  }
  &.projectList-Loader{
    opacity: .7;
    img{
      max-width: 80px;
      height: auto;
    }
  }
  &.create-release-Loader{
    opacity: .7;
    img{
      max-width: 80px;
      height: auto;
    }
  }
  &.story-compare-Loader{
    // opacity: .7;
    img{
      max-width: 80px;
      height: auto;
    }
  }
}
.storyApprovalPanel-Loader{
  height: 100%;
  width: calc(100% - 270px);
  max-width: 1170px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 200px;
    height: auto;
  }
}

.storyBoardPanel-Loader, .sprintInitial-Loader, .approvalfilter-Loader{
  width: 100%;
  height: calc(100vh - 53px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 200px;
    height: auto;
  }
}
.sprintInitial-Loader{
  height: 100%;
  z-index: 126;
  img{
    max-width: 90px;
  }
}

.approvalfilter-Loader {
  height: calc(100vh - 100px);
  z-index: 126;
  top: 49px;
  img{
    max-width: 90px;
  }
}

.whiteBoardPanel-Loader{
  width: 100%;
  height: calc(100vh - 53px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 200px;
    height: auto;
  }
}

.storyBoardPanel-filterLoader{
  width: 100%;
  height: calc(100vh - 134px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 200px;
    height: auto;
  }
}
.attachmentAction-Loader{
  width: 100%;
  height: 170px;
  position: absolute;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 90px;
    height: auto;
  }
}
.subTaskAction-Loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,.9);
  z-index: 112;
  img{
    max-width: 90px;
    height: auto;
  }
}

.drag-Loader{
  width: 100%;
  // height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;
  border-radius: 20px;

  img{
    max-width: 130px;
    height: auto;
  }
  &.drag-height1 {
    height: 305px;
  }
  &.drag-height2 {
    height: 225px;
  }
}
.coloumnLoader{
  width: calc(100% - 15px);
  height: calc(100% - 60px);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;
  border-radius: 0;
  top: 50px;
  left: 8px;
  opacity: .9;
  img{
    max-width: 90px;
    height: auto;
  }
}

.test-suite-story-loader{
  width: 100%;
  height: calc(100% - 45px);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;
  border-radius: 0;
  top: 50px;
  left: 0;
  opacity: .9;
  img{
    max-width: 90px;
    height: auto;
  }
}

.bulkAction-Loader{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;
  border-radius: 20px;
  &.set-height {
    min-height: 278px;
    position: relative;
  }
  img{
    max-width: 130px;
    height: auto;
  }
}
.detailStory-Loader{
  width: 625px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1111;
  border-radius: 20px;

  img{
    max-width: 130px;
    height: auto;
  }
}
.parentList-Loader{
  width: 100%;
  height: 190px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 60px;
    height: auto;
  }
}
.mainProjectList-Loader, .userActivityList-Loader, .userCard-Loader{
  width: 100%;
  height: 90px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 60px;
    height: auto;
  }
}
.featureStoryList-Loader{
  width: 100%;
  height: 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 112;

  img{
    max-width: 40px;
    height: auto;
  }
}
//.userCard-Loader{
//  height: 100%;
//  background-color: #FAFAFA;
//}
.userActivityList-Loader, .userCard-Loader{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #FAFAFA;
  z-index:1;
}
.makeRelative{
  position: relative;
  display: block;
}

.import-loadingWrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:11;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-text{
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-top: 90px;
  }
}
.import-loading{
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 4px solid #6C00EA;
  border-top-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation:import-loading 1.2s linear infinite;
  -webkit-animation:import-loading 1.2s linear infinite;
}
@keyframes import-loading{
  0%{transform:rotate(0deg)}
  100%{transform:rotate(360deg)}
}
@-webkit-keyframes import-loading{
  0%{-webkit-transform:rotate(0deg)}
  100%{-webkit-transform:rotate(360deg)}
}

.import-processingWrapper{
  width: 60px;
  height: 60px;
  position: relative;
  z-index:11;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  .loading-text{
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-top: 90px;
  }
}
.import-processing{
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 4px solid #6C00EA;
  border-top-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation:import-processing 1.2s linear infinite;
  -webkit-animation:import-processing 1.2s linear infinite;
}
@keyframes import-processing{
  0%{transform:rotate(0deg)}
  100%{transform:rotate(360deg)}
}
@-webkit-keyframes import-processing{
  0%{-webkit-transform:rotate(0deg)}
  100%{-webkit-transform:rotate(360deg)}
}

a img {
  border: 0;
}
.mb-0{
  margin-bottom: 0 !important;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-8{
  margin-bottom: 8px;
}

.relative {
  position: relative;
}

.usersInitals-wrapper{
  width: auto;
  position: relative;
  float: right;
  margin-top: -3px;
  margin-right: 10px;
  z-index: 118;

  .duplicateStoryPopup-open & {
    z-index: 1;
  }


  &:focus, &:active{
    outline: none;
  }

  .userInitials-block{
    width: auto;
    position: relative;
    display: inline-block;
  }

  .usersInitials{
    width: 30px;
    height: 30px;
    position: relative;
    display: none;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #FFFFFF;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .elementHover{
      padding: 7px 11px 5px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover{
      .elementHover{
        visibility: visible;
        opacity: 1;
        bottom: -32px;
        transform: translateX(-50%);
      }
    }

    &:nth-child(1){
      display: inline-flex;
      background-color:#E6F7FF !important;
      color: #1890FF !important;
      z-index: 3;
      border-color: #91D5FF;
    }
    &:nth-child(2){
      display: inline-flex;
      background-color: #E8F5E9;
      color: #4CAF50;
      margin-left: -6px;
      z-index: 2;
      border-color: #9af19d;
    }
    &:nth-child(3){
      display: inline-flex;
      background-color: #FFF0F6;
      color: #F759AB;
      margin-left: -6px;
      z-index: 1;
      border-color: #fbcbde;
    }
    &:last-of-type{
      display: inline-flex;
      background-color: #ECEDF0;
      color: #83889E;
      margin-left: -6px;
      z-index: 0;
      border-color: #d8dbe6;
    }
  }

  .userListDD{
    width: 225px;
    max-height: 360px;
    position: absolute;
    top: 70px;
    right: 0;
    border: 1px solid #DFE0E6;
    box-sizing: border-box;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    z-index: 115;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    padding: 10px 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }

    &.userListDD-open{
      top: 33px;
      visibility: visible;
      opacity: 1;
    }
    .userList-infoWrapper{
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 10px 15px;
      transition: all 0.3s ease-in-out;

      @for $i from 1 through $userInitialsBackgroundColorsLength {
        &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}){
          .userList-initials{
            background-color: nth($userInitialsBackgroundColors, $i);
            color: nth($userInitialsColors, $i);
            border-color: nth($userInitialsBorderColor, $i);
          }
        }
      }

      &.userNotActive{
        background-color: rgba(0,0,0,.05);
        cursor: not-allowed;

        &:hover{
          background-color: rgba(0,0,0,.05);

          .ifUserDeallocated{
            left: 55px;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    .userList-initials{
      width: 30px;
      height: 30px;
      position: relative;
      display: inline-flex;
      border-radius: 50%;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      border: 1px solid #FFFFFF;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      vertical-align: middle;
    }
    .userList-name{
      width: calc(100% - 30px);
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #676B7E;
      vertical-align: middle;
      padding-left: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ifUserDeallocated{
      width: calc(100% - 60px);
      position: absolute;
      top: 10px;
      left: 30px;
      font-size: 11px;
      font-weight: 500;
      color: #fff;
      background-color: #3C3E49;
      opacity: 0;
      visibility: hidden;
      transition: left 0.3s ease-in-out;
      z-index: 11;
      padding: 7px 12px;
      border-radius: 4px;
      cursor: not-allowed;
    }
  }
}

.viewAll-listWrapper{
  .usersInitals-wrapper{
    margin-top: 0;
    margin-left: 10px;
    z-index: 110;
    .userInitials-block{
      .usersInitials{
        width: 26px;
        height: 26px;
        font-weight: 400;
      }
    }
  }
}

.quickTimeAction-backdrop{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 11;
}

.quickTimeAction{
  width: auto;
  color: #FFFFFF;
  font-size: 11px;
  letter-spacing: -0.01em;
  padding: 20px;
  border-radius: 4px;
  background-color: #3C3E49;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  white-space: nowrap;
  transition: all 0.2s ease;
  z-index: 120;
  visibility: hidden;

  &.openTimeAction{
    visibility: visible;
    opacity: 1;
    top: 28px;
    transform: translateX(-50%);
  }

  &:before{
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    background-color: #3C3E49;
  }

  em.iconb-close{
    font-size: 10px;
    display: inline-block;
    position: absolute;
    top: 3px;
    right: 2px;
    cursor: pointer;
    height: 10px;
    padding: 2px 6px;
  }

  .timeIncButton{
    width: auto;
    position: relative;
    display: inline-block;
    padding: 3px 6px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 12px;
    line-height: 12px;
    margin-right: 15px;
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:last-of-type{
      margin-right: 0;
    }

    &:hover{
      background-color: #ffffff;
      color: #3C3E49;
    }
  }
}
.elementHover-horizontal{
  width: auto;
  color: #FFFFFF;
  font-size: 11px;
  padding: 5px 9px 5px;
  background-color: #3C3E49;
  display: block;
  position: absolute;
  top: 0;
  left: 90%;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: all 0.3s ease;
  z-index: 111;
}

.elementHover{
  width: auto;
  color: #FFFFFF;
  font-size: 11px;
  letter-spacing: -0.01em;
  padding: 5px 9px 3px;
  border-radius: 4px;
  background-color: #3C3E49;
  display: block;
  position: absolute;
  bottom: -45px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  z-index: 111;

  &:before{
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    background-color: #3C3E49;
  }
}

.elementHover-table{
  width: auto;
  color: #FFFFFF;
  font-size: 11px;
  letter-spacing: -0.01em;
  padding: 5px 9px 3px;
  border-radius: 4px;
  background-color: #3C3E49;
  display: block;
  position: absolute;
  bottom: -45px;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  z-index: 111;

  &:before{
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    background-color: #3C3E49;
  }
}

.timerHover{
  width: 234px;
  padding: 6px 9px 4px;
  border-radius: 4px;
  background-color: #3C3E49;
  display: block;
  position: absolute;
  top: 25px;
  left: 50%;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%);
  z-index: 111;
  font-size: 12px;
  color: #fff;
  transition: all 0.3s ease;

  .timer_type{
    width: 143px;
    position: relative;
    display: inline-block;
    text-align: left;
  }
  .timer_value{
    padding: 3px;
    width: 70px;
    position: relative;
    display: inline-block;
  }
}
.menuListHover{
  width: auto;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 10px 15px 10px;
  border-radius: 4px;
  background-color: #3C3E49;
  display: none;
  position: fixed;
  top: auto;
  margin-top: -25px;
  left: 75px;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: all 0.3s ease;
  z-index: 111;
  &:before{
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    position: absolute;
    top: 49%;
    left: -6px;
    transform: rotate(45deg) translateY(-50%);
    background-color: #3C3E49;
  }

  &.defaultTooltip {
    display: inline-block;
    left: 230px;
    .collapseNav & {
      display: none;
    }
  }
}

.storyBoard-navigation {
  width: calc(100% + 12px);
  margin-right: -12px;
  position: relative;
  display: inline-block;
  max-height: calc(100vh - 310px);
  overflow: hidden auto;
  
  &::-webkit-scrollbar {
    width: 2px;
    border-radius:2px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(60, 62, 73, 0.2);
    border-radius: 2px;
  }

  .navigation-list{
    width: 100%;
    position: relative;
    display: inline-block;
    padding-right: 12px;

    .navList-item{
      width: 100%;
      position: relative;
      display:block;
      padding: 9px 10px 8px;
      background: #ffffff;
      border-radius: 3px;
      color: #83889E;
      cursor: pointer;
      margin: 2px 0;
      transition: all .3s ease-in-out;

      em{
        font-size: 16px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-right: 10px;
        height: 18px;

        &.iconb-fade{
          transform: rotate(90deg);
          font-size: 14px;
          margin-top: -2px;
          margin-left: -1px;
        }

        &.iconb-project-dashboard{
          font-size: 14px;
          height: 16px;
        }
        &.iconb-hotspost{
          font-size: 14px;
          height: 16px;
        }
        &.ddarw{
          position: absolute;
          top: 15px;
          right: 10px;
          font-size: 9px;
          color: #83889E;
          margin: 0;
          height: 9px;
          transition: all .2s ease-in-out;
        }
        &.notOpen{
          transform: rotate(180deg);
        }
      }
      .navigation-listName{
        font-size: 13px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        // margin-right: 10px;
        height: 18px;

        .text-1, .text-2{
          width: 120px;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
        }

        .count {
          margin: 0;
          font-size: 12px;
          font-style: normal;
          height: auto;
          margin-left: 10px;
        }
      }

      &:active, &:focus{
        outline: none;
      }

      &:hover, &.active{
        color: #6C00EA;
        background: #F4EAFF;
      }
      &:hover{
        .menuListHover{
          visibility: visible;
          opacity: 1;
          left: 55px;
          &.defaultTooltip {
            left: 208px;
          }
        }
      }
      &.planningDD{
        width: calc(100% - 15px);
        padding: 7px 10px 7px;
        margin: 2px 0 2px 15px;
        em{
          font-size: 14px;
          margin-right: 7px;
          height: 18px;
        }
      }
    }
  }
}

/* Story types */

.makePopupAttop{
  .revStoryDes-popup{
    .revStoryDes-popupWrapper{
      .storyDetailsWrapper{
        .storyDetails-container{
          .acceptance-comment-Activity-wrapper{
            z-index: 114;
          }
        }
      }
    }
  
    &.expand{
      
      .revStoryDes-popupWrapper{
        
        .storyDetailsWrapper{
          .storyDetails-container{
            .topContent-wrapper{
              z-index: 2;
            }
            .acceptance-comment-Activity-wrapper{
              z-index: 113;
            }
          }
        }
      }
    }
  }
}

.revStoryDes-popup{
  width: 685px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1111;
  text-align: center;
  transition: all .3s ease-in-out;

  &.hideBox{
    opacity: 0;
    visibility: hidden;
  }

  .storyCollapseExpand{
    width: 32px;
    height: 32px;
    position: absolute;
    left: -21px;
    top: 79px;
    // transform: translateY(-50%);
    display: inline-flex;
    border: 1px solid #DFE0E6;
    box-shadow: 0 0 10px rgba(131, 136, 158, 0.1);
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #fff;
  
    .iconb-open-story, .iconb-close-story{
      font-size: 16px;
      color: #9B9FB1;
      display: inline-block;
      height: 16px;
       
      .elementHover-extra{
        width: auto;
        color: #FFFFFF;
        font-size: 11px;
        letter-spacing: -0.01em;
        padding: 5px 9px 3px;
        border-radius: 4px;
        background-color: #3C3E49;
        display: block;
        position: absolute;
        top: 4px;
        left: 52px;
        opacity: 0;
        pointer-events: none;
        white-space: nowrap;
        transition: all 0.3s ease;
        z-index: 111;
        text-transform: capitalize;
      
        &:before{
          content: "";
          width: 8px;
          height: 8px;
          display: block;
          position: absolute;
          top: 10px;
          left: 0;
          transform: rotate(45deg) translateX(-50%);
          background-color: #3C3E49;
        }
      }
      &:hover  .elementHover-extra{
        visibility: visible;
        opacity: 1;
        // bottom: 0;
        left: 40px;
      }
    }
  }
  .storyNext{
    width: 32px;
    height: 32px;
    position: absolute;
    left: -21px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    border: 1px solid #DFE0E6;
    box-shadow: 0 0 10px rgba(131, 136, 158, 0.1);
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #fff;
    font-size: 11px;
    color: #9B9FB1;

    .elementHover-extra{
      width: auto;
      color: #FFFFFF;
      font-size: 11px;
      letter-spacing: -0.01em;
      padding: 5px 9px 3px;
      border-radius: 4px;
      background-color: #3C3E49;
      display: block;
      position: absolute;
      top: 4px;
      left: 52px;
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
      transition: all 0.3s ease;
      z-index: 111;
      text-transform: capitalize;
    
      &:before{
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        top: 10px;
        left: 0px;
        transform: rotate(45deg) translateX(-50%);
        background-color: #3C3E49;
      }
    }
    &:hover  .elementHover-extra{
      visibility: visible;
      opacity: 1;
      // bottom: 0;
      left: 42px;
    }
  }
  .storyPrevious{
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    border: 1px solid #DFE0E6;
    box-shadow: 0 0 10px rgba(131, 136, 158, 0.1);
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 111;
    background-color: #fff;
    font-size: 11px;
    color: #9B9FB1;

    .elementHover-extra{
      width: auto;
      color: #FFFFFF;
      font-size: 11px;
      letter-spacing: -0.01em;
      padding: 5px 9px 3px;
      border-radius: 4px;
      background-color: #3C3E49;
      display: block;
      position: absolute;
      top: 4px;
      right: 52px;
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
      transition: all 0.3s ease;
      z-index: 111;
      text-transform: capitalize;
    
      &:before{
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        top: 10px;
        right: -5px;
        transform: rotate(45deg) translateX(-50%);
        background-color: #3C3E49;
      }
    }
    &:hover  .elementHover-extra{
      visibility: visible;
      opacity: 1;
      // bottom: 0;
      right: 40px;
    }
  }

  .revStoryDes-backdrop{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 25px;
    z-index:1;
    transition:all .3s ease-in-out ;

    &.activeContent{
      right: 0;
      opacity: 1;
    }
  }

  .revStoryDes-popupWrapper{
    width: 625px;
    height: 100%;
    top: 0;
    right: 0;
    //max-width: 625px;
    position: absolute;
    display: inline-block;
    //padding: 35px;
    background: #ffffff;
    z-index: 2;
    border-left: 1px solid #DFE0E6;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    margin: 0;
    //opacity: 0;
    text-align: left;
    transition: all .3s ease-in-out;
  
  }

  &.expand{
    width: 1300px;
    .storyCollapseExpand{
      z-index: 3;
    }
    .revStoryDes-popupWrapper{
      padding-right: 625px;
      width: 1250px;
      .close-storyPopup{
        left: calc(100% - 1303px);
      }

      .storyDetailsWrapper{
        .detailStory-Loader{
          width: 1250px;
        }
        .makeFullLoader{
          width: 1250px;
        }

        .commentListWrapper-common{
          padding: 0;
        }
        .storyTop-block{
          top: 0;
          right: 625px;       
        }
        .storyDetails-container{
          .topContent-wrapper{
            z-index: 2;
          }
          .acceptance-comment-Activity-wrapper{
            position: fixed;
            top: 0;
            right: 0;
            width: 625px;
            height: calc(100vh - 50px);
            margin-top: 0;
            padding-top: 54px;
            box-shadow: inset 0px 10px 38px rgb(0 0 0 / 5%);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            z-index: 1;
            overflow-y: auto;

            &::before{
              content: '';
              width: 100%;
              height: 2px;
              left: 0;
              top: 51px;
              position: absolute;
              border-bottom: 1px solid rgba(0, 0, 0, .1);
            }
            &::-webkit-scrollbar {
              background-color: transparent;
              width: 6px;
              height: 6px;
              border-radius: 20px;
            }
            &::-webkit-scrollbar-thumb {
              background: #bbb;
              min-height: 40px;
              border-radius: 20px;
            }
            &::-webkit-scrollbar-track {
              margin: 20px 4px;
            }
          }
        }
      }
    }
  }
}

.blocker-inCollapsed{
  width: 100%;
  position: relative;
  display: inline-block;
  padding-right: 5px;
  vertical-align: middle;
  cursor: pointer;
  text-align: right;
  margin-top: 9px;

  &:empty{
    display: none;
  }
}
.blockerContent-block{
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 6px 13px;

  &:empty{
    display: none;
  }

  &.bb-story{
    .blockerInfo-wrapper{
      background-color: #7da4e9;
    }
  }

  .blockerInfo-wrapper{
    width: auto;
    max-width: calc(100% - 36px);
    position: relative;
    display: inline-block;
    border-radius: 40px;
    background-color: #e84850;
    padding: 3px;
    overflow: hidden;

    &:last-of-type{
      margin-bottom: 0;
    }

    .blockerIcon-wrapper{
      width: 28px;
      height: 28px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      border: 2px solid #fff;
      padding: 1px;
      margin-right: 7px;

      .ifBlocker, .ifBlockedBy{
        width: 22px;
        height: 22px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        border: 1px solid #e84850;
        background-color: #ffffff;
        text-align: center;

        img{
          max-width: 15px;
          height: auto;
          margin-top: 4px;
        }
      }
      .ifBlockedBy{
        border: 1px solid #7da4e9;
      }
    }
    .blocker-description{
      color: #fff;
      font-size: 13px;
      border-radius: 3px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      padding: 8px 0;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      margin-right: 15px;
      max-width: calc(100% - 50px);
    }
  }
}

// Show Project Brief

.breifAttachment-wrapper {
  .slick-prev {
    left: -12px !important;
    z-index: 1;
    transform: translate(0, -40%) rotate(90deg);
  }
  .slick-next {
    right: -12px !important;
    z-index: 1;
    transform: translate(0, -40%) rotate(90deg);
  }
  .slick-prev, .slick-next{
    background: #fff;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #ECEDF0;
  }
  .slick-prev {
    &:before {
      content: "\63" !important;
      font-family: "builder-tracker" !important;
      font-size: 11px !important;
      color: #9B9FB1 !important;
      vertical-align: middle;
    }
  }
  .slick-next {
    &:before {
      content: "\63" !important;
      font-family: "builder-tracker" !important;
      font-size: 11px !important;
      color: #9B9FB1 !important;
      vertical-align: middle;
      transform: rotate(180deg);
      display: inline-block;
    }
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    //background: #fff !important;
    color: transparent;
  }
}

.overlay-board {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-attachment: fixed;
  text-align: center;
  z-index: 99999;
  top: 0;
  left: 0;
}
.brandingGuildlines{
  width:600px;
  right:-630px;
  position: fixed;
  background-color: #ffffff;
  padding: 20px 0 32px 32px;
  z-index:999999;
  top:0;
  height:100%;

  &.openBrandingGuidlines{
    right:0;
  }

  .tag-box {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #333333;
    background: #ECEDF0;
    padding: 4px 16px;
    border-radius: 32px;
    margin-bottom: 16px;
    max-width:400px;
  }
  .common-desc-view{
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 5px;
    padding:16px;
    overflow-x: auto;
    max-height: 144px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #F1F1F1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    p,a{
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #333333;
      margin-bottom:0;
    }
    a{
      display:block;
      color:#2F54EB;
    }
  }
  .guideline-btn{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #333333;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 5px;
    background:none;
    margin-right:28px;
    padding:9px 15px;
    em{
      display:inline-block;
      vertical-align:middle;
      margin-left:12px;
    }
    &:focus{
      outline:none;
      box-shadow: none;
    }
  }

  .filterPanelHeader{
    height: auto;
    padding: 0;
    display: block;
    border: none;
    padding-right:32px;
    padding-bottom:32px;

    .iconb-close{
      width: 24px;
      height: 24px;
      position: absolute;
      top: 26px;
      right: 44px;
      font-size: 24px;
      z-index: 1;
      cursor: pointer;
      color: #333;
    }

    h3{
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.01em;
      color: #333333;
      margin:0;
    }
    .close-icon{
      cursor:pointer;
      font-size: 25px;
      vertical-align: middle;
      color: #333;
    }
  }
  .filterPanelContent {
    overflow:auto;
    height:calc(100% - 10px);
    padding-right:32px;
    padding-bottom:60px;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #F1F1F1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .common-guide-box{
      margin-top: 30px;
      &.brand-logo-box{
        margin-top:0;
      }
      .common-guide-item{
        margin-top:24px;
        &.m0{
          margin-top:0;
        }
        &.m10{
          margin-top:10px;
        }
      }
      .common-guide-head{
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.01em;
        color: #333;
        border-bottom: 1px solid #333;
        padding-bottom: 8px;
      }
      .guide-logo{
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 5px;
        height:120px;
        width:120px;
        display:flex;
        align-items: center;
        justify-content: center;
        img{
          max-width: 100px;
          max-height: 100px;
        }
      }
      .logo-item{
        margin-left: 0;
        padding-left: 0;
        li {
          display: flex;
          margin-bottom:16px;
          .info-logo-box{
            margin-left:16px;
            margin-top: 18px;
          }
          &:last-child{
            margin-bottom:0;
          }
        }
      }
      .font-names{
        font-size: 14px;
        line-height: 20px;
        color:#000;
      }
      ul.font-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom:0;
        padding: 0;
        li {
          margin-left: 7px;
          width: 32.3%;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          .tag-box{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            text-align:left;
          }
        }
      }
      ul.color-items{
        display:flex;
        flex-wrap:wrap;
        margin:0 -8px;
        padding: 0;
        li{
          padding:0 8px;
          margin-bottom:16px;
          img{
            max-width: 37px;
            max-height: 37px;
          }
          .color-circle{
            width:40px;
            height:40px;
            border-radius:100%;
            margin:0 auto;
            border:1px solid #D9D9D9;
          }
          .color-code{
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            margin-top:4px;
            letter-spacing: -0.01em;
            color: #333333;
          }
        }
      }
    }
  }
}

.story-note-com{
  display: block;
  padding-bottom: 40px;
}

// Notes
.note {
  // background-color: #fff;
  font-size: 12px;
  padding: 10px 10px 10px 6px;
  display: block;
  margin-bottom: 5px;
  position: relative;

  &:last-child {
    margin-bottom: 50px;
  }
}
.attachInfo{
  width: 79%;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .attachment_description{
    position: relative;
    width: calc(100% - 125px);
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: #83889E;

    .attachfilename, .attachsize{
      width: 100%;
      position: relative;
      display: inline-block;
      color: #83889E;
      font-size: 11px;
    }
  }
  .attachment_thumbnail{
    background-color: #f2f2f2;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 0;
    height: auto;
    width: 62px;
    max-width: 100%;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    min-height: 48px;
    justify-content: center;
    align-items: center;

    .videoContainer{
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      em{
        display: inline-block;
        font-size: 18px;
        height: 20px;
      }
    }

    img{
      max-width: 100%;
      height: auto;
    }
    .default-attachment{
      max-width: 35px;
      width: auto;
      max-height: 32px;
    }

    .thumbnail_inner {
      background-color: #f2f2f2;
      display: block;
    }

    abbr {
      font-size: 11px;
      font-weight: 700;
      line-height: 37px;
      text-align: center;
      text-transform: uppercase;
      display: block;
    }
  }
}
.downloadAttachment{
  width: 32px;
  position: absolute;
  top: 50%;
  right: 21px;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  margin-top: 3px;

  img{
    max-width: 14px;
  }
  &.changePosition{
    right: 0;
  }
  em{
    font-size: 16px;
    color: #83889E;
  }
  .elementHover{
    padding: 5px 11px 4px;
  }

  &:hover{
    .elementHover{
      visibility: visible;
      opacity: 1;
      bottom: -32px;
      transform: translateX(-50%);
    }
  }
}
.deleteDocument{
  width: 32px;
  position: absolute;
  top: 50%;
  right: -11px;
  transform: translateY(-50%);
  display: inline-block;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  padding-top: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  em{
    font-size: 16px;
    color: #83889E;
  }
  .elementHover{
    padding: 5px 11px 4px;
  }

  &:hover{
    .elementHover{
      visibility: visible;
      opacity: 1;
      bottom: -32px;
      transform: translateX(-50%);
    }
  }
}
.popover div.note {
 a.delete-note {
    display: none;
  }
}

.popover-content {
  padding: 0 !important;

  &.right, &.left {
    padding: 0;
  }
}

.sessions-list-wrapper {
  .popover-content {
    padding: 6px !important;
    text-align: center;
  }
}

.edit-project,
.add-user,
.edit-user,
.members-list {
  .member-name {
    float: left;
    margin-top: 5px;
  }
}

.edit-user .checkbox,
.sign-in .checkbox {
  margin-left: 50px;
}

.date-select-wrapper select {
  display: inline-block;
}

.current-password {
  margin-top: 20px;
}

.storyBoard-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  z-index: 111;
  .duplicateStoryPopup-open & {
    z-index: inherit;
  }

  .storyBoard-topBlock{
    width: 100%;
    position: relative;
    padding: 13px 15px 13px 40px;
    display: inline-block;

    .storyBoard-title{
      width: auto;
      position: relative;
      display: inline-block;
      font-size: 24px;
      font-weight: 500;
      color: #000;
    }
    .storyBoardSearch-wrapper{
      position: relative;
      float: right;
      display: inline-flex;

      .searchArea-block{
        width: 170px;
        height: 34px;
        display: inline-block;
        border: 1px solid #DFE0E6;
        box-sizing: border-box;
        border-radius: 3px;
        text-align: right;
        margin: -3px 0 0 8px;
        cursor: text;

        em{
          font-size: 14px;
          display: inline-block;
          height: 14px;
          color: #DFE0E6;
          margin-top: 10px;
          margin-right: 10px;
        }
      }
      .searchWrapper-backdrop{
        width:100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index:11;
        //background-color: rgba(0,0,0,.65);
      }
      .searchWrapper{
        width: 484px;
        position: absolute;
        top:-5px;
        right: 0;
        display: inline-block;
        padding: 10px 0;
        border: 1px solid #DFE0E6;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        background-color: #fff;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        z-index: 115;

        &.searchWrapper-open{
          top: -5px;
          visibility: visible;
          opacity: 1;
        }
        .searchInput-wrapper{
          width: 100%;
          position: relative;
          display: inline-block;
          padding:0 10px;

          .searchinput{
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 12px;
            color: #83889E;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #DFE0E6;
            padding: 0 160px 7px 0;

            &:focus, &:active{
              outline: none;
            }
            &::-webkit-input-placeholder {
              color: #8C8C8C;
            }
            &:-ms-input-placeholder {
              color: #8C8C8C;
            }
            &::placeholder {
              color: #8C8C8C;
            }
          }
          .searchInput-info{
            width: auto;
            position: absolute;
            display: inline-block;
            font-size: 12px;
            color: #83889E;
            top: 2px;
            right: 0;
            padding-right: 10px;

            em{
              font-size: 14px;
              display: inline-block;
              height: 14px;
              color: #3C3E49;
              margin-left: 8px;
              float: right;
              margin-top: 1px;
            }
          }
        }
        .resultToggle-wrapper{
          width: auto;
          float: left;
          position: relative;
          margin-top: 8px;
          margin-left: 12px;

          .resultStatus{
            width: 25px;
            height: 14px;
            float: left;
            position: relative;
            background-color: #6200EA;
            border-radius: 14px;
            margin: 0 10px;

            .toggleCircle{
              width: 10px;
              height: 10px;
              position: absolute;
              top: 2px;
              left: 2px;
              border-radius: 50%;
              background-color: #ffffff;
              transition: all .2s ease-in-out;
            }

            &.resultStoriesActive{
              .toggleCircle{
                left: 2px;
              }
            }
            &.resultLabelsActive{
              .toggleCircle{
                left: 13px;
              }
            }
          }

          .resultStatus-title{
            width: auto;
            float: left;
            position: relative;
            color: #9B9FB1;
            font-size: 10px;
            background-color: transparent;
            border: none;
            padding: 0;
            font-weight: 500;
            margin-top: 1px;
            transition: color .3s ease-in-out;

            &:active, &:focus{
              outline: none;
            }
            &:hover{
              color: #6200EA;
            }
            &.activeToggle{
              color: #6200EA;
            }
          }
        }
        .searhResultWrapper{
          width: 100%;
          position: relative;
          display: inline-block;
          max-height: 250px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 2px;
            height: 2px;
          }
          &::-webkit-scrollbar-track {
            background: #F8F9FA;
          }
          &::-webkit-scrollbar-thumb {
            background: #b4b2b2;
            opacity:0.2;
            border-radius: 12px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #b4b2b2;
            opacity:0.2;
          }
        }

        .resultBlock{
          width: 100%;
          position: relative;
          display: inline-block;
          //padding: 17px 0;
          border-bottom: 1px solid #DFE0E6;

          &:last-of-type{
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .blank_search {
          width: 100%;
          position: relative;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .blank_searchLoader{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 36px;
            margin-bottom: 12px;
          }
          p {
            font-size: 13px;
            color: #777;
          }
        }
        .result-title{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 10px;
          color: #83889E;
          padding:0 10px;

          img{
            width: 20px;
            height: auto;
          }
        }
        .resultListing-labelFeatureWrapper{
          width: 100%;
          position: relative;
          display: block;
          padding:0 10px;

          .labelName{
            max-width: 380px;
            position: relative;
            float: left;
            color: #3C3E49;
            font-size: 9px;
            background: #ECEDF0;
            border-radius: 3px;
            padding: 3px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 7px 15px 7px 0;
            cursor: pointer;
          }
          .featureName{
            max-width: 380px;
            position: relative;
            float: left;
            color: #9f4dff;
            font-size: 9px;
            background: #f4eaff;
            border-radius: 3px;
            padding: 3px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 7px 15px 7px 0;
            cursor: pointer;
          }
        }
        .resultListing{
          width: calc(100% - 10px);
          position: relative;
          float: left;
          padding: 3px 5px;
          border-radius: 3px;
          margin:3px 5px;
          cursor: pointer;

          &:first-of-type{
            margin-top: 10px;
          }

          &:hover{
            background: #ECEDF0;
          }

          .storyName{
            max-width: calc(100% - 60px);
            position: relative;
            float: left;
            font-size: 12px;
            color: #3C3E49;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 13px;
            margin-top: 2px;
          }
          .storyState-icon{
            width: 14px;
            height: 14px;
            position: relative;
            float: left;
            border-radius: 50%;
            margin: 1px 8px 0;
          }
          em{
            font-size: 12px;
            float: left;
            height: 12px;
            margin-top: 2px;

            &.iconb-task{
              font-size: 14px;
              height: 15px;
              margin-top: 0;
            }
            &.iconb-side-effect{
              font-size: 13px;
            }
            &.iconb-chore{
              font-size: 13px;
              height: 13px;
              margin-top: 1px;
            }
            &.iconb-release{
              font-size: 11px;
              height: 11px;
              margin-top: 3px;
            }
            &.iconb-bug{
              font-size: 12px;
            }
          }
          .storyTypeIcon{
            font-size: 15px;
            color: #83889E;
            float: left;
            margin-right: 10px;
            margin-top: 0;
            height: 15px;
          }
          .storyDescription{
            width: 100%;
            position: relative;
            float: left;
            font-size: 10px;
            color: #676B7E;
            word-break: break-word;
            margin-top: 7px;

            &:empty{
              display: none;
            }
          }
        }
      }
    }
    .ideButton-wrapper{
      width: auto;
      position: relative;
      float: right;
      margin-left: 15px;

      .ideButton{
        width: 152px;
      }
    }
    .ideButton{
      width: auto;
      position: relative;
      float: right;
      height: 30px;
      border-radius: 3px;
      border:none;
      padding: 7px 11px;
      background-color: #F4EAFF;
      font-size: 12px;
      color: #6C00EA;
      font-weight: 500;
      text-decoration: none;
      margin-left: 8px;
      cursor: pointer;

      em.iconb-stories{
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        height: 15px;
        margin-right: 6px;
      }
      em.iconb-dd-down{
        font-size: 10px;
        display: inline-block;
        vertical-align: middle;
        height: 12px;
        margin-left: 6px;
      }
      em.iconb-copyid {
        line-height: 0;
        display: inline-block;
        position: relative;
        top: 2px;
        margin-left: 10px;
        &:hover {
          .elementHover {
            visibility: visible;
            opacity: 1;
            bottom: -32px;
            transform: translateX(-50%);
          }
        }
        .elementHover {
          line-height: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .ide-DD{
      width: 180px;
      max-height: 360px;
      position: absolute;
      top: 33px;
      left: 0;
      visibility: visible;
      opacity: 1;
      z-index: 115;
      transition: all 0.3s ease-in-out;
      border-radius: 4px;
      padding: 9px 0;
      overflow-y: auto;
      background-color: #3C3E49;
      mix-blend-mode: normal;
      border: 1px solid #ECEDF0;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }

    .ideButtons{
      width: 100%;
      position: relative;
      float: left;
      border:none;
      padding: 11px 15px;
      background-color: transparent;
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      text-decoration: none;
      cursor: pointer;
      //text-transform: capitalize;

      &:last-child:not(:first-child) {
        em.iconb-copyid { 
          &:hover {
            .elementHover {
              bottom: auto;
              top: -32px;
            }
          }
          .elementHover {
            bottom: auto;
            top: -45px;
            &::before {
              top: auto;
              bottom: -6px;
            }
          }
        }
      }

      em{
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        height: 15px;
        margin-right: 6px;
      }

      em.iconb-copyid {
        position: absolute;
        right: 10px;
        top: calc(50% - 8px);
        margin: 0;
        &:hover {
          .elementHover {
            visibility: visible;
            opacity: 1;
            bottom: -32px;
            transform: none;
          }
        }
        .elementHover {
          line-height: 16px;
          font-style: normal;
          font-weight: 400;
          color: #3C3E49;
          background: #fff;
          right: -7px;
          left: auto;
          transform: none;
          &::before {
            background-color: #fff;
            left: calc(100% - 14px);
          }
        }
      }
    }

    .me-filter-btn {
      display: inline-flex;
      align-items: center;
      float: right;
      padding: 6px 8px 4px;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #83889E;
      transition: all 0.3s ease-in-out;
      margin-right: 4px;
      cursor: pointer;
      border-radius: 2px;
      &:hover {
        background: #ECEDF0;
      }
      &.selected {
        background-color: #F3EBFF;
        color: #6200EA;
      }
      em {
        line-height: 0;
        margin-right: 4px;
        font-size: 13px;
        position: relative;
        top: -1px;
      }
      span {
        line-height: 0;
        cursor: pointer;
        margin-left: 6px;
        font-size: 10px;
      }
      &.disabled {
        pointer-events: none;
      }
    }

    .show-hide-task-wrapper {
      position: relative;
      float: right;
      margin-right: 4px;
      &.open {
        .btn-trigger {
          background: #ECEDF0;
        }
        .dropdown-wrapper {
          opacity: 1;
          visibility: visible;
        }
      }
      .btn-trigger {
        font-size: 12px;
        color: #83889E;
        height: 22px;
        position: relative;
        border-radius: 3px;
        border: none;
        padding: 7px 8px;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        height: 30px;
        cursor: pointer;
        &:hover {
          background: #ECEDF0;
        }
        .iconb-eye {
          display: inline-flex;
          line-height: 0;
          margin-right: 6px;
          position: relative;
          top: 3px;
          font-size: 13px;
        }
      }
      .dropdown-wrapper {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        right: 0;
        top: calc(100% + 3px);
        z-index: 7;
        border: 1px solid #DFE0E6;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
        border-radius: 4px;
        background: #fff;
        width: 159px;
        padding: 2px 10px;
        transition: all 0.3s ease-in-out;
        .option {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #83889E;
          padding: 9px 4px;
          border-bottom: 1px solid #DFE0E6;
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
          &.active {
            color: #6200EA;
          }
        }
      }
    }


    .trackedHour-info{
      width: auto;
      position: relative;
      float: right;
      height: 25px;
      padding-top: 7px;
      margin-right: 8px;
      &.has-margin {
        margin-left: 8px;
      }

      .trackedHour-infoText{
        display: inline-block;
        font-size: 12px;
        color: #83889E;
      }
      .trackedHour-detail{
        display: inline-block;
        font-size: 12px;
        color: #83889E;
        margin: 0 7px 0 3px;
      }
      .iconb-info{
        position: relative;
        top: -1px;
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        color: #83889E;
        line-height: 0;
        .elementHover{
          line-height: 16px;
        }

        &:hover{
          .elementHover{
            visibility: visible;
            opacity: 1;
            bottom: -32px;
            transform: translateX(-50%);
          }
        }
      }
    }


    .viewButton-wapper{
      width: auto;
      position: relative;
      float: right;
      margin-right: 4px;
      &.is-secondary {
        margin-right: 3px;
        .viewButton {
          .sprint-icon {
            line-height: 0;
            margin-right: 4px;
            font-size: 13px;
            position: relative;
            top: -1px;
            display: inline-flex;
            height: auto;
          }
          .dropdown-arrow {
            font-size: 10px;
            height: 20px;
            width: 20px;
            margin: 0 0 0 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .viewButton{
        width: auto;
        height: 30px;
        position: relative;
        display: inline-block;
        background-color: #fff;
        border-radius: 3px;
        border:none;
        padding: 6px 8px;
        transition: background-color .3s ease-in-out;
        cursor: pointer;
        
        em{
          font-size: 16px;
          display: inline-block;
          vertical-align: middle;
          color: #83889E;
          height: 16px;
          margin-right: 6px;
        }
        .viewbutton-title{
          display: inline-block;
          font-size: 12px;
          color: #83889E;
        }

        &:hover{
          background-color: #ECEDF0;
        }
        &[disabled]{
          opacity: 0.5;
          cursor: not-allowed;
          &:hover{
            background-color: transparent;
          }
          .dropdown-arrow{
            pointer-events: none;
          }
        }
        &.buttonSelected{
          background-color: #ECEDF0;
        }
        &.is-selected {
          background-color: #F3EBFF;
          .sprint-icon, .viewbutton-title, .dropdown-arrow {
            color: #6200EA;
          }
        }
      }
      .selectColumnDD{
        width: 162px;
        max-height: 360px;
        position: absolute;
        top: 70px;
        right: 0;
        border: 1px solid #DFE0E6;
        box-sizing: border-box;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        visibility: hidden;
        opacity: 0;
        z-index: 115;
        transition: all 0.3s ease-in-out;
        border-radius: 4px;
        padding: 3px 0;
        overflow-y: auto;
        &.sprint-dropdown{
          width: 200px;
          max-height: inherit;
          overflow: inherit;
          .selectColumn-name{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .selectColumn-listing{
            .tooltip-icon{
              cursor: pointer;
              position: relative;
              &:hover{
                .elementHover{
                  visibility: visible;
                  opacity: 1;
                  bottom: auto;
                  top: calc(100% + 5px);
                }
              }
              .elementHover{
                width: 250px;
                white-space: inherit;
                bottom: auto;
                top: calc(100% + 15px);
              }
            }
          }
        }
        &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
        }
        &::-webkit-scrollbar-track {
          background: #F8F9FA;
        }
        &::-webkit-scrollbar-thumb {
          background: #b4b2b2;
          opacity:0.2;
          border-radius: 12px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #b4b2b2;
          opacity:0.2;
        }

        &.selectColumnDD-open{
          top: 33px;
          visibility: visible;
          opacity: 1;
        }
        .selectColumn-listing{
          width: 100%;
          position: relative;
          display: inline-block;
          padding: 8px 15px;
        }
        .selectColumn-check{
          width: 16px;
          height: 18px;
          position: relative;
          display: inline-block;
          vertical-align: middle;

          .customCheckForColumnSelection{
            width: 16px;
            position: relative;
            display: inline-block;
            margin-bottom: 0;

            .column-check-toggle{
              width: 13px;
              height: 13px;
              position: relative;
              display: inline-block;
              border: 1px solid rgba(0,0,0,.15);
              background-color: #fff;
              border-radius: 2px;
              cursor: pointer;

              &:before{
                content: '';
                width: 8px;
                height: 4px;
                position: absolute;
                top: 2px;
                left: 2px;
                border-left: 1px solid rgba(0,0,0,.015);
                border-bottom: 1px solid rgba(0,0,0,.015);
                display: block;
                transform: rotate(-47deg);
              }
            }

            input[type='checkbox'].column-selected-checkbox:checked + .column-check-toggle{
              border-color: #6C00EA;
              background-color: #6C00EA;

              &:before{
                content: "";
                width: 8px;
                height: 4px;
                position: absolute;
                top: 2px;
                left: 2px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                display: block;
                transform: rotate(-47deg);
              }
            }
          }
        }
        .selectColumn-name{
          width: calc(100% - 30px);
          position: relative;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          color: #3C3E49;
          vertical-align: middle;
          padding-left: 6px;
          cursor: pointer;
          margin: 0 0 1px;
        }
      }
    }
  }

  .storyBoard-block{
    width: 100%;
    position: relative;
    display:inline-block;
    background: #ffffff;
  }
  .storyBoard-columnWrapper{
    width: 100%;
    position: relative;
    display:inline-block;
  }
  .storyBoard{
    width: 100%;
    height: calc(100vh - 120px);
    position: relative;
    display: inline-block;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }

    &.no-feature-col {
      padding-left: 33px;
    }

    .storyBoard-column{
      width: 265px;
      position: relative;
      display: inline-block;
      background: transparent;
      border-radius: 6px;
      margin: 0 7px;
      white-space: initial;
      vertical-align: top;
      transition: all 0.5s;

      &:first-child {
        .drag_column_header {
          background-color: rgba(243, 235, 255, 0.5);
        }
        .expand-collapse-btn {
          background-color: rgba(243, 235, 255, 0.5);
        }
      }

      &.customColumnStories {
        .column_header {
          .story-header-right.selectWithinCol {
            margin-right: 0;
            margin-left: 5px;
          }
        }
        .drag_column_header {
          padding-left: 52px;
        }
      }

      &.shrinked {
        width: 48px;
        &:first-child {
          width: 95px;
          .drag_column_header {
            .expand-collapse-btn {
              right: 7px;
              background-color: rgba(243, 235, 255, 0.5);
              &:hover {
                background-color: rgba(243, 235, 255, 0.5);
              }
            }
            .toggle-title {
              margin-left: auto;
              margin-right: 7px;
            }
          }
        }

        .storyLoader {
          display: none;
        }
        .epiccolumn {
          height: calc(100vh - 182px);
          margin-top: 59px;
          background: rgba(243, 235, 255, 0.5);
        }
        .storycolumn {
          .projectStory {
            opacity: 0;
            visibility: hidden;
          }
          &:hover{
            &::-webkit-scrollbar-thumb {
              background: #F8F9FA;
            }
          }
        }
        .add-toDo.add-storyBtn {
          display: none;
        }
        .epiccolumn {
          .projectEpic {
            display: none;
          }
        }
        &:first-of-type {
          .drag_column_header {
            padding-left: 0;
          }
        }
        .drag_column_header {
          padding-left: 0;
          padding-right: 0;
          padding-top: 80px;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          background: transparent;
          &:hover {
            z-index: 10;
          }
          &.noStory{
            padding-left: 0;
          }
          .add-feature {
            display: none;
          }
          .featureStatus-toggle {
            display: none;
          }
          .feature-search-wrapper {
            display: none;
          }
          .toggle-title {
            writing-mode: vertical-rl;
            width: 48px;
            display: flex;
            align-items: center;
            .showTotalCount {
              margin: 0;
              margin-top: 8px;
            }
            .col-title {
              .iconb-search {
                display: none;
              }
            }
            .feature-search-string {
              width: 32px;
              .string {
                padding-right: 0;
                max-height: 100px;
                min-height: 75px;
              }
              .clear-search {
                margin-top: 10px;
                top: 0;
                left: 1px;
                &:hover {
                  .elementHover {
                    bottom: 20px;
                    left: -5px;
                  }
                }
                .elementHover {
                  left: -5px;
                  bottom: 25px;
                  padding: 7px 1px;
                  &::before {
                    top: auto;
                    bottom: -6px;
                    left: 10px;
                  }
                }
              }
            }
          }
          .expand-collapse-btn {
            width: 48px;
            height: 44px;
            right: 0;
            top: 0;
            transition: none;
            background: #ECEDF0;
            color: #3C3E49;
            .iconb-left-arrow {
              transform: rotate(180deg);
            }
            &:hover {
              background: #ECEDF0;
              color: #3C3E49;
              .elementHover-extra{
                left: 48px;
              }
            }
          }
        }
        .storycolumn {
          margin-top: 59px;
          background: #F8F9FA;
          border-radius: 6px;
          overflow: hidden;
          height: calc(100vh - 182px);
        }
        .column_header {
          display: none;
        }
      }

      &.make-hidden {
        display: none;
      }

      &.makeFullLenght{
        height: 100%;
      }

      &:first-of-type{
        margin-left: 40px;
        position: sticky;
        left: 0;
        background: #fff;
        z-index: 8;
        padding-left: 40px;
        margin: 0;
        width: 312px;
        padding-right: 7px;
      }

      &:last-of-type{
        margin-right: 20px;
      }

      .feature-search-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 12;
        background: #f9f5ff;
        border-radius: 4px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        .feature-search-outer {
          position: relative;
          width: 100%;
          .iconb-search {
            position: absolute;
            left: 8px;
            top: calc(50% + 1px);
            transform: translateY(-50%);
            color: #83889E;
            line-height: 0;
            font-size: 12px;
            cursor: pointer;
          }
          .search-input {
            background: transparent;
            outline: 0;
            border: 1px solid #C0C3CE;
            border-radius: 4px;
            font-size: 10px;
            color: #000;
            height: 30px;
            padding: 0 27px;
            width: 100%;
            &:-moz-placeholder {
              color: #83889E !important;
              font-size: 10px !important;
              opacity: 1 !important;
            }
            &::-ms-placeholder {
              color: #83889E !important;
              font-size: 10px !important;
              opacity: 1 !important;
            }
            &::-o-placeholder {
              color: #83889E !important;
              font-size: 10px !important;
              opacity: 1 !important;
            }
            &::-webkit-input-placeholder {
              color: #83889E !important;
              font-size: 10px !important;
              opacity: 1 !important;
            }
          }
          .clear-input {
            position: absolute;
            line-height: 0;
            color: #83889E;
            font-size: 11px;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
      }

      .newStory-wrapper, .newList-wrapper{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 4px;
        padding: 10px;
        border: 1px solid #ECEDF0;
        transition: width .3s ease-in-out;

        .newStory-title{
          width: 100%;
          min-height: 100px;
          position: relative;
          float: left;
          font-size: 12px;
          color: #000000;
          margin-bottom: 10px;
          line-height: 20px;
          border: none;
          background-color: transparent;
          resize: none;

          &::-webkit-input-placeholder {
            color: #8C8C8C;
          }
          &:-ms-input-placeholder {
            color: #8C8C8C;
          }
          &::placeholder {
            color: #8C8C8C;
          }

          &:focus, &:active{
            outline: none;
          }

          &:placeholder-shown {
            height: 100px !important;
          }
        }
        .newStory-actions{
          width: 100%;
          position: relative;
          float: left;

          button[type=button]{
            &:focus, &:active{
              outline: none;
            }
          }

          .addStory{
            width: 84px;
            height: 30px;
            display: inline-block;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            background: #6C00EA;
            mix-blend-mode: normal;
            border-radius: 3px;
            border: none;
            margin-right: 15px;

            &:disabled{
              opacity: .4;
              cursor: not-allowed;
            }
          }
          .newStoryForm-close{
            width: 84px;
            height: 30px;
            display: inline-block;
            font-weight: 500;
            font-size: 12px;
            color: #83889E;
            text-align: center;
            background: #fff;
            mix-blend-mode: normal;
            border-radius: 3px;
            border: none;
          }
        }
      }
      .newList-wrapper{
        width: calc(100% - 24px);
        margin: 0 12px 7px;
      }

      .drag_column_header{
        width: 100%;
        display: inline-block;
        position: relative;
        text-align: left;
        background-color: #ECEDF0;
        padding: 13px 15px 5px 35px;
        border-radius: 4px;
        cursor: move;
        min-height: 44px;
        &.noStory{
          padding-left: 15px;
        }
        &.to_do,
        &.epicHeader,
        &.qa,
        &.in_progress,
        &.rejected,
        &.accepted,
        &.moveDisable{
          cursor: auto;
        }

        .expand-collapse-btn {
          position: absolute;
          right: 8px;
          height: 28px;
          width: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          top: calc(50% - 13px);
          cursor: pointer;
          border-radius: 4px;
          font-size: 12px;
          transition: all 0.3s ease;
          .iconb-left-arrow {
            line-height: 0;
          }
          &:hover {
            background: #F3EBFF;
            color: #6200EA;
          }

          .elementHover-extra{
            width: auto;
            color: #FFFFFF;
            font-size: 11px;
            letter-spacing: -0.01em;
            padding: 5px 9px 3px;
            border-radius: 4px;
            background-color: #3C3E49;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 42px;
            opacity: 0;
            pointer-events: none;
            white-space: nowrap;
            transition: all 0.3s ease;
            z-index: 111;
            text-transform: capitalize;
          
            &:before{
              content: "";
              width: 8px;
              height: 8px;
              display: block;
              position: absolute;
              top: 10px;
              left: 0;
              transform: rotate(45deg) translateX(-50%);
              background-color: #3C3E49;
            }
          }
          &:hover  .elementHover-extra{
            visibility: visible;
            opacity: 1;
            left: 32px;
          }
        }

        .toggle-title{
          width: calc(100% - 60px);
          display: inline-block;
          color: #3C3E49;
          text-decoration: none;
          font-size: 14px;
          text-transform: none;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          transition: all 0.5s;

          &.canAddStory{
            width: calc(100% - 127px);
          }

          .col-title {
            display: inline-flex;
            align-items: center;
            .iconb-search {
              line-height: 0;
              display: inline-block;
              margin-left: 5px;
              cursor: pointer;
              font-size: 13px;
            }
          }

          .feature-search-string {
            width: 86px;
            display: inline-flex;
            align-items: center;
            .string {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              max-width: calc(100% - 12px);
              padding-right: 5px;
            }
            .clear-search {
              position: relative;
              cursor: pointer;
              line-height: 0;
              top: -1px;
              &:hover{
                .elementHover{
                  visibility: visible;
                  opacity: 1;
                  bottom: -4px;
                  transform: none;
                  left: calc(100% + 9px);
                }
              }
              em {
                line-height: 0;
                font-size: 12px;
              }
              .elementHover{
                bottom: -4px;
                transform: none;
                left: calc(100% + 15px);
                padding: 0 7px;
                font-weight: 400;
                line-height: 19px;
                &::before {
                  left: -1px;
                  top: 8px;
                }
              }
            }
          }
        }
        .showTotalCount{
          display: inline-block;
          color: #83889E;
          text-decoration: none;
          font-size: 11px;
          text-transform: none;
          font-weight: 500;
          margin-left: 4px;
          vertical-align: middle;
          margin-bottom: 2px;
        }

        .add-feature{
          position: absolute;
          z-index: 11;
          right: 40px;
          top: 14px;
          text-decoration: none;
          float: right;
          padding: 0;
          cursor: pointer;
          font-size: 12px;
          margin-top: 0;
          color: #83889E;
          font-weight: 500;
          transition: all .2s ease-in-out;

          &.feature-add{
            margin-left: 15px;
          }

          &:hover{
            color: #6C00EA;
          }
        }
        .featureStatus-toggle{
          width: auto;
          float: right;
          position: absolute;
          z-index: 11;
          margin-top: 0;
          right: 35px;
          top: 16px;

          .epicStatus{
            width: 25px;
            height: 14px;
            float: left;
            position: relative;
            background-color: #6200EA;
            border-radius: 14px;
            margin: 0 10px;
            cursor: pointer;

            .toggleCircle{
              width: 10px;
              height: 10px;
              position: absolute;
              top: 2px;
              left: 2px;
              border-radius: 50%;
              background-color: #ffffff;
              transition: all .2s ease-in-out;
            }

            &.epicStatusActive{
              .toggleCircle{
                left: 2px;
              }
            }
            &.epicEstimateActive{
              .toggleCircle{
                left: 13px;
              }
            }
          }

          .epicStatus-title{
            width: auto;
            float: left;
            position: relative;
            color: #9B9FB1;
            font-size: 10px;
            background-color: transparent;
            border: none;
            padding: 0;
            font-weight: 500;
            margin-top: 1px;
            transition: color .3s ease-in-out;

            &:active, &:focus{
              outline: none;
            }
            &:hover{
              color: #6200EA;
            }
            &.activeToggle{
              color: #6200EA;
            }
          }
        }
      }
      .column_headerforNewStory{
        width: 100%;
        min-height: 44px;
        display: inline-block;
        position: relative;
        text-align: left;
        background-color: #ECEDF0;
        padding: 11px 15px;
        border-radius: 4px;

        .add-newColumn{
          width: 100%;
          text-decoration: none;
          float: right;
          padding: 0;
          cursor: pointer;
          font-size: 12px;
          margin-top: 2px;
          color: #3C3E49;
          font-weight: 500;
          text-align: left;
          transition: all .2s ease-in-out;

          &.feature-add{
            margin-left: 15px;
          }

          &:hover{
            color: #6C00EA;
          }

          .plusIcon{
            position: relative;
            float: left;
            font-size: 22px;
            font-weight: 400;
            line-height: 18px;
            margin-right: 7px;
          }
          .plusIcon-text{
            position: relative;
            float: left;
            font-size: 14px;
          }
        }
      }
      .add-toDo.add-storyBtn {
        text-decoration: none;
        float: right;
        padding: 0;
        cursor: pointer;
        font-size: 12px;
        margin-top: 0;
        color: #83889E;
        font-weight: 500;
        transition: all .2s ease-in-out;
        position: absolute;
        right: 40px;
        top: 14px;

        &:hover{
          color: #6C00EA;
        }
      }
      .column_header {
        width: auto;
        display: inline-block;
        position: absolute;
        text-align: left;
        background-color: transparent;
        padding: 12px 15px;
        border-radius: 0;
        top: 0;
        left: 0;
        z-index:6;


        &.feature-colHeader{
          padding: 12px 13px 10px;
        }

        .toggle-title{
          width: auto;
          display: inline-block;
          color: #676B7E;
          text-decoration: none;
          font-size: 14px;
          text-transform: none;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &.feature-col{
            width: calc(100% - 185px);
          }
        }
        .add-toDo, .add-newColumn, .columnAction{
          text-decoration: none;
          float: right;
          padding: 0;
          cursor: pointer;
          font-size: 12px;
          margin-top: 2px;
          color: #83889E;
          font-weight: 500;
          transition: all .2s ease-in-out;

          &.feature-add{
            margin-left: 15px;
          }

          &:hover{
            color: #6C00EA;
          }
        }
        .columnAction{
          margin-top: 0;
          margin-left: -3px;
          position: relative;

          em{
            font-size: 18px;
            display: inline-block;
            height: 18px;
            margin-top: 2px;
          }
        }
        .columnName-actionDD{
          width: 120px;
          position: absolute;
          display: inline-block;
          top: 22px;
          left: 0;
          background-color: #3C3E49;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          padding: 6px 7px;
          border-radius: 3px;

          .actionList{
            width: 100%;
            position: relative;
            display: inline-block;
            color: #ffffff;
            cursor: pointer;
            padding: 7px 8px;

            em{
              font-size: 12px;
              display: inline-block;
              margin-right: 10px;
              height: 12px;
            }
            .actionList-title{
              position: relative;
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
            }

            &:hover{
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
        }

        .story-header-right{
          width: 24px;
          position: relative;
          display: inline-block;
          vertical-align: top;
          text-align: left;
          padding-top: 0;

          &.selectWithinCol{
            float: right;
            padding-top: 0;
            margin-right: 8px;
            margin-top: 0;
          }

          .story-selected-checkbox{
            width: 1px;
            height: 1px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
          }
          
          .customCheckForStorySelection + .elementHover{
            text-align: center;
            padding: 5px 9px 5px;
            transform: translateX(-47%);
            left: -5px;
            transform: none;
            &::before {
              left: 10px;
            }
          }
          .customCheckForStorySelection:hover + .elementHover{
            visibility: visible;
            opacity: 1;
            bottom: -47px;
          }

          .customCheckForStorySelection{
            width: 14px;
            height: 16px;
            position: relative;
            display: inline-block;
            margin-left: 0;
            margin-bottom: 0;
            vertical-align: middle;

            .check-toggle{
              width: 13px;
              height: 13px;
              position: relative;
              display: inline-block;
              border: 1px solid rgba(0,0,0,.15);
              background-color: #fff;
              border-radius: 2px;
              cursor: pointer;

              &:before{
                content: '';
                width: 7px;
                height: 4px;
                position: absolute;
                top: 2px;
                left: 2px;
                border-left: 1px solid rgba(0,0,0,.015);
                border-bottom: 1px solid rgba(0,0,0,.015);
                display: block;
                transform: rotate(-47deg);
              }
            }

            input[type='checkbox'].story-selected-checkbox:disabled + .check-toggle {
              cursor: not-allowed;
            }

            input[type='checkbox'].story-selected-checkbox:checked + .check-toggle{
              border-color: #6C00EA;
              background-color: #6C00EA;

              &:before{
                content: '';
                width: 7px;
                height: 4px;
                position: absolute;
                top: 2px;
                left: 2px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                display: block;
                transform: rotate(-47deg);
              }
            }
            .elementHover-checkbox{
              width: 242px;
              color: #FFFFFF;
              font-size: 11px;
              letter-spacing: -0.01em;
              padding: 5px 9px 3px;
              border-radius: 4px;
              background-color: #3C3E49;
              display: block;
              position: absolute;
              top: 28px;
              right: -11px;
              opacity: 0;
              pointer-events: none;
              transition: all 0.3s ease;
              z-index: 111;
              font-weight: 400;
              text-align: left;
            
              &:before{
                content: "";
                width: 8px;
                height: 8px;
                display: block;
                position: absolute;
                top: 0;
                right: 9px;
                transform: rotate(45deg) translateX(-50%);
                background-color: #3C3E49;
              }
            }
            &:hover{
              
              .story-selected-checkbox:disabled ~ .elementHover-checkbox{
                opacity: 1;
                visibility: visible;
                top: 20px;
              }
            }
          }
        }
      }

      .storycolumn {
        width: 100%;
        height: calc(100vh - 184px);
        display: inline-block;
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        padding: 0 10px 0;
        margin-top: 15px;

        &::-webkit-scrollbar {
          width: 2px;
          border-radius:2px;
        }
        &::-webkit-scrollbar-track {
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background: #fff;
          opacity:0.2;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #b4b2b2;
          opacity:0.2;
        }

        &:hover{
          &::-webkit-scrollbar-thumb {
            background: #b4b2b2;
          }
        }

        .projectStory {
          width: 100%;
          position: relative;
          display: inline-block;
          margin-bottom: 10px;
          min-width: 243px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .epiccolumn {
        width: 100%;
        height: calc(100vh - 184px);
        display: inline-block;
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        padding: 0 10px 13px;
        border-radius: 6px;
        margin-top: 15px;

        .projectEpic {
          min-width: 243px;
        }

        &::-webkit-scrollbar {
          width: 2px;
          border-radius:2px;
        }
        &::-webkit-scrollbar-track {
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background: #fff;
          opacity:0.2;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #b4b2b2;
          opacity:0.2;
        }

        &:hover{
          &::-webkit-scrollbar-thumb {
            background: #b4b2b2;
          }
        }
      }
    }
  }
}
.videoPopup-wrapper{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index:1112;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;


  .closeVideoPopup{
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    z-index: 2;
  }

  .videoPopup-backdrop{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;
  }
  .videoPopup {
    width: auto;
    max-width: 753px;
    position: relative;
    display: inline-block;
    padding: 50px;
    background: #ffffff;
    z-index: 2;
    box-shadow: 0 10px 80px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
}
.epicStoryDes-popup{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index:1111;
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
  display: inline-block;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bbb;
    min-height: 40px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    margin:20px 4px;
  }

  &.leavePlanner-feature-popup {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .epicStoryDes-popupWrapper {
      padding: 30px;
      border-radius: 8px;
      max-height: 574px;
      overflow: auto;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        height: 6px;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: #bbb;
        min-height: 40px;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        margin:20px 4px;
      }

      .closeEpicStoryPopup {
        color: #83889E;
        font-size: 14px;
      }
      .epicTitle {
        margin-bottom: 40px;
      }
      .feature-wrapper {
        .feature-outer {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin: 0;
            margin-bottom: 4px;
            color: #000;
          }
          .content {
            color: #83889E;
          }
        }
      }
    }
  }

  .epicStoryDes-backdrop{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;
  }

  .epicStoryDes-popupWrapper{
    width: 100%;
    max-width: 753px;
    position: relative;
    display: inline-block;
    padding: 50px;
    background: #ffffff;
    z-index:2;
    box-shadow: 0 10px 80px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    text-align: left;
    margin: 45px 0;

    .closeEpicStoryPopup {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 20px;
      cursor: pointer;
      z-index: 2;
      line-height: 0;
    }

    .epicTitle{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size:24px;
      font-weight: 500;
      color: #000000;
      &::first-letter{
        text-transform: capitalize;
      }
    }
    .timeInfoWrapper{
      width: 100%;
      position: relative;
      display: inline-block;
      color: #83889E;
      margin-top: 3px;

      em{
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        height: 14px;
      }
      .timeValue{
        position: relative;
        display: inline-block;
        font-size: 14px;
        margin-left: 3px;
      }
    }
    .epicContent-wrapper{
      width: 100%;
      position: relative;
      display: inline-block;
      margin-top: 25px;

      .contentTitle, .contentTitle2{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size:14px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
      }
      .contentTitle2{
        margin-top: 35px;
      }
      .contentDescription{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size:12px;
        color: #676B7E;
        word-break: break-word;
        img{
          max-width: 100%;
          height: auto;
        }

        .angular-editor {
          .angular-editor-wrapper {
            .angular-editor-textarea {
              overflow: auto;
            }
          }
        }

        .container{
          width: 100% !important;
          padding: 0;
          margin: 0;
        }
      }
      .epicAttachment-wrapper{
        width: 100%;
        position: relative;
        display: inline-block;
        padding: 8px 0 15px;

        .epicAttachment-list{
          width: 200px;
          height: 160px;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          border: 1px solid #DFE0E6;
          box-sizing: border-box;
          border-radius: 3px;
          margin: 0 13px;

          .assetsContainer, .videoContainer{
            width: 100%;
            height: 100px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #FAFAFA;

            .asst-container{
              width: 100%;
              height: 100%;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .att-type{
              position: absolute;
              width: 70px;
              height: 18px;
              left: 7px;
              top: 6px;
              border-radius: 20px;
              border: 1px solid #DFE0E6;
              background-color: #fff;
              color: #676B7E;
              text-align: center;
              font-size: 9px;
              text-transform: uppercase;
              padding-top: 2px;
            }

            em{
              display: inline-block;
              font-size: 36px;
              height: 40px;
            }

            img{
              max-width: 180px;
              height: auto;
              max-height: 80px;

              &.default-attachment{
                max-width: 40px;
              }
            }
          }
          .videoContainer{
            position: relative;
            cursor: pointer;

            .att-type{
              top: -4px;
              left: -4px;
            }

            &:hover{
              .elementHover{
                visibility: visible;
                opacity: 1;
                bottom: 0;
                transform: translateX(-50%);
              }
            }
          }

          .assetsInfo{
            width: 100%;
            position: relative;
            display: inline-block;
            padding-top: 20px;
            border-top: 1px #DFE0E6 solid;

            .assetsName{
              width: calc(100% - 60px);
              position: relative;
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              color: #000;
              text-align: left;
              padding: 0 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: top;
            }
            .videoName{
              width: 100%;
              position: relative;
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              color: #000;
              text-align: center;
              padding: 0 7px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: top;
            }
            .gotoImage{
              width: 60px;
              position: relative;
              display: inline-block;

              em{
                width: 24px;
                font-size: 16px;
                display: inline-block;
                cursor: pointer;
                color: #9B9FB1;
              }
              &:active, &:focus{
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}

.projectEpic {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.epic {
  width: 100%;
  position: relative;
  display: inline-block;

  &.epicHover{
    z-index: 2;
  }
}

.cursorPointer{
  cursor: pointer;
}

.epic-content {
  display: inline-block;
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ECEDF0;
  transition: width .3s ease-in-out;
  z-index: 1;
  //cursor: pointer;

  .feature-addNewStory {
    width: 18px;
    height: 16px;
    position: absolute;
    top: -10px;
    right: 10px;
    margin-left: 7px;
    margin-bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    z-index: 3;
    cursor: pointer;

    &.showToClick{
      top: 12px;
      visibility: visible;
      opacity: 1;
    }
    .type-selection-menu {
      background: #fff;
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      border: 1px solid #DFE0E6;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      width: 141px;
      padding: 3px 10px;
      span.type {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: #83889E;
        padding: 9px 0;
        border-bottom: 1px solid #DFE0E6;
        cursor: pointer;
        &:last-child {
          border-bottom: 0;
        }
        em[class^='iconb'] {
          color: #83889E;
          line-height: 0;
          margin-right: 8px;
          font-size: 12px;
        }
      }
    }

    em{
      font-size: 14px;
      color:#83889E;
      &.menu-visible {
        color: #651FFF;
      }
    }
    .iconb-plusIcon {
      &:hover{
        ~ .epicMinCardHover{
          visibility: visible;
          opacity: 1;
          top: 20px;
        }
      }
    }
  }

  .epicMinCardHover{
    width: auto;
    color: #FFFFFF;
    font-size: 11px;
    letter-spacing: -0.01em;
    padding: 5px 9px 5px;
    border-radius: 4px;
    background-color: #3C3E49;
    display: block;
    position: absolute;
    top: 30px;
    right: -21px;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    transition: all 0.3s ease;
    z-index: 111;

    &:before{
      content: '';
      width: 7px;
      height: 7px;
      display: block;
      position: absolute;
      top: -1px;
      right: 26px;
      transform: rotate(45deg) translateX(-50%);
      background-color: #3C3E49;
    }
  }

  .customCheckForStorySelection{
    width: 14px;
    height: 16px;
    position: absolute;
    top: 11px;
    right: 32px;
    display: inline-block;
    margin-left: 7px;
    margin-bottom: 0;
    vertical-align: middle;
    z-index: 111;

    &.story_writting_done{
      cursor: not-allowed;
      &::before{
        content: '';
        width: 14px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        background-color: rgba(255,255,255,.5);
        z-index: 2;
      }
    }
    .epicMinCardHover{
      width: auto;
      right: -25px;
    }

    &:hover{
      .epicMinCardHover{
        visibility: visible;
        opacity: 1;
        top: 20px;
      }
    }

    .check-toggle{
      width: 13px;
      height: 13px;
      position: relative;
      display: inline-block;
      border: 1px solid #6200EA;
      background-color: #fff;
      border-radius: 2px;
      cursor: pointer;

      &.checked {
        border-color: #6C00EA;
        background-color: #6C00EA;

        &:before{
          content: '';
          width: 7px;
          height: 4px;
          position: absolute;
          top: 2px;
          left: 2px;
          border-left: 1px solid #fff;
          border-bottom: 1px solid #fff;
          display: block;
          transform: rotate(-47deg);
        }
      }
    }
  }

  .epic-details {
    width: 100%;
    float: left;
    position: relative;

    .title{
      width: 90%;
      position: relative;
      float: left;
      font-size: 12px;
      font-weight: 400;
      color: #000;
      line-height: 20px;
      word-break: break-word;

      &::first-letter{
        text-transform: capitalize;
      }
    }

    .featuer-notes-label {
      display: inline-flex;
      align-items: center;
      float: left;
      padding: 3px 6px;
      border: 1px solid #ECEDF0;
      border-radius: 3px;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #83889E;
      margin-top: 3px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      .iconb-feature-notes {
        line-height: 0;
        margin-right: 8px;
        font-size: 12px;
      }

      &:hover{
        color: #9F4DFF;
        border-color: #9F4DFF;
      }
    }

    .bb-info-wrapper {
      float: left;
      width: 100%;
      border-top: 1px solid #F8F9FA;
      padding-top: 8px;
      margin-top: 8px;
  
      .bb-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        .bb-label {
          white-space: nowrap;
          color: #9B9FB1;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
        }
        .bb-right-info {
          display: flex;
          align-items: center;
          margin-bottom: -6px;
          justify-content: flex-end;
          .info-tag {
            display: inline-flex;
            align-items: center;
            padding: 1px 6px;
            background: #E2FCEA;
            border-radius: 3px;
            line-height: 20px;
            margin-right: 6px;
            margin-bottom: 6px;
            color: #2E7D32;
            width: 26px;
            overflow: hidden;
            transition: all 0.3s;
  
            &:last-child {
              margin-right: 0;
            }
  
            &.ongoing_customisation {
              background: #E6F8FF;
              color: #2B6CB0;
              &:hover {
                width: 128px;
              }
            }
            
            &.unassigned {
              background: #F6F7FB;
              color: #83889E;
              &:hover {
                width: 81px;
              }
            }
            
            &.assigned {
              background: #FFE3E3;
              color: #D73A49;
              &:hover {
                width: 70px;
              }
            }
            
            &.waiting {
              background: #FFF9DB;
              color: #F59E0B;
              &:hover {
                width: 62px;
              }
            }
            
            &.finished_customisation {
              background: #B9F6CA;
              color: #1B5E20;
              &:hover {
                width: 132px;
              }
            }
            
            &.finished_using_bb {
              background: #B9F6CA;
              color: #1B5E20;
              &:hover {
                width: 109px;
              }
            }
            
            &.skipped {
              background: #FDF2E9;
              color: #E85A4F;
              &:hover {
                width: 65px;
              }
            }
            
            &.bb_under_development {
              background: #E6F8FF;
              color: #2B6CB0;
              &:hover {
                width: 89px;
              }
            }
  
            &.is-expanded {
              width: auto !important;
              margin-right: 0;
              .info-label {
                text-indent: 0 !important;
                padding-left: 0;
              }
            }
  
            &:hover {
              width: 133px;
              .info-label {
                text-indent: 0;
              }
            }
            
            em {
              font-size: 14px;
              line-height: 0;
            }
            
            .info-label {
              padding-left: 5px;
              white-space: nowrap;
              font-size: 9px;
              text-indent: 10px;
            }
          }
        }
      }
    }

    .epic-briefDescription{
      width: 100%;
      float: left;
      position: relative;
      font-size: 11px;
      margin-top: 4px;

      .briefDescription{
        width: 87%;
        float: left;
        position: relative;
        padding-right: 10px;
        font-weight: 400;
        color: #9B9FB1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
        //overflow: hidden;
        height: 14px;

        &::first-letter{
          text-transform: capitalize;
        }

        ol, ul, li {
          display: none;
        }
      }
      .moreOption{
        width: 13%;
        float: left;
        position: relative;
        color: #6200EA;
        cursor: pointer;
      }
    }
    .epic-s-length{
      width: 60px;
      position: relative;
      float: left;
      text-align: right;
      margin-top: 0;
      font-size: 13px;
      font-weight: 500;
      color: #555;
      border-right: 2px solid #e9e9f0;
      padding-right: 10px;

      em{
        font-size: 14px;
        margin-right: 4px;
        color: #c28aa3;
        vertical-align: text-top;
        height: 16px;
        display: inline-block;
      }
    }
    .storyIcons-block {
      width: 100%;
      position: relative;
      float: left;
      margin-top: 5px;
      padding: 0;
      color: #9B9FB1;
      .storyIcons-container{
        float: left;
        position: relative;
        height: 14px;
        margin-right: 10px;
        margin-top: 5px;

        .storyTypeIcon-minCard{
          position: relative;
          float: left;
          color: #9B9FB1;
          font-size: 12px;
          cursor: pointer;
        }
        .elementHover{
          transform: none;
          left: -10px;
          &:before{
            left: 10px;
            top: -3px;
            transform: rotate(45deg);
          }
        }
        &:hover{
          .elementHover{
            visibility: visible;
            opacity: 1;
            bottom: -32px;
            transform:none;
          }
        }
      }
    }
    .allStories-progress{
      width: 100%;
      position: relative;
      float: left;
      padding: 0;
      text-align: right;
      margin: 13px 0 0;
      display: flex;

      .est-allStories-progressMade{
        border-radius:12px;
      }

      .allStoriesProgressBar{
        width: 194px;
        height: 4px;
        position: relative;
        float: left;
        background-color: #ECEDF0;
        border-radius: 6px;
        margin-left: 2px;
        display: inline-flex;

        .allStories-progressMade{
          height: 4px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #52C41A;
          z-index:1;
          border-radius: 6px;
        }
      }
      .allStories-counterbox{
        width: auto;
        position: relative;
        display: inline-flex;
        color: #9B9FB1;
        font-size: 10px;
        font-weight: normal;
        margin-left: 6px;
        float: left;
        margin-top: -5px;

        &:hover {
          .epicMinCardHover {
            visibility: visible;
            opacity: 1;
            top: 20px;
          }
        }

        .epicMinCardHover {
          width: 220px;
          white-space: pre-wrap;
          text-align: left;
        }

        .iconb-clock{
          font-size: 12px;
          position: relative;
          float: left;
          height: 13px;
          margin-right: 5px;
        }

      }

      .estimateProgressInfo{
        width: 277px;
        height: 210px;
        position: fixed;
        //left: 0;
        //top: 10px;
        padding: 15px;
        border-radius: 3px;
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
        z-index:12;
        text-align: left;

        .est-progressBarTitle{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: #676B7E;
        }
        .est-progressBar{
          width: 100%;
          position: relative;
          display: inline-block;
          margin-top: 15px;
        }
        .est-progressBarWrapper{
          width: 100%;
          position: relative;
          display: flex;
          white-space: nowrap;
        }
        .est-subTitle{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          color: #83889E;
          margin-bottom: 7px;
        }
        .est-allStoriesProgressBar{
          width: 194px;
          height: 4px;
          position: relative;
          float: left;
          background-color: #ECEDF0;
          border-radius: 6px;
          margin-left: 2px;
          display: inline-flex;

          .allStories-progressMade{
            height: 4px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #52C41A;
            z-index:1;
            border-radius: 6px;
          }
        }
        .est-allStories-counterbox{
          width: auto;
          position: relative;
          display: inline-flex;
          color: #9B9FB1;
          font-size: 10px;
          font-weight: normal;
          margin-left: 6px;
          float: left;
          margin-top: -5px;

          &.showPositive{
            color: #52C41A;
            background-color: rgba(82,196,26,0.1);
          }

          &.showNegative{
            color: #FF4D4F;
            background-color: rgba(255,77,79,0.1);
          }
          &.showTrend{
            border-radius: 9px;
            padding: 2px 5px 1px 15px;
            font-size: 9px;
            margin-top: -7px;
            font-weight: 500;

            .iconb-dd-down-fill{
              font-size: 7px;
              display: inline-block;
              margin-right: 3px;
              position: absolute;
              top: 5px;
              left: 5px;
              height: 6px;

              &.showUp{
                color: #52C41A;
              }
              &.showDown{
                color: #FF4D4F;
                transform: rotate(180deg);
              }
            }
          }

          .iconb-clock{
            font-size: 12px;
            position: relative;
            float: left;
            height: 13px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

ul.attachments {
  list-style-type: none;
  margin-top: 3px;
  padding-left: 0;
  position: relative;
  display: inline-block;
  width: 100%;

  a {
    color: #4a4a4a;
    text-decoration: none;
  }

  .attachment {
    display: inline-block;
    width: 100%;
    font-weight: 700;
    font-size: 11px;
    position: relative;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 4px;
    padding-left: 35px;

    .size {
      color: #999;
      font-size: 11px;
      margin-left: 80px;
    }

    .wrapped {
      display: block;
      word-wrap: break-word;
    }

    .removeDocument {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 0;
      margin-right: 5px;
      padding: 2px 4px;
      border-radius: 2px;

      &:hover {
        background-color: rgba(0, 0, 0, .1)
      }

      &:active {
        background-color: rgba(0, 0, 0, .2);
      }
    }

    &:hover {
      background-color: #fff;

      .removeDocument {
        display: block;
        background-color: transparent;
      }
    }

    .file_description {
      .size {
        margin-bottom: 0;
      }

      .filename {
        margin-right: 25px;
        margin-top: 0;

        a {
          color: #4a4a4a;
          font-size: 11px;
          font-weight: 700;
          text-decoration: none;
          line-height: 16px;
        }

        span {
          color: #4a4a4a;
          font-size: 11px;
          font-weight: 700;
          text-decoration: none;
          line-height: 16px;
        }


      }

    }

    .thumbnail {
      background-color: #f2f2f2;
      border: 1px solid #d8d8d8;
      border-radius: 2px;
      float: left;
      padding: 8px;
      margin-right: 10px;
      margin-bottom: 0;
      height: auto;
      width: 70px;
      max-width: 100%;
      cursor: pointer;

      .default-attachment{
        max-width: 35px;
        width: auto;
      }

      .thumbnail_inner {
        background-color: #f2f2f2;
        display: block;
      }

      abbr {
        font-size: 11px;
        font-weight: 700;
        line-height: 37px;
        text-align: center;
        text-transform: uppercase;
        display: block;
      }
    }
  }
}

.authorBioWrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: none;

  &.openAuthorBio {
    display: block;
  }
  .backdropClose {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .authorContent {
    width: 100%;
    height: calc(100%);
    position: relative;
    background-color: transparent;
    padding: 25px;
    z-index: 2;
    border-radius: 6px;
    box-shadow: 0 5px 34px 0 rgba(0, 0, 0, 0.09);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      max-width: 100%;
      height: auto;
      max-height: calc(100vh - 50px);
    }
  }
  .closeFun {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    z-index: 3;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: 46px;
  }

}

.new-error-meg{
  color: #df0000;
}

.story-creation-reason-wrapper{
  width: 100%;
  position: relative;
  display: inline-block;

  &.forBulk_duplicate{
    width: 350px;
    margin-left: 8px;
    margin-top: 5px;
    &.full-width {
      width: 100%;
      margin: 0;
    }
    .selected-reason{
      margin-bottom: 0;
      border-radius: 3px;
      font-size: 12px;
      padding: 8px 12px;

      em.iconb-dd-down{
        top: 7px;
      }
    }
    .selected-reason-list-wrapper{
      top: 34px;
    }
    .otherReason-input{
      height: 120px;
      font-size: 12px;
      padding: 12px;
      border-radius: 3px;
      margin:8px 0 0;
    }
  }

  .selected-reason{
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 13px;
    color: #3C3E49;
    padding: 12px;
    margin-bottom: 30px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.1);
    background-color: #ffffff;
    cursor: pointer;

    em.iconb-dd-down{
      height: 10px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 13px;
      font-size: 10px;
      margin-top: 4px;
      transition: all .2s ease-in-out;

      &.makeDown{
        transform: rotate(180deg);
      }
    }
  }
  .selected-reason-list-wrapper{
    width: 100%;
    position: absolute;
    top: 42px;
    left: 0;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.05);
    background-color: #ffffff;
    z-index: 1;
    &.scrollable {
      max-height: 138px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 2px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity: 0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity: 0.2;
      }
    }

    .select-reason{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 13px;
      color: #3C3E49;
      padding: 9px 12px 6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:last-of-type{
        border-bottom: none;
      }

      &:hover{
        color: #6C00EA;
      }
    }
  }
  .otherReason-input{
    width: 100%;
    height: 140px;
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #262626;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    overflow: auto;
    resize: none;
    margin-bottom: 20px;

    &:focus, &:active{
      outline: none;
    }
  }
}


.commonPopUp{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index:124;
  &.shiftUp{
    z-index:1222;
  }
  &.makeLayerUp{
    z-index:132;
  }
  &.commonSecondary {
    display: flex;
    align-items: center;
    justify-content: center;
    .commonPopUp-wrapper {
      max-width: 540px;
      border-radius: 3px;
      filter: drop-shadow(0px 10px 80px rgba(0, 0, 0, 0.05));
      transform: none;
      position: relative;
      top: inherit;
      left: inherit;
      .commonPopUp-title {
        font-size: 24px;
        line-height: 28px;
        padding: 0;
        margin-bottom: 30px;
      }
      .commonPopUp-body {
        padding-bottom: 15px;
        &.minHeight {
          min-height: 220px;
        }
        &.spill-over-content{
          min-height: auto;
          padding-bottom: 30px;
        }
        p {
          font-size: 13px;
          line-height: 20px;
          color: #000;
          margin-bottom: 8px;
          strong {
            font-weight: 500;
          }
          .red-text {
            color: #FF1744;
          }
        }
      }
      .commonPopUp-actionBtn {
        display: flex;
        justify-content: flex-end;
        > * {
          width: auto;
          margin-right: 16px;
          padding: 8px 20px;
          line-height: 21px;
          border-radius: 3px;
          font-weight: 500;
          font-size: 13px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.selectedStoriesList-wrapper{
  width: 100%;
  max-height: 320px;
  position: relative;
  display: inline-block;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    border-radius:2px;
  }
  &::-webkit-scrollbar-track {
    background: #F8F9FA;
  }
  &::-webkit-scrollbar-thumb {
    background: #b4b2b2;
    opacity:0.2;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b4b2b2;
    opacity:0.2;
  }
}

.selectedStoriesList{
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 15px 10px;
  border-bottom: 1px solid rgba(0,0,0,.1);

  &:last-of-type{
    border-bottom: none;
  }

  .selectedStoriesList-left{
    width: calc(100% - 56px);
    position: relative;
    display: inline-block;
    vertical-align: top;

    .story-typeIcon{
      width: 32px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      line-height: 32px;

      em{
        font-size: 18px;
        vertical-align: middle;
        color: #9B9FB1;

        &.featureIcon{
          //color: #FFC000;
        }
        &.choreIcon{
          //color: #6C00EA;
        }
        &.bugIcon{
          //color: #C20B0B;
        }
        &.releaseIcon{
          //color: #47d764;
        }
        &.sideEffectIcon{
          //color: #4db9c6;
        }
      }
    }
    .story-NameBox{
      width: calc(100% - 32px);
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding: 5px 10px;

      .selected-storyName{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        word-break: break-all;
      }
      .selected-storyEpicName{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        color: #6C00EA;
        margin-top: 7px;
        word-break: break-all;
      }
    }
  }
  .selectedStoriesList-right{
    width: 56px;
    position: relative;
    display: inline-block;
    vertical-align: top;

    .deleteSelection-check{
      width: 40px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 5px 0;
      margin-left: 14px;
      border-left: 1px dashed rgba(0, 0, 0, 0.1);
      text-align: center;

      .customCheckFor-deleteSelection{
        width: 22px;
        height: 20px;
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;

        .check-toggleDeleteSelection{
          width: 20px;
          height: 20px;
          position: relative;
          display: inline-block;
          border: 1px solid rgba(0,0,0,.15);
          background-color: #fff;
          border-radius: 2px;
          cursor: pointer;

          &:before{
            content: '';
            width:11px;
            height: 7px;
            position: absolute;
            top: 4px;
            left: 4px;
            border-left: 2px solid rgba(0,0,0,.015);
            border-bottom: 2px solid rgba(0,0,0,.015);
            display: block;
            transform: rotate(-47deg);
          }
        }

        input[type='checkbox'].delete-selected-checkbox:checked + .check-toggleDeleteSelection{
          border-color: #6C00EA;
          background-color: #6C00EA;

          &:before{
            content: '';
            width: 11px;
            height: 7px;
            position: absolute;
            top: 4px;
            left: 4px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            display: block;
            transform: rotate(-47deg);
          }
        }
      }
    }
  }
}

.commonPopUp-backdrop{
  width:100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:11;
  background-color: rgba(0,0,0,.65);
}
.commonPopUp-wrapper{
  width: 100%;
  max-width: 576px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  border-radius: 10px;
  //border-top: 7px solid #6C00EA;
  z-index:12;
  padding: 30px;

  .closeCommonPopup{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    cursor: pointer;
    color:#83889E;
  }

  &.toListStories{
    max-width: 654px;
  }

  &.dev_checklist{
    max-width: 920px;
    height: 610px;

    .certificationDoc-container{
      border: 1px solid rgba(0,0,0,.1);
      border-radius: 4px;
    }

    .certificationConfirmation-wrapper{
      height: calc(100% - 153px);
      .storyStartConfirmation-title {
        padding: 0 0 0;
        font-size: 16px;
      }
      .confirmOption-list, 
      .storyStartConfirmation-text {
        font-size: 14px;
      }
    }

    .commonPopUp-actionBtn{
      .commonPopUp-submit-s, 
      .commonPopUp-cancel, 
      .commonPopUp-submit, 
      .commonPopUp-cancel-s{
        max-width: 140px;
      }
    }
  }

  .commonPopUp-close {
    display: inline-flex;
    position: absolute;
    top: 37px;
    right: 30px;
    color: #83889E;
    cursor: pointer;
    font-size: 12px;
    z-index: 1;
  }

  .commonPopUp-title, .commonPopUp-title2{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 7px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.39;
    text-align: left;
    color: #000;
    //border-bottom: solid 1px rgba(0,0,0,.1);
  }

  .commonPopUp-title2{
    font-size: 24px;
  }

  .commonPopUp-img{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 6px 0;
    text-align: center;

    em{
      display: inline-block;
      font-size: 48px;
      color:#6C00EA;
    }
  }
  .commonPopUp-text2{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 6px 0 5px;
    font-size: 13px;
    line-height: normal;
    font-weight: 500;
    text-align: center;
    color: #3C3E49;
  }
  .list-title{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 6px 0 10px;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
  .commonPopUp-text3{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 2px 0 5px;
    font-size: 13px;
    line-height: normal;
    font-weight: 400;
    text-align: center;
    color: #83889E;
  }
  .commonPopUp-text4{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 2px 0 10px;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    .epic_title{
      position: relative;
      display: inline-block;
      font-weight: 500;
      &::first-letter{
        text-transform: capitalize;
      }
    }
  }

  .commonPopUp-featurecontent{
    width: 100%;
    position: relative;
    display: inline-block;
    max-height: 440px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius:4px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
  }

  .featuresList{
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 13px;
    color: #83889E;
    padding: 10px 0;
    border-bottom: 1px solid #DFE0E6;
  }

  .storiesList-wrapper{
    width: 100%;
    max-height: 240px;
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      border-radius:2px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
  }
  .storiesList{
    width: 100%;
    position: relative;
    display: block;
    padding: 14px 120px 14px 3px;
    font-size: 13px;
    line-height: normal;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover{
      color: #9F4DFF;
    }
    &:last-of-type{
      border-bottom: none;
    }
    .forPhase{
      position: absolute;
      right: 7px;
      z-index: 1;
      background: #F4EAFF;
      border-radius: 3px;
      padding: 3px 7px;
      color: #9F4DFF;
      font-size: 11px;
      margin-top: -4px;
    }
  }
  .commonPopUp-form-group {
    margin: 22px 0;
    .form-control {
      width: 100%;
      height: 42px;
      position: relative;
      display: inline-block;
      cursor: text;
      outline: none;
      padding: 0 10px;
      font-size: 13px;
      font-weight: normal;
      border-radius: 4px;
      margin-bottom: 0;
      border: solid 1px #d0d2da;
      background-color: #ffffff;
      color: #000;
      outline: none;
      box-shadow: none;
      &:focus {
        border-color: #d0d2da;
        box-shadow: none;
      }
    }
  }
  .commonPopUp-text{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 6px 0 20px;
    font-size: 14px;
    line-height: 1.67;
    text-align: left;
    color: #262626;
  }
  .commonPopUp-actionBtn{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: right;
    padding: 0;
    line-height:normal;

    .commonPopUp-cancel{
      width: 48%;
      height: 38px;
      position: relative;
      float: right;
      color: #6C00EA;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      background-color: transparent;
      border-radius: 2px;
      border: solid 1px #6C00EA;
      cursor: pointer;
      margin-right: 15px;

      &:focus, &:active{
        outline: none;
      }
    }

    .commonPopUp-reload{
      width: 120px;
      height: 38px;
      position: relative;
      float: left;
      margin-left: calc(50% - 60px);
      color: #0091EA;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      border-radius: 2px;
      background-color: #fff;
      cursor: pointer;
      margin-top: 20px;
      border: 1px solid #0091EA;
      transition: all .2s ease-in-out;

      &:focus, &:active{
        outline: none;
      }
      &:hover{
        color: #fff;
        background-color: #0091EA;
      }
    }

    .commonPopUp-submit{
      width: 48%;
      height: 38px;
      position: relative;
      float: right;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      border-radius: 2px;
      background-color: #6C00EA;
      border: none;
      cursor: pointer;

      &:disabled{
        opacity: .5;
        cursor: not-allowed;
      }

      &:focus, &:active{
        outline: none;
      }
    }
    .commonPopUp-cancel-s{
      width: 48%;
      height: 38px;
      position: relative;
      float: right;
      color: #6C00EA;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      background-color: transparent;
      border-radius: 2px;
      border: solid 1px #6C00EA;
      cursor: pointer;
      margin-right: 15px;
      &.is-black {
        border-color: #000000;
        color: #000000;
      }

      &:focus, &:active{
        outline: none;
      }
      &.no-margin{
        margin-right: 0;
      }
    }

    .commonPopUp-submit-s{
      width: 48%;
      height: 38px;
      position: relative;
      float: right;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      border-radius: 2px;
      background-color: #6C00EA;
      border: none;
      cursor: pointer;

      &:disabled,&.disabled{
        opacity: .5;
        cursor: not-allowed;
      }
      &:focus, &:active{
        outline: none;
      }
    }
  }
}
.custom-toaster{
  position: fixed;
  width: 380px;
  height: auto;
  top: 22px;
  right: -410px;
  z-index: 1113;
  transition: all .7s ease-in-out;
  transition-delay: .3ms;

  &.visiblecustom-toaster{
    right: 5px;
    transition: all .7s ease-in-out;
    transition-delay: .3ms;
  }

  .showToastDisplay{
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    transition: all .7s ease-in-out;
    transition-delay: .3ms;
    right: -510px;

    &.showCustomToaster{
      transition: all .7s ease-in-out;
      transition-delay: .3ms;
      right: 0;
    }
  }
}

.invalid-feedback{
  font-size: 12px;
  padding-top: 5px;
}


.cal-SelBox{
  .input-group input{
    padding-left: 12px !important;
    text-align: left !important;
    padding-top: 12px;
  }
}

.popover-title {
  padding: 2px 5px !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 5px !important;
}

/*===============popover custom style start================*/
.popover {
   border-radius: 0 !important;
}
.popover > .arrow {
  border-width: 6px !important;
}
.popover > .arrow:after {
  border-width: 6px !important;
}
.popover.bottom > .arrow:after {
  margin-left: -6px !important;
  top: -6px !important;
}
.bs-popover-top .arrow, .bs-popover-bottom .arrow {
  margin-left: -6px !important;
  top: -12px !important;
}

/*===============popover custom style end================*/

.auth-screen {
  #main {
    height: 100%;
  }
}

.authMainContainer {
  width: 100%;
  height: calc(100vh - 1px);
  position: relative;
  background: #ffffff;
  display: inline-block;

  .leftSection{
    width: 60%;
    height: 100%;
    position: relative;
    display: inline-block;
    padding-top: 35px;
    //background: #6C00EA;
    vertical-align: top;
    text-align: center;
    //background-image: url('../images/loginBG.jpg');
    background-size: cover;

    img{
      //max-width: 170px;
      //height: auto;
    }
  }
  .rightSection,
  .rightSection-register{
    width: 40%;
    height: 100%;
    position: relative;
    display: inline-block;
    background: #fff;
    vertical-align: top;
    padding:0 4%;
    overflow: auto;

    .logo{
      width: 100%;
      position: relative;
      display: inline-block;
      padding-top: 35px;

      img{
        width:auto;
        max-height: 70px;
      }
    }

    .sign-box-center {
      background: #fff;
      width: 100%;
      position: relative;
      display: inline-block;
      padding-top: 70px;

      .auth-title{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 54px;
        font-weight: 700;
        color: #262626;
      }
      .auth-title2{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 32px;
        font-weight: 700;
        color: #262626;
      }
      .auth-title3{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 24px;
        font-weight: 500;
        color: #262626;
      }

      .authOption-wrapper{
        width: 100%;
        position: relative;
        display: inline-block;
        margin: 20px 0 25px;
      }
      .select-authOption{
        width: 135px;
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        background-color: transparent;
        border: 2px solid #D9D9D9;
        border-radius: 6px;
        margin-right: 30px;

        &:last-of-type{
          margin-right: 0;
        }

        &.selected{
          color: #6C00EA;
          border: 2px solid #6C00EA;
        }

        &:focus, &:active{
          outline: none;
        }
      }

      .authForm{
        width: 100%;
        position: relative;
        display: inline-block;
      }
      .reveal-conceal{
        position: absolute;
        right: 10px;
        bottom: 12px;
        width: 20px;
        height: 17px;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;

        em{
          font-size: 14px;
        }

        &.showText{
          &:before{
            content: '';
            width: 1px;
            height: 20px;
            position: absolute;
            top: -2px;
            left: 9px;
            background-color: #000000;
            display: block;
            transform: rotate(45deg);
            z-index: 1;
          }
        }
      }
      .reveal-concealChangePass{
        position: absolute;
        right: 10px;
        top: 42px;
        width: 20px;
        height: 17px;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;

        em{
          font-size: 14px;
        }

        &.showText{
          &:before{
            content: '';
            width: 1px;
            height: 20px;
            position: absolute;
            top: -2px;
            left: 9px;
            background-color: #000000;
            display: block;
            transform: rotate(45deg);
            z-index: 1;
          }
        }
      }
      .authForm-block{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-top: 25px;
      }
      .faceLogin-step1{
        width: 100%;
        position: relative;
        display: inline-block;
      }
      .inputLabel{
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom:10px;
        font-weight: 400;
        font-size: 14px;
        color: #8C8C8C;
      }

      .authInput{
        width: 100%;
        height: 42px;
        padding: 0 15px;
        position: relative;
        display: inline-block;
        font-size: 14px;
        color: #262626;
        font-weight: 400;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: #ffffff;

        &:focus, &:active{
          outline: none;
        }
      }
      input[type="password"].authInput{
        padding-right: 45px;
      }

      .resend{
        margin-left: 28px;
        em{
          position: relative;
          display: inline-block;
          margin-left: 5px;
          height: 22px;
          vertical-align: middle;
          font-size: 18px;
          .elementHover{
            font-size: 13px;
          }
          &:hover{
            .elementHover{
              visibility: visible;
              opacity: 1;
              bottom: -47px;
              transform: translateX(-50%);
            }
          }
        }
      }

      .submitAuth{
        width: calc(50% - 14px);
        height: 42px;
        position: relative;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        border: none;
        background-color: #6C00EA;
        border-radius: 4px;
        margin-top: 35px;
        max-width: 210px;

        &:focus, &:active{
          outline: none;
        }

        &:disabled{
          opacity: .5;
          cursor: not-allowed;
        }
      }

      .fgtBlock{
        width: 100%;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      .fgtBlock2{
        width: 100%;
        position: relative;
        display: inline-block;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #8C8C8C;
        margin-top: 16px;
      }
      .notifyText{
        width: 75%;
        position: relative;
        display: inline-block;
        margin-top: 30px;
        font-size: 13px;
        font-weight: 400;
        color: #8C8C8C;
        line-height: 22px;
      }
      .notifyText2{
        width: 100%;
        position: relative;
        display: inline-block;
        margin: 30px 0;
        font-size: 14px;
        font-weight: 400;
        color: #8C8C8C;
        line-height: 22px;
      }
      .forgotPassword-link {
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #6C00EA;
        margin-top: 3px;
        cursor: pointer;

        &:focus, &:active{
          outline: none;
        }
      }
      .btn.btn-sign {
        margin: 30px 0 0 !important;
        font-size: 18px !important;
        outline: none;
        &.btnForget {
          margin-top: 10px !important;
        }
        &.signup {
          margin: 10px 0 0 !important;
        }
      }
    }
  }
  .rightSection-register{
    padding-bottom: 40px;

    .sign-box-center {
      padding-top: 30px;
    }
  }
}

.faceAuthContainer{
  width: 100%;
  position: relative;
  display: inline-block;

  .topLogo{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(0,0,0,.1);

    img{
      max-width: 150px;
      height: auto;
    }
  }

  .faceAuth-progress{
    width: 100%;
    position: relative;
    display: inline-block;
    margin: 25px 0;
    text-align: center;

    .goBackToNormalLogin-button{
      position: absolute;
      top: -5px;
      left: 5%;
      display: inline-block;
      padding: 9px 25px;
      border: 2px solid #6C00EA;
      cursor: pointer;
      border-radius: 4px;
      z-index: 1;
      transition: all .3s ease-in-out;

      em{
        display: block;
        transform: rotate(-135deg);
        font-size: 14px;
        color: #6C00EA;
        transform-origin: 8px 8px;
        transition: all .3s ease-in-out;
      }

      &:hover{
        em{
          transition: all .3s ease-in-out;
          transform: translateX(-5px) rotate(-135deg);
        }
      }
    }

    .progress-wrapper{
      width: 100%;
      max-width: 740px;
      position: relative;
      display: inline-block;

      .progress-step{
        width: 33.33%;
        position: relative;
        display: inline-block;
        opacity: .5;

        &.active{
          opacity: 1;

          &:before{
            opacity: 1;
          }

        }

        &:before{
          content: '';
          width: calc(100% - 21px);
          height: 1px;
          display: block;
          position: absolute;
          bottom: 21px;
          right: 50%;
          background-color: #6C00EA;
          z-index: -1;
          opacity: .5;
        }

        &:first-of-type{
          &:before{
            display: none;
          }
        }

        .progress-infoTitle{
          width: 100%;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-bottom: 7px;
          color: #6C00EA;
          font-size: 14px;
          font-weight: 400;
        }
        .progress-Status{
          width: 42px;
          height: 42px;
          display: inline-block;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          background-color: #6C00EA;
          line-height: 42px;
          border-radius: 50%;
        }
      }
    }
  }

  .faceAuth-wrapper{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: center;

    .faceAuth-block{
      width: 100%;
      height: 463px;
      max-width: 620px;
      position: relative;
      display: inline-block;
    }

    .faceCapture{
      width: 100%;
      height: 100%;
      position: relative;
      display: inline-block;
    }

    .counterFace{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 244px;
      font-weight:500;
      text-align: center;
      color: #ffffff;
      z-index: 1;
    }
    .loader-verification{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 54px;
      font-weight:500;
      text-align: center;
      color: #ffffff;
      z-index: 2;

      .loading:after {
        content: ' .';
        width: 10px;
        height: 10px;
        animation: dots 1s steps(5, end) infinite;}

      @keyframes dots {
        0%, 20% {
          color: rgba(0,0,0,0);
          text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
        40% {
          color: white;
          text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
        60% {
          text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0,0,0,0);}
        80%, 100% {
          text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;}}
    }

    .faceAuth-alternateOptions{
      width: 100%;
      height: 463px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: 0;
      border: 1px solid #C0C3CE;
      border-radius: 4px;
      z-index:2;

      .otherOptionTitle{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        margin-bottom: 35px;
        text-align: center;
      }

      .forPermission,
      .forloginWithPassword{
        width: 220px;
        height: 50px;
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 400;
        color: #6C00EA;
        background-color: transparent;
        border: 2px solid #6C00EA;
        border-radius: 6px;
        margin: 8px 0;
        transition: all .3s ease-in-out;
        cursor: pointer;

        &:hover{
          color: #ffffff;
          background-color: #6C00EA;
          transition: all .3s ease-in-out;
        }

        &:focus, &:active{
          outline: none;
        }
      }
    }
  }
}


.wrong-password{
  font-size: 11px;
  position: relative;
  display: inline-block;
  width: 100%;
  color: #ff0000;
}
.changePasswordButton-wrapper{
  width: 100%;
  position: relative;
  display: inline-block;
  margin: 10px 0;

  .changePasswordButton{
    width:auto;
    position: relative;
    display: inline-block;
    background-color: #6C00EA;
    color: #fff;
    border: 1px solid #6C00EA;
    outline: none;
    height: 40px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-right: 30px;
    border-radius: 4px;
    padding: 0 30px;

    &:nth-child(2){
      margin-right: 0;
    }

    &:focus, &:active{
      outline: none;
      box-shadow: none;
    }
    &:disabled{
      opacity: .65;
      cursor: not-allowed;
    }

    &.backToLogin{
      background-color: #ffffff;
      color: #6C00EA;
    }
  }
}

.forBreadCrumb{
  width: 100%;
  position: relative;
  display: inline-block;
  padding:10px 40px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(0,0,0,.05);

  .backLink{
    position: relative;
    display: inline-block;
    padding: 0 10px 0 0;
    cursor: pointer;
    vertical-align: middle;

    &:before{
      content: '';
      width: 1px;
      height: 14px;
      background-color: #cccccc;
      display: inline-block;
      position: absolute;
      top: 3px;
      right: 0;
      transform: rotate(7deg);
    }

    em{
      font-size: 16px;
      color: #6C00EA;
    }
  }
  .activePage{
    position: relative;
    display: inline-block;
    color: #555555;
    font-size: 13px;
    vertical-align: middle;
    margin-left: 8px;
  }
  .innerText{
    position: relative;
    display: inline-block;
    color: #6C00EA;
    font-size: 13px;
    vertical-align: top;
    margin-left: 8px;
  }
}


.storyTypeBlock {
  color: #ffffff;
  font-weight: normal;
  padding: 0;
  white-space: normal;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .ss-type {
    display: inline-block;
    position: relative;
    padding: 0 10px;
    //border-right: 1px solid rgba(255,255,255,.3);

    &:before{
      content: '';
      width: 1px;
      height: 18px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color: rgba(255,255,255,.3);
    }

    &:last-of-type {
      border-right: none;
      padding-right: 0;

      &:before{
        display: none;
      }
    }
  }
  .taskIcons,
  .bugIcons,
  .sideeffectIcons,
  .choreIcons,
  .releaseIcons,
  .rewrkIcons{
    margin-right: 3px;
    vertical-align: middle;
    font-size: 14px;
    color: #83889E;
  }
  .typeCounter {
    display: inline-block;
    position: relative;
    font-size: 15px;
    font-weight: 300;
  }
}

.projectCard-infoWrapper, .projectCard-name{

  .storyTypeBlock{
    float: left;
    padding: 0 0 14px;
    color: #757575;
    width: 100%;
    border-bottom: 1px solid #ECEDF0;
    display: flex;

    &:last-child{
      border-bottom: 0;
    }

    .ss-type {
      padding: 0 10px;
      float: left;
      display: inline-flex;

      &:first-child{
        padding-left: 0;
      }

      &:before{
        display: none;
      }
      .stateSelection-icon{
        width: 14px;
        height: 14px;
        position: relative;
        float: left;
        margin-right: 8px;
        border-radius: 50%;
        margin-top: 2px;
      }
    }
    .taskIcons,
    .bugIcons,
    .sideeffectIcons,
    .choreIcons,
    .releaseIcons,
    .reworkIcons{
      margin-right: 4px;
      font-size: 14px;
      float: left;
      margin-top: 2px;
      height: 16px;
    }
    .taskIcons{
      font-size: 14px;
      margin-top: 2px;
    }
    .choreIcons{
      font-size: 16px;
      margin-top: 1px;
    }
    .typeCounter {
      font-size: 12px;
      font-weight: 400;
      float: left;
      margin-top: 3px;
      line-height: 14px;
    }
  }
}

.count-box{
  .storyTypeBlock{
    .ss-type {
      padding: 0 13px;
      &:first-of-type{
        padding-left: 0;
      }
      &:before{
        content: '';
        background-color: rgba(0,0,0,.15);
      }
    }
    .taskIcons,
    .bugIcons,
    .sideeffectIcons,
    .choreIcons,
    .releaseIcons,
    .reworkIcons{
      margin-right: 6px;
      font-size: 12px;
    }
    .choreIcons {
      //color: #6C00EA;
    }

    .typeCounter {
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: 300;
      color: #757575;
    }
  }
}

.show-more-stories{
  position: relative;
  background-color: #FFF0F6;
  color: #F759AB;
  width: 100%;
  height: 34px;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  padding-top: 9px;
  cursor: pointer;

  em{
    font-size: 8px;
    display: inline-block;
    height: 10px;
    vertical-align: middle;
    margin-left: 3px;
  }
}
.ifNoActivity{
  width: 100%;
  height: 130px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
}
.hs_story {
  padding: 0;
  font-size: 12px;

  .hs_storyListing{
    width: 100%;
    position: relative;
    display: inline-block;
    border-bottom: 1px solid #e9e9f0;
    padding: 10px 0;

    &:last-of-type{
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .hs_story-header {
    width: 100%;
    display: inline-block;
    //background: #fff;
    border-radius: 4px;
    padding: 7px;

    .hs_userInitials{
      width: 27px;
      height: 27px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      color: #FA541C;
      background: #FFF2E8;
      border: 1px solid #FFBB96;
      border-radius: 50%;
      margin-right: 9px;
      line-height: normal;
      vertical-align: top;
      margin-top: -4px;
    }

    .hs_storyIconWrapper {
      width: 22px;
      position: relative;
      display: inline-block;
      margin-right: 4px;
      vertical-align: middle;

      .hs_story-expandIcon {
        width: 18px;
        height: 18px;
        background-color: #ffffff;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        font-size: 9px;
        color: #6C00EA;
        border: solid 1px #e9e9f0;
        margin-right: 3px;
        transform: rotate(-90deg);
        transform-origin: center;
        line-height: 20px;
        float: left;
        cursor: pointer;

        &.expanded{
          transform: rotate(0deg);
        }
      }
    }

    .hs_storyTitleWrapper {
      width: calc(100% - 70px);
      display: inline-block;
      position: relative;
      font-size: 13px;
      color: #000;
      line-height: 19px;
      font-weight: 400;
      vertical-align: middle;

      .hs_requestor{
        position: relative;
        display: inline-block;
        width: 100%;
      }

      .hs_requesterInfo{
        position: relative;
        display: inline-block;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        margin-right: 5px;
      }
      .hs_eventType{
        position: relative;
        display: inline-block;
        font-weight: 400;
        margin-right: 5px;
      }
      .commitURl{
        position: relative;
        display: inline-block;
        color: #F759AB;
        font-weight: 400;
        word-break: break-all;
      }
      .ifCommitId{
        position: relative;
        display: inline-block;
        padding: 1px 7px;
        font-size: 11px;
        color: #83889E;
        font-weight: 400;
        border: solid 1px #dddddd;
        background-color: #f8f8f8;
        margin: 0 5px;
        border-radius: 4px;
      }
      .hs_attachments{
        width: 100%;
        position: relative;
        display: inline-block;
        margin: 10px 0;
      }

      .uploading-listing{
        width: 100%;
        position: relative;
        display: inline-block;

        .uploading-listing-thumbnail{
          width: 50px;
          height: 40px;
          position: relative;
          display: inline-flex;
          vertical-align: middle;
          text-align: center;
          justify-content: center;
          align-items: center;
          background-color: #ababab;

          img{
            max-width: 100%;
            height: auto;
            max-height: 40px;
          }
          .default-attachment{
            max-width: 22px;
            height: auto;
          }
        }
        //.hs_requestor{
        //  overflow: hidden;
        //  text-overflow: ellipsis;
        //  white-space: nowrap;
        //}
        .uploading-file-description{
          width: calc(100% - 52px);
          position: relative;
          display: inline-block;
          padding:0 7px 0;
          vertical-align: middle;

          .docuName{
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
            color: #555;
            word-wrap: break-word;
            margin-bottom: 4px;
            word-break: break-all;
          }
          .docuSize{
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            color: #555;
          }
        }
      }
    }
  }
  .hs_date{
    display: block;
    margin: 2px 0 0 50px;
    float: left;
    overflow-wrap: break-word;
    font-weight: 400;
    color: #83889E;
    font-size: 12px;
  }
  .hs_date_projectDash{
    display: block;
    margin: 2px 0 0 10px;
    float: left;
    overflow-wrap: break-word;
    font-weight: 400;
    color: #83889E;
    font-size: 12px;
  }

  .sp-em{
    text-decoration: underline; margin-left: 2px;
  }
  .hs_historyInfo{
    width: calc(100% - 42px);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: 36px;
    margin-top: 4px;
    margin-right: 10px;
    margin-bottom: 10px;

    .hs_eventTypeList{
      width: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 8px;

      &:last-of-type{
        margin-bottom: 0;
      }

      .hs_eventTypeName{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 13px;
        word-break: break-word;

        strong{
          font-weight: 500;
        }
      }
      .hs_attachments{
        position: relative;
        display: inline-block;
        width: 100%;
        margin: 7px 0;
      }
      .uploading-listing{
        width: 100%;
        position: relative;
        display: inline-block;

        .uploading-listing-thumbnail{
          width: 50px;
          height: 40px;
          position: relative;
          display: inline-flex;
          vertical-align: middle;
          text-align: center;
          justify-content: center;
          align-items: center;
          background-color: #ababab;

          img{
            max-width: 100%;
            height: auto;
            max-height: 40px;
          }
          .default-attachment{
            max-width: 22px;
            height: auto;
          }
        }

        .uploading-file-description{
          width: calc(100% - 52px);
          position: relative;
          display: inline-block;
          padding:0 7px 0;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .docuName{
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
            color: #555;
            word-wrap: break-word;
            margin-bottom: 4px;
          }
          .docuSize{
            width: 100%;
            position: relative;
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            color: #555;
          }
        }
      }
      img{
        max-width: 100%;
      }
    }
  }
}

.highcharts-graph{
  stroke: #6C00EA;
  stroke-width: 2px;
}
.highcharts-point{
  fill: #6C00EA;
}
.highcharts-tooltip-box{
  stroke: #e9e9f0;
  stroke-width: 1px;
  fill: #ffffff;
  opacity: 1;
}
.cToolTip{
  width: 185px;
  position: relative;
  display: block;
  font-size: 12px;
  font-weight: 400;

  .cToolTip-top,
  .cToolTip-storiesCreated,
  .cToolTip-storiesAccepted,
  .cToolTip-hoursAccepted{
    width: 100%;
    position: relative;
    display: block;
    clear: both;
    font-size: 11px;
    font-weight: 500;
    color: #555555;
    padding: 3px 10px;
  }
  .cToolTip-top{
    padding: 0 0 5px;
    text-align: center;
    color: #6C00EA;
    font-weight: 500;
    border-bottom: solid 1px #e9e9f0;
    font-size: 12px;
  }
  .cToolTip-storiesCreated{
    padding-top: 7px;
  }
}

.allAttachmentsList-wrapper, .epicAttachment-wrapper{
  .slick-initialized .slick-slide {
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .storyDetailImage{
    height: 140px;
    min-width: 142px;

    .default-attachment{
      max-width: 60px;
    }

    .videoContainer{
      width: 132px;
      cursor: pointer;

      .iconb-play-circle{
        font-size: 44px;
        height: 47px;
        display: inline-block;
      }
    }
  }
  .slick-slide {
    text-align: center;
    padding: 10px;
    background-color: #ECEDF0;
    border: 1px solid #ECEDF0;
    box-sizing: border-box;
    border-radius: 3px;

    a:active, a:focus{
      outline: none;
    }
    &:focus, &:active{
      outline: none;
    }

    img{
      display: inline-block;
      max-width: 120px;
      height: auto;
      cursor: pointer;
      max-height: 120px;
    }
  }

  .slick-prev{
    left:-2px !important;
  }
  .slick-next{
    right:0 !important;
  }
  .slick-prev, .slick-next {
    height: 100%;
    width: 38px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 111;
  }
  .slick-disabled{
    display: none !important;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .slick-prev:before{
    content: "\e00d";
  }
  .slick-next:before{
    content: "\e01a";
  }
  .slick-prev:before, .slick-next:before {
    font-family: "builder-tracker";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


.finished{
  background-color: #FADB14;
}
.delivered{
  background-color: #FF7A45;
}
.accepted{
  background-color: #4CAF50;
}
.rejected{
  background-color: #FF4D4F;
}
.unscheduled{
  background-color: #BFBFBF;
}
.unstarted{
  background-color: #262626;
}
.started{
  background-color: #1890FF;
}


.actionBt-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 20px 0 0;
  text-align: center;

  .actionButtonRevert {
    width: 145px;
    height: 42px;
    position: relative;
    display: inline-block;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #6C00EA;
    text-align: center;
    color: #6C00EA;
    font-size: 16px;
    padding: 10px 0;
    margin-right: 45px;
    transition: all .3s ease-in-out;

    &:hover {
      color: #ffffff;
      background-color: #6C00EA;
    }
    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  .actionButton {
    width: auto;
    height: 42px;
    position: relative;
    display: inline-block;
    border-radius: 4px;
    background-color: #6C00EA;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 28px;
    text-decoration: none;
    cursor: pointer;
    margin-right: 15px;
    border: none;
    transition: all .4s ease-in-out;

    &.cancel-addMember {
      background-color: #fff;
      border-radius: 4px;
      border: solid 1px #6C00EA;
      color: #6C00EA;

      &:hover {
        color: #ffffff;
        background-color: #6C00EA;
        transition: all .4s ease-in-out;
      }
    }

    &:hover {
      color: #ffffff;
    }
    &:focus, &:active {
      outline: none;
      box-shadow: none;
    }
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.addNewProject-popupWrapper{

  &.addMember{
    .addNewProject-content{
      padding-top: 23px !important;
      .inputBlock {
        width: 100%;
        position: relative;
        display: inline-block;
        padding: 8px 0;
      }
    }
  }
  .addNewProject-content {
    .outerWrapper{
      padding: 0;
    }
    .genralBlock-title{
      display: none;
    }
    .genralBlock, .genralBlock2{
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;
    }
    .inputBlock {
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 16px 0;
    }
    .forProjectName {
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      margin-bottom: 5px;
    }
    .domainName {
      width: auto;
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      vertical-align: middle;
      margin-left: 7px;
    }
    .addProject-input, .addMember-input {
      width: 100%;
      max-width: 100%;
      height: 42px;
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      padding: 0 12px;
      border-radius: 4px;
      border: solid 1px #e9e9f0;
      background-color: #ffffff;

      &:focus, &:active {
        outline: none;
        box-shadow: none;
      }

      &.typeEmail{
        max-width: 360px;
      }
    }

    .cal-warpper{
      width: 100%;
      position: relative;
      display: inline-block;
    }
    .cal-btn{
      width: 40px;
      position: absolute;
      display: inline-block;
      left: calc(100% - 40px);
      top: 9px;
      text-align: center;

      .calIcon{
        position: relative;
        display: inline-block;
        cursor: pointer;
      }

      em.iconb-calendar{
        font-size: 21px;
        display: inline-block;
        height: 22px;
        vertical-align: middle;
        color: #6C00EA;
      }
    }
  }
}
.addSprintForm-block{
  .ngxmdp{
    position: relative;
    top: -30px;
  }
}
.sprintDemoDate{
  .ngxmdp{
    position: relative;
    left: -66px;
    top: -17px;
  }
}

.storyProgressStatus-popup{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index:1112;
  background-color: rgba(0,0,0,.5);
}

.storyProgressStatus-popupBackdrop{
  width:100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:11;
  background-color: rgba(0,0,0,.65);
}
.storyProgressStatus-popupWrapper {
  width: 100%;
  max-width: 464px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  border-radius: 20px;
  padding: 40px;
  z-index: 12;


  .topSection{
    width: 100%;
    position: relative;
    display: inline-block;

    .titleSection{
      max-width: calc(100% - 90px);
      position: relative;
      float: left;
      font-size: 24px;
      font-weight: 500;
      margin-right: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .storyState-icon{
      width: 14px;
      height: 14px;
      position: relative;
      float: left;
      margin-right: 8px;
      border-radius: 50%;
      margin-top: 11px;
    }
    .storyTypeIcon{
      font-size: 17px;
      color: #83889E;
      float: left;
      margin-right: 10px;
      margin-top: 7px;
    }

    em{

      &.iconb-summary{
        font-size: 14px;
        color: #83889E;
        float: left;
        margin-top: 10px;
      }
    }
    .timeInfo{
      width: 100%;
      position: relative;
      display: inline-block;
      margin-top: 12px;
      font-size: 14px;
      color: #83889E;

      em{
        font-size: 16px;
        color: #83889E;
        display: inline-block;
        height: 18px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }

  .progressSection{
    width: 100%;
    position: relative;
    display: inline-block;
    margin: 70px 0;

    .slideSection{
      width: 100%;
      position: relative;
      display: inline-block;

      .slideProgressLine{
        width: 100%;
        position: relative;
        float: left;
      }
      .slideLineDefault{
        width: 100%;
        height: 10px;
        position: relative;
        float: left;
        background-color: #ECEDF0;
        border-radius: 12px;
      }
      .slideLineprog{
        height: 10px;
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        background-color: #6C00EA;
        border-radius: 12px;
        transition: all .3s ease-in-out;

        .percentCounter{
          font-size: 24px;
          font-weight: 500;
          color: #6C00EA;
          position: absolute;
          display: inline-block;
          left: calc(100% - 21px);
          top: 43px;
        }

        &:before{
          content: "";
          width: 40px;
          height: 40px;
          position: absolute;
          top: -16px;
          left: calc(100% - 20px);
          background-color: #ffffff;
          border-radius: 50%;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
          z-index: 1;
        }
        &:after{
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: -2px;
          left: calc(100% - 6px);
          background-color: #6C00EA;
          border-radius: 50%;
          z-index: 2;
        }
      }
      .slideInterval-wrapper{
        width: 100%;
        position: relative;
        float: left;
      }
      .slideInterval{
        width: 10%;
        height: 20px;
        position: absolute;
        top: -15px;
        display: block;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:after{
          content: '';
          width: 2px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          background-color: transparent;
        }

        &.covered{

          &:after{
            content: '';
            width: 2px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            background-color: transparent;
          }
        }


        &:nth-of-type(1){
          left: 0;
          width: 5%;

          &:before{
            content: '0%';
            font-size: 14px;
            font-weight: 500;
            color: #676B7E;
            position: absolute;
            display: inline-block;
            left: 0;
            top: 30px;
          }
        }
        &:nth-of-type(2){
          left: calc(5%);
        }
        &:nth-of-type(3){
          left: calc(15%);
        }
        &:nth-of-type(4){
          left: calc(25%);
        }
        &:nth-of-type(5){
          left: calc(35%);
        }
        &:nth-of-type(6){
          left: calc(45%);
        }
        &:nth-of-type(7){
          left: calc(55%);
        }
        &:nth-of-type(8){
          left: calc(65%);
        }
        &:nth-of-type(9){
          left: calc(75%);
        }
        &:nth-of-type(10){
          left: calc(85%);
        }
        &:nth-of-type(11){
          left: calc(95%);
          width: 5%;

          &:before{
            content: '100%';
            font-size: 14px;
            font-weight: 500;
            color: #676B7E;
            position: absolute;
            display: inline-block;
            left: -17px;
            top: 30px;
          }
        }
      }
    }
  }

  .statusAction{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: right;
    padding: 0;
    line-height:normal;
    margin-top: 34px;

    .statusButton{
      width: 95px;
      height: 40px;
      position: relative;
      float: right;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 10px 10px;
      border-radius: 2px;
      background-color: #6C00EA;
      border: 1px solid #6C00EA;
      cursor: pointer;

      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
      &:disabled{
        cursor: not-allowed;
        opacity:0.5;
      }
    }
  }
}

.addNewEpic-popup{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0,0,0,.5);
}

.addNewEpic-backdrop{
  width:100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:11;
  background-color: rgba(0,0,0,.65);
}
.addNewEpic-popupWrapper {
  width: 100%;
  max-width: 640px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  border-radius: 20px;
  padding: 40px;
  z-index: 12;

  .topSection{
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
  }

  .topTitle{
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 10px;
  }

  .addEpic-label{
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: #000000;
    margin-bottom: 13px;
  }
  .addEpic-inputTitle{
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #000000;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
    background-color: transparent;
    &:active, &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  .addEpic-description{
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
  }
  .addEpic-inputDescription{
    width: 100%;
    height: 120px;
    position: relative;
    display: inline-block;
    font-size: 14px;
    padding:13px;
    color: #000000;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.1);
    background-color: transparent;
    resize: none;
    overflow: hidden;
    overflow-y: auto;
    word-break: break-word;
    &:active, &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  .actionButtons{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: right;
    padding: 0;
    line-height:normal;
    margin-top: 34px;

    .actionButton{
      width: 95px;
      height: 40px;
      position: relative;
      float: right;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 10px 10px;
      border-radius: 2px;
      background-color: #6C00EA;
      border: 1px solid #6C00EA;
      cursor: pointer;
      margin-left: 30px;

      &.cancel{
        color: #6C00EA;
        background-color: #fff;
        border-color: #fff;
        transition: all .3s ease-in-out;

        &:hover{
          color: #fff;
          background-color: #6C00EA;
          border-color: #6C00EA;
        }
      }

      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
      &:disabled{
        cursor: not-allowed;
        opacity:0.5;
      }
    }
  }
}


.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #6C00EA !important;
  border-color: #6C00EA !important;
  color: #fff !important;
}
.pagination > li > a,
.pagination > li > span {
  color: #6C00EA !important;
}

.mentionWrapper{
  width: 100%;
  position: relative;
  float: left;
}
.mentiondropdown{
  width: 100%;
  position: absolute;
  bottom: 51px;
  left: 0;
  max-height: 145px;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 2px 10px 0 rgb(0, 0, 0, .16);
  border: solid 1px #e9e9f0;
  background-color: #ffffff;
  text-align: left;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  display: inline-block;
  overflow: auto;


  &::-webkit-scrollbar {
    width: 2px;
    border-radius:2px;
  }
  &::-webkit-scrollbar-track {
    background: #F8F9FA;
  }
  &::-webkit-scrollbar-thumb {
    background: #b4b2b2;
    opacity:0.2;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b4b2b2;
    opacity:0.2;
  }

  .mentionSelection-list {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 12px 15px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    transition: all .3s ease-in-out;

    &:hover{
      background-color: rgba(0,0,0,.05);
    }
    &:last-of-type{
      border-bottom:none;
    }

    @for $i from 1 through $userInitialsColorsLength {
      &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}){
        .mentionUser-initial{
          background-color: nth($userInitialsBackgroundColors, $i);
          color: nth($userInitialsColors, $i);
        }
      }
    }
    .mentionUser-initial{
      width: 25px;
      height: 25px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      border-radius: 50%;
      vertical-align: top;
    }
    .mentionUser-descriptionWrapper{
      width: calc(100% - 34px);
      position: relative;
      display: inline-block;
      color: #83889E;
      padding-left: 7px;
      transition: all .3s ease-in-out;
      line-height: 1.2em;

      &:hover{
        color: #6C00EA;
      }
    }

    .mentionUser-email{
      width: 100%;
      position: relative;
      float: left;
      font-size: 10px;
      color: #9B9FB1;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 12px;
    }
    .mentionUser-name{
      width: 100%;
      position: relative;
      float: left;
      font-size: 13px;
      font-weight: normal;
      color: #83889E;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 16px;
    }
  }
}

.mentionTitle{
  position: relative;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;

  &:hover{
    .elementHover{
      visibility: visible;
      opacity: 1;
      bottom: -32px;
      transform: translateX(-50%);
    }
  }
}

.replyInput{
  .mentiondropdown{
    bottom: 40px;
  }
}

.replyWrapper{
  .mentiondropdown{
    width: calc(100% - 35px);
    margin-left: 35px;
    bottom: 40px;
  }
  .replyInput{
    .mentiondropdown{
      bottom: 40px;
    }
  }
}

.cdk-drag-preview {
  width: 265px;
  position: relative;
  display: inline-block;
  background: #F8F9FA;
  border-radius: 6px;
  margin: 0 7px;
  white-space: initial;
  vertical-align: top;
  padding: 19px 10px 12px;
  font-family: "Rubik", sans-serif;

  .drag_column_header{
    padding: 0 5px 2px;
    .toggle-title{
      color: #676B7E;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .column_header {
    display: none;
  }
  .epicHeader{
    padding: 0 5px 20px;
  }
  .storycolumn{
    .projectStory{
      margin-bottom: 10px !important;
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.column-list:last-child {
  border: none;
}

.project-column-list.cdk-drop-list-dragging .column-list:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ifReadOnly{
  width: 100%;
  height: calc(100% - 54px);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: not-allowed;
}
.ifReadOnly2 {
  width: 100%;
  height: 29px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  border-radius: 4px;
}
.ifReadOnly3 {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 110;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  border-radius: 0;
}
.relatedToWrapper{
  width: 100%;
  position: relative;
  display: inline-block;

  &.inPopup{
    // margin-top: 20px;
    .addRelated-input{
      border: 1px solid rgba(0, 0, 0, .1);
    }
  }

  .addRelated-to{
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
  }

  .addRelated-input{
    width: 100%;
    height: 42px;
    position: relative;
    display: inline-block;
    cursor: text;
    outline: none;
    padding: 0 10px;
    font-size: 13px;
    font-weight: normal;
    border-radius: 4px;
    margin-bottom: 10px;
    border: solid 1px #e9e9f0;
    background-color: #ffffff;
    // color: #000;
  }

  .relatedto-storyList-wrapper{
    width: 100%;
    max-height: 175px;
    position: absolute;
    top: 42px;
    left: 0;
    display: inline-block;
    box-shadow: 1px 4px 3px 0 rgb(0 0 0 / 16%);
    border: solid 1px #e9e9f0;
    border-radius: 4px;
    background-color: #fff;
    z-index: 12;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
    &:empty{
      display: none;
    }
    .no-story-available{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      padding: 15px 12px;
    }

    .relatedto-storyList{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      padding: 12px;
      border-bottom: solid 1px #e9e9f0;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all .2s ease-in-out;

      .relatedTo-storyId{
        position: relative;
        display: inline-block;
        font-weight: 500;
      }

      &:hover {
        color: #6c00ea;
      }

      &:last-of-type{
        border-bottom: none;
      }
    }
  }
}

.storyStartConfirmation-wrapper, .certificationConfirmation-wrapper{
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  color: #3C3E49;

  .storyStartConfirmation-title{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.39;
    text-align: left;
    color: #000;
    em{
      font-size: 22px;
      display: inline-block;
      height: 22px;
      vertical-align: sub;
      color: #6C00EA;
    }
  }
  .storyStartConfirmation-text{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 6px 0 15px;
    font-size: 13px;
    font-weight: 400;
  }
  .confirmOption-wrapper{
    width: 100%;
    position: relative;
    display: inline-block;
  }

  .check-icon{
    width: 13px;
    height: 13px;
    position: relative;
    display: inline-block;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 2px;
    border-color: #6C00EA;
    background-color: #6C00EA;
    cursor: pointer;
    vertical-align: top;
    margin-top: 2px;
    
    &:before{
      content: '';
      width: 7px;
      height: 4px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      display: block;
      transform: rotate(-47deg);
    }
  }
  .confirmOption-list{
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 0;
  }
  .confirmOption-text{
    width: calc(100% - 20px);
    position: relative;
    display: inline-block;
    padding-left: 10px;
    white-space: pre-line;

    &.read_only{
      padding-left: 14px;
      &:before{
        content: '-';
        position: absolute;
        left: 2px;
        top: 0;
      }
    }
  }
  
  .option-title{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 6px 0 6px;
    font-size: 13px;
    font-weight: 500;
  }
}
.certificationConfirmation-wrapper{
  .confirmOption-wrapper{
    max-height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
      border-radius:2px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
  }
  .customCheckFor-certificationList{
    width: 16px;
    height: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    padding-top: 2px;

    .check-certificationList{
      width: 14px;
      height: 14px;
      position: relative;
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #fff;
      border-radius: 2px;
      cursor: pointer;

      &:before{
        content: '';
        width:8px;
        height: 4px;
        position: absolute;
        top: 3px;
        left: 2px;
        border-left: 2px solid rgba(0,0,0,.015);
        border-bottom: 2px solid rgba(0,0,0,.015);
        display: block;
        transform: rotate(-47deg);
      }
    }

    input[type='checkbox'].certificationList-checkbox:checked + .check-certificationList{
      border-color: #6C00EA;
      background-color: #6C00EA;

      &:before{
        content: '';
        width: 8px;
        height: 4px;
        position: absolute;
        top: 3px;
        left: 2px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        display: block;
        transform: rotate(-47deg);
      }
    }
  }
}

.quickAction-popUp-wrapper{
  .storyStartConfirmation-wrapper{
    margin: 10px 0 0;
  }
}

.fliterButton-wapper{
  width: auto;
  position: relative;
  float: right;
  
  .filterBackdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 111;
  }
  .filterButton{
    width: auto;
    height: 30px;
    position: relative;
    display: inline-block;
    background-color: #fff;
    border-radius: 3px;
    border:none;
    padding: 6px 8px;
    transition: background-color .3s ease-in-out;
    cursor: pointer;

    &.for_story_approval{
      height: 33px;
      border: 1px solid #ecedf0;
      border-radius: 3px;
    }

    em{
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      color: #83889E;
      height: 14px;
      margin-right: 6px;
    }
    .filterbutton-title{
      display: inline-block;
      font-size: 12px;
      color: #83889E;
      &.release-filter {
        line-height: normal;
        vertical-align: middle;
        height: 12px;
      }
    }

    &:hover{
      background-color: #ECEDF0;
    }
    &.buttonSelected{
      background-color: #ECEDF0;
    }
  }
  .filterDD{
    width: 570px;
    height: 100% !important;
    position: fixed;
    top: 0;
    right: -190px;
    border: 1px solid #DFE0E6;
    border-radius: 0;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    z-index: 145;
    overflow: hidden;
    transition: all .1s ease-in-out;

    &.filterDD-open{
      // top: 33px;
      right:0;
      visibility: visible;
      opacity: 1;
    }

    &.forInternal{
      height: 384px;
      .filterOptions-typeBock{
        height: 331px;
      }
      .ownerListWrapper{
        height: 273px;
      }
    }
    .filterWrapper{
      width: 100%;
      height: 100%;
      position: relative;
      display: inline-block;
      overflow: hidden auto;
      display: flex;
      flex-wrap: wrap;
      
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }
    .resetFilter-block{
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 10px 13px;
      border-bottom: 1px solid #ECEDF0;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 5;
    }
    .filterCounter{
      width: calc(100% - 70px);
      position: relative;
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      color: #676B7E;
      line-height: 12px;
      vertical-align: middle;
    }
    .resetFilter-button{
      width: 70px;
      position: relative;
      display: inline-block;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      color: #ffffff;
      line-height: 10px;
      padding: 8px 0;
      cursor: pointer;
      border: none;
      background-color: #6C00EA;
      border-radius: 3px;
      vertical-align: middle;

      &:focus, &:active{
        outline: none;
      }

      &:disabled{
        opacity: .3;
        cursor: not-allowed;
      }
    }
    .filterOptions-type{
      width: 190px;
      height: calc(100% - 47px);
      min-height: calc(100% - 47px);
      position: relative;
      display: inline-block;
      background-color: #F8F9FA;
      vertical-align: top;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }
    .filterOptions-typeListing{
      width: 100%;
      position: relative;
      float: left;
      padding: 15px 13px;
      border-bottom: 1px solid #ECEDF0;
      cursor: pointer;

      &:last-of-type{
        border-bottom: none;
      }

      &.selected{
        background-color: #fff;
      }

      .typeList-name{
        width: 125px;
        position: relative;
        float: left;
        font-size: 12px;
        font-weight: 500;
        color: #3C3E49;
      }
      .typeList-counter{
        position: relative;
        float: right;
        font-size: 12px;
        font-weight: 400;
        color: #6C00EA;
      }
    }
    .filterOptions-typeBock{
      width: calc(100% - 190px);
      height: calc(100% - 47px) !important;
      position: relative;
      display: inline-block;
      padding:12px 20px;
      background-color: #fff;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }
    .filtertitle{
      width: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #3C3E49;
      padding: 0 20px;
    }

    .ownerBlock, .featureBlock, .labelBlock{
      padding:12px 0;
    }

    .typeBock-listing{
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 8px 0;

      @for $i from 1 through $userInitialsColorsLength {
        &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}){
          .typeBock-ownerInitials{
            background-color: nth($userInitialsBackgroundColors, $i);
            color: nth($userInitialsColors, $i);
            border-color: nth($userInitialsBorderColor, $i);
          }
        }
      }
      .disableFilter{
        opacity: .3;
      }

      &:last-child {
        .sprint-status {
          &:hover {
            .sprint-status-tooltip {
              bottom: auto;
              top: -32px;
            }
          }
          .sprint-status-tooltip {
            bottom: auto;
            top: -45px;
            &::before {
              top: auto;
              bottom: -6px;
            }
          }
        }
      }
      
      .sprint-status {
        display: inline-block;
        height: 14px;
        width: 14px;
        min-width: 14px;
        border-radius: 50%;
        background-color: #BFBFBF;
        position: relative;
        font-style: normal;
        top: 1px;
        margin-left: 5px;
        cursor: pointer;
        &.draft {
          background-color: #FF6D00;
        }
        &.running {
          background-color: #40C4FF;
        }
        &.paused {
          background-color: #FFAB00;
        }
        &.completed {
          background-color: #4CAF50;
        }
        &:hover {
          .sprint-status-tooltip {
            visibility: visible;
            opacity: 1;
            bottom: -32px;
          }
        }
        .sprint-status-tooltip {
          width: auto;
          color: #FFFFFF;
          font-size: 11px;
          letter-spacing: -0.01em;
          padding: 5px 9px 3px;
          border-radius: 4px;
          background-color: #3C3E49;
          display: block;
          position: absolute;
          right: -5px;
          bottom: -45px;
          opacity: 0;
          pointer-events: none;
          white-space: nowrap;
          transition: all 0.3s ease;
          z-index: 1;
          transform: none;
          text-transform: capitalize;
          &::before {
            content: "";
            width: 7px;
            height: 7px;
            display: block;
            position: absolute;
            top: -1px;
            left: calc(100% - 13px);
            background-color: #3C3E49;
            transform: rotate(45deg) translateX(-50%);
          }
        }
      }

      .typeBock-label {
        display: block;
        margin-bottom: 0;
        cursor: pointer;
        }

      .elementHover{
        left: 0;
        transform: none;
        &:before {
          left: 16%;
        }
      }

      &:hover .elementHover{
        visibility: visible;
        opacity: 1;
        bottom: -23px;
        transform: none;
      }

      .filterbyDate-button{
        width: 89px;
        height: 32px;
        position: relative;
        // display: inline-block;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        background-color: #6C00EA;
        border: 1px solid #6C00EA;
        cursor: pointer;
        margin: 0;
        font-weight: 500;
        padding: 8px;
        float: right;
    
        &:active, &:focus{
          outline: none;
          box-shadow: none;
        }
        &:disabled{
          cursor: not-allowed;
          opacity:0.5;
        }
      }
    }

    .typeBock-listing-subtype{
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 1px 23px;

      @for $i from 1 through $userInitialsColorsLength {
        &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}){
          .typeBock-ownerInitials{
            background-color: nth($userInitialsBackgroundColors, $i);
            color: nth($userInitialsColors, $i);
            border-color: nth($userInitialsBorderColor, $i);
          }
        }
      }

      .filterbyDate-button{
        width: 89px;
        height: 32px;
        position: relative;
        // display: inline-block;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        background-color: #6C00EA;
        border: 1px solid #6C00EA;
        cursor: pointer;
        margin: 0;
        font-weight: 500;
        padding: 8px;
        float: right;
    
        &:active, &:focus{
          outline: none;
          box-shadow: none;
        }
        &:disabled{
          cursor: not-allowed;
          opacity:0.5;
        }
      }
    }

    .filterSearchWrapper{
      width: calc(100% - 20px);
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      margin-left: 10px;

      .filterSearch{
        width: 100%;
        height: 32px;
        position: relative;
        display: inline-block;
        font-size: 12px;
        color: #676B7E;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        background-color: transparent;
        padding: 0 10px;

        &:focus, &:active{
          outline: none;
        }
      }
      .filterSearchButton{
        width: 26px;
        height: 30px;
        position: absolute;
        top: 1px;
        right: 1px;
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus, &:active{
          outline: none;
        }

        em{
          font-size: 10px;
          display: inline-block;
          height: 10px;
        }
      }
    }
    .ownerListWrapper{
      width: 100%;
      height: calc(100% - 90px);
      position: relative;
      display: inline-block;
      padding:0 20px;
      background-color: #fff;
      // overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }
    .devicePlatformList-wrapper{
      width: 100%;
      max-height: 465px;
      position: relative;
      display: inline-block;
      padding:0 20px;
      background-color: #fff;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }
    .devicePlatformList-wrapper{
      .typeBock-listing {
        padding-left: 24px;
        border-bottom: 1px solid rgba(0,0,0,.1);
      }
    }
    .devicePlatformList{
      width: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 10px;

      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .deviceType{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 12px;
      color: #3C3E49;
      font-weight: 500;
      margin: 7px 0;

      em{
        font-size: 18px;
        display: inline-block;
        height: 18px;
        margin-right: 3px;
        vertical-align: text-bottom;
      }
    }
    .typeBock-check{
      width: 16px;
      height: 16px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      float: left;
      margin-top: 2px;

      .customCheckForStorySelection{
        width: 16px;
        position: relative;
        display: inline-block;
        margin-bottom: 0;

        .check-toggle{
          width: 13px;
          height: 13px;
          position: relative;
          display: inline-block;
          border: 1px solid rgba(0,0,0,.15);
          background-color: #fff;
          border-radius: 2px;
          cursor: pointer;

          &:before{
            content: '';
            width: 7px;
            height: 4px;
            position: absolute;
            top: 2px;
            left: 2px;
            border-left: 1px solid rgba(0,0,0,.015);
            border-bottom: 1px solid rgba(0,0,0,.015);
            display: block;
            transform: rotate(-47deg);
          }
        }

        input[type='checkbox'].story-selected-checkbox:checked + .check-toggle{
          border-color: #6C00EA;
          background-color: #6C00EA;

          &:before{
            content: "";
            width: 7px;
            height: 4px;
            position: absolute;
            top: 2px;
            left: 2px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            display: block;
            transform: rotate(-47deg);
          }
        }
      }
    }
    .typeBock-ownerInitials{
      width: 20px;
      height: 20px;
      position: relative;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 10px;
      border-radius: 50%;
      margin-left: 5px;
    }
    .typeBock-name{
      width: calc(100% - 50px);
      position: relative;
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: #3C3E49;
      vertical-align: middle;
      padding-left: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .featureBlock, .labelBlock{
      .typeBock-name{
        max-width: calc(100% - 60px);
        width: auto;
        height: 22px;
        position: relative;
        float: left;
        font-size: 9px;
        font-weight: 400;
        color: #676B7E;
        padding: 5px 10px;
        background: #ECEDF0;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: -2px;
      }
    }
    .featureBlock{
      .typeBock-name{
        color: #9F4DFF;
        background: #F4EAFF;
      }
    }
  }
}

.sprint-wrapper {
  width: 100%;
  position: relative;
  display: block;
  .topInfo-section {
    width: calc(100% - 200px);
    height: 37px;
    position: absolute;
    display: inline-block;
    padding: 0;
    color: #3C3E49;
    top: -77px;
    right: 0;
    //z-index:1;

    .topActionButtons{
      width: 100%;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: right;

      .tableTitle{
        position: relative;
        float: left;
        margin-right: 35px;
        font-size: 13px;
        font-weight: 500;
        color: #3C3E49;
        border-bottom: 2px solid #651FFF;
        padding: 16px 0 8px;
      }
      .integrationSection-search{
        width: 240px;
        position: relative;
        float: right;

        .integrationSection-searchInput{
          width: 100%;
          height: 37px;
          position: relative;
          display: inline-block;
          font-size: 12px;
          font-weight: normal;
          color: #3C3E49;
          padding: 0 15px 0 40px;
          border-radius: 3px;
          border: 1px solid #DFE0E6;
          background-color: #fff;

          &:focus, &:active{
            outline: none;
            box-shadow: none;
          }
          &::-webkit-input-placeholder {
            color: #AFB2C0;
          }
          &:-ms-input-placeholder {
            color: #AFB2C0;
          }
          &::placeholder {
            color: #AFB2C0;
          }
        }
        .integrationSection-searchButton{
          width: 36px;
          height: 33px;
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 1;
          background-color: transparent;
          text-align: center;
          border: none;

          &:focus, &:active{
            outline: none;
          }

          em{
            color: #83889E;
            line-height: 36px;
          }
        }
      }

      .sortby-sprints {
        float: right;
        margin-right: 12px;
        position: relative;
        &.expanded {
          .sort-trigger {
            background: #F8F9FA;
          }
          .sort-options {
            display: block;
          }
        }
        .sort-trigger {
          padding: 7px 10px;
          display: inline-flex;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 23px;
          color: #83889E;
          cursor: pointer;
          border-radius: 3px;
          transition: all 0.5s;
      
          .iconb-sorting {
            line-height: 0;
            font-size: 16px;
            margin-right: 9px;
          }
        }
      
        .sort-options {
          display: none;
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          width: 113px;
          text-align: left;
          filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.1));
          background: #fff;
          z-index: 2;
          .sort-outer {
            border-bottom: 1px solid #DFE0E6;
            &:last-child {
              border-bottom: 0;
            }
            &:hover {
              .submenu-trigger {
                background-color: #F8F9FA;
                .iconb-dd-down {
                  transform: rotate(90deg);
                }
              }
              .submenu {
                display: block;
              }
            }
            .submenu-trigger {
              font-size: 13px;
              line-height: 24px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 4px 8px;
              color: #9B9FB1;
              cursor: pointer;
              position: relative;
              transition: all 0.5s;
              .iconb-dd-down {
                line-height: 0;
                color: #C9C9C9;
                font-size: 9px;
                transform: rotate(-90deg);
                transition: all 0.5s;
              }
      
            }
            .submenu {
              position: absolute;
              left: 100%;
              top: 0;
              background: #fff;
              width: 113px;
              display: none;
              filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.1));
              .submenu-option {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px 8px;
                border-bottom: 1px solid #DFE0E6;
                color: #9B9FB1;
                font-size: 13px;
                line-height: 24px;
                cursor: pointer;
                &.active {
                  .iconb-tick {
                    display: inline-block;
                  }
                }
      
                &:last-child {
                  border-bottom: 0;
                }
                .iconb-tick {
                  line-height: 0;
                  color: #651FFF;
                  font-size: 10px;
                  display: none;
                }
              }
            }
          }
        }
      }

      .addNewProject-release{
        width: 120px;
        height: 37px;
        position: relative;
        float: right;
        padding: 7px 12px 7px;
        font-size: 12px;
        font-weight: 500;
        color: #1890FF;
        text-align: center;
        margin-left: 20px;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #1890FF;
        transition: all 0.3s ease-in-out;

        &:hover{
          background-color: #1890FF;
          color: #fff;
        }
        &:focus, &:active{
          outline: none;
        }
      }
      .bulk-action-block{
        width: calc(100% - 65px);
        height: 100%;
        display: inline-block;
        position: absolute;
        top: 0;
        padding-top: 6px;
        right: 0;
        background-color: #F8F9FA;
        z-index: 1;
        &.extra-space{
          width: calc(100% - 56px);
        }
      }
      .bulk-add-stories{
        height: 32px;
        position: relative;
        float: right;
        padding: 7px 16px 7px;
        font-size: 13px;
        line-height: normal;
        text-align: center;
        margin-right: 13px;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        background-color: #1890ff;
        color: #fff;
        border: 1px solid #1890ff;
        margin-top: 3px;

        &:disabled{
          opacity: .3;
          cursor: not-allowed;
        }
      }

      .sprint-sorting{
        //width: 200px;
        position: relative;
        float: right;
        margin-right: 20px;
      }
      .selectedFilter{
        position: relative;
        float: right;
        height: 37px;
        font-size: 13px;
        font-weight: 500;
        color: #83889E;
        padding: 9px 11px;
        cursor: pointer;
        border-radius: 3px;
        transition: background-color .3s ease-in-out;
        &:hover{
          background-color: #ECEDF0;
        }

        em{
          display: inline-block;
          margin-right: 4px;
          font-size: 14px;
          height: 14px;
          vertical-align: text-top;
        }
        &.buttActive{
          background-color: #F3EBFF;
          color: #651FFF;
        }
      }
      .sprint-sorting-options{
        position: absolute;
        width: 190px;
        display: inline-block;
        top: 40px;
        right: 0;
        padding: 5px 15px;
        background: #FFFFFF;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.14);
        border-radius: 4px;
        text-align: left;
        z-index: 150;

        .sprint-sorting-option{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 12px;
          color: #83889E;
          cursor: pointer;
          padding: 10px 3px 5px;
          border-bottom: 1px solid rgba(0,0,0,.1);

          &.active{
            color: #6C00EA;
          }

          em{
            display: inline-block;
            height: 11px;
            margin-right: 5px;
            font-size: 12px;
            vertical-align: text-top;
          }

          &:last-of-type{
            border-bottom: 0;
          }
        }
      }
      .fliterButton-wapper{
        width: auto;
        position: relative;
        float: right;
        margin-right: 20px;
        text-align: left;

        .filterButton{
          width: auto;
          height: 36px;
          position: relative;
          display: inline-block;
          background-color: #fff;
          border-radius: 3px;
          border:none;
          padding: 9px 11px;
          transition: background-color .3s ease-in-out;
          cursor: pointer;

          em{
            font-size: 13px;
            display: inline-block;
            vertical-align: middle;
            color: #83889E;
            height: 14px;
            margin-right: 6px;
          }
          .filterbutton-title{
            display: inline-block;
            font-size: 13px;
            color: #83889E;
            font-weight: 500;
          }

          &:hover{
            background-color: #ECEDF0;
          }
          &.buttonSelected{
            background-color: #F3EBFF;
            color: #651FFF;
          }
        }
        .filterBackdrop{
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          background-color: transparent;
          z-index: 111;
        }
      }
    }
  }

  .is-collapse{
    width: 44px;
    position: relative;
    display: flex;
    writing-mode: vertical-lr;
    align-items: center;
    padding-top: 27px;
    font-size: 14px;
    font-weight: 500;
    color: #3C3E49;
  }

  .ifNoSprint-exits{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(71vh - 110px);
    justify-content: center;
    align-items: center;
    .ifNoSprint-text, .ifNoSprint-text2{
      width: 100%;
      position: relative;
      display: block;
      font-size: 14px;
      text-align: center;
    }
    .ifNoSprint-text2{
      font-size: 12px;
      color: #83889E;
      margin-top: 10px;
    }

    .addNewProject-release{
      width: 120px;
      height: 37px;
      position: relative;
      float: right;
      padding: 7px 12px 7px;
      font-size: 12px;
      font-weight: 500;
      color: #1890FF;
      text-align: center;
      margin-top: 10px;
      border-radius: 2px;
      background-color: #fff;
      border: 1px solid #1890FF;
      transition: all 0.3s ease-in-out;

      &:hover{
        background-color: #1890FF;
        color: #fff;
      }
      &:focus, &:active{
        outline: none;
      }
    }
  }

  

  .releaseVersion-table{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0;
    height: calc(100vh - 242px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius:2px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(60, 62, 73, 0.2);
      border-radius: 8px;
    }

    .releaseVersion-tableHead, .releaseVersion-tableContent{
      width: 100%;
      position: relative;
      display: inline-block;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      font-size: 13px;
    }
    .releaseVersion-tableHead{
      position: sticky;
      top: 0;
      left: 0;
      z-index: 111;
      background: #fff;

      .rowCheckBox{
        width: 16px;
        height: 18px;
        position: relative;
        display: inline-block;
        visibility: visible;
        opacity: 1;
        margin-top: 2px;
      }
      .customCheckForRowSelection {
        width: 16px;
        position: relative;
        display: inline-block;
        margin-bottom: 0;
    
        .check-toggle {
          width: 13px;
          height: 13px;
          position: relative;
          display: inline-block;
          border: 1px solid rgba(0, 0, 0, .15);
          background-color: #fff;
          border-radius: 2px;
          cursor: pointer;
    
          &:before {
            content: '';
            width: 8px;
            height: 4px;
            position: absolute;
            top: 2px;
            left: 2px;
            border-left: 1px solid rgba(0, 0, 0, .015);
            border-bottom: 1px solid rgba(0, 0, 0, .015);
            display: block;
            transform: rotate(-47deg);
          }
        }
    
        input[type='checkbox'].story-selected-checkbox:checked + .check-toggle {
          border-color: #6C00EA;
          background-color: #6C00EA;
    
          &:before {
            content: "";
            width: 8px;
            height: 4px;
            position: absolute;
            top: 2px;
            left: 2px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            display: block;
            transform: rotate(-47deg);
          }
        }
      }
    }
    .releaseVersion-tableContent{
      color: #83889E;
    }
    .version-no, .sprint-name, .story-progress, .stories-status-values, .versionStatus, .noOfFeatures, .shippingDate{
      width: 100%;
      position: relative;
      display: block;
      margin-bottom: 5px;
      line-height: normal;
    }
    .stories-status-values{
      margin-bottom: 0;
    }
    .name-text{
      max-width: calc(100% - 34px);
      position: relative;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 16px;
    }
    .story-progress{
      width: calc(100% - 25px);
      height: 6px;
      margin: 15px 0 10px;
      border-radius: 10px;
      // overflow: hidden;

      .progress-wrapper {
        width: 100%;
        position: relative;
        display: block;
        border-radius: 10px;
        overflow: hidden;
        height: 6px;
        margin: 12px 0;
      }

      .status-type{
        height: 6px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        border-right: 1px solid #fff;

        &:first-of-type{
          border-radius: 10px 0 0 10px;
        }

        &:last-of-type{
          border-right: none;
          border-radius: 0 10px 10px 0;
        }
      }

      .story-breakup{
        width: 200px;
        display: block;
        position: absolute;
        top: 30px;
        left: 0;
        padding: 9px 15px;
        background-color: #3C3E49;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        z-index:11;
        transition: all .2s ease-in-out;

        &:before{
          content: '';
          width: 12px;
          height:12px;
          position: absolute;
          top: -6px;
          left: 30px;
          background-color: #3C3E49;
          transform: rotate(45deg);
        }

        .story-breakup-title{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 10px;
          border-bottom: 1px solid rgba(255,255,255,.2);
          padding-bottom: 10px;
          color: #fff;
          text-transform: uppercase;
        }
        .story-breakup-list{
          width: 100%;
          position: relative;
          display: inline-block;
          padding: 6px 0;
          border-bottom: 1px solid rgba(255,255,255,.2);

          &:last-of-type{
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .story-status-color{
          width: 12px;
          height: 12px;
          float: left;
          position: relative;
          border-radius: 50%;
          margin-right: 10px;
          margin-top: 1px;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
        .story-status-name{
          width: calc(100% - 60px);
          position: relative;
          float: left;
          color: #fff;
          font-size: 12px;
        }
        .story-count{
          width: 35px;
          position: relative;
          float: left;
          color: #fff;
          font-size: 10px;
          text-align: right;
        }
      }

      &:hover{
        .story-breakup{
          top: 20px;
          visibility: visible;
          opacity:1;
        }
      }
    }
  }
}
.releaseVersion-tableRow{
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 15px 0 10px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  cursor: pointer;
  color: #83889E;

  &:last-of-type{
    border-bottom: none;
  }

  &.sub-task{
    border-left: 1px solid #6200ea;
  }
  
  .nameBlock {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: -3px;

    &:hover{
      .elementHover{
        visibility: visible;
        opacity: 1;
        bottom: -35px;
      }
    }
  }
  .userInitials{
    width: 28px;
    height: 28px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    border-radius: 50%;
    padding-top: 2px;
    cursor: pointer;
    text-transform: capitalize;

    &.noMember{
      border: 1px dashed #AFB2C0 !important;
      background-color: #F8F9FA !important;
      color: #83889E !important;

      em{
        font-size: 12px;
      }
    }
  }
  .nameWithInitials{
    width: calc(100% - 30px);
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #83889E;
    vertical-align: middle;
    padding-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    line-height: 14px;
    margin-top: 2px;

    &:focus, &:active{
      outline: none;
    }
    .ifUserDeallocated{
      width: 100%;
      height: 100%;
      position: absolute;
      top: -1px;
      left: -20px;
      font-size: 11px;
      font-weight: 500;
      color: #fff;
      background-color: #3C3E49;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      z-index: 11;
      padding: 7px 12px;
      border-radius: 4px;
      cursor: not-allowed;
      &.sameUser{
        width: calc(100% + 10px);
        height: 49px;
        top: -11px;
        padding: 11px 12px;
        white-space: normal;
      }
    }
    .selectUser-email{
      width: 100%;
      position: relative;
      float: left;
      font-size: 10px;
      color: #9B9FB1;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 12px;
    }
    .selectUser-name{
      width: 100%;
      position: relative;
      float: left;
      font-size: 13px;
      font-weight: normal;
      color: #83889E;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
  .story-summary{
    width: 100%;
    position: relative;
    display: inline-block;

    &.hide-story-summary{
      display: none;
    }

    .story-title{
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 35px);
    }

    .story-summary-text{
      max-width: calc(100% - 34px);
      position: relative;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }


    .editTitle{
      width: 24px;
      height: 21px;
      font-size: 11px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      padding-top: 4px;
      border: 1px solid #651FFF;
      border-radius: 3px;
      margin-left: 7px;
      text-align: center;
      vertical-align: top;
      margin-top: -3px;
      color: #651FFF;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }
  .tableCell-block{
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .sprint-date{
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .no_of_working_days{
    position: relative;
    display: inline-block;
    padding: 5px 10px 3px;
    border-radius: 3px;
    margin-top: 3px;
    background-color: #fff;

    em{
      display: inline-block;
      font-size: 14px;
      height: 14px;
      margin-right: 2px;
      vertical-align: text-top;
      margin-top: 0px;
    }
  }
  .sprint-status{
    position: relative;
    display: inline-block;
    padding: 7px 15px;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    font-size: 11px;
    line-height: normal;
    margin-top: -4px;
    text-transform: capitalize;

    &.draft{
      background-color: #FF6D00;
    }
    &.running{
      background-color: #40C4FF;
    }
    &.paused{
      background-color: #FFAB00;
    }
    &.completed{
      background-color: #4CAF50;
    }
  }
  .story-statusWrapper{
    position: relative;
    display: inline-block;

    .story-statusIcon{
      width: 12px;
      height: 12px;
      position: relative;
      display: inline-block;
      border-radius: 50%;
    }
    .elementHover{
      text-transform: capitalize;
    }
    &:hover{
      .elementHover{
        text-transform: capitalize;
        visibility: visible;
        opacity: 1;
        bottom: -35px;
      }
    }
  }
  .story-type{
    position: relative;
    .elementHover{
      text-transform: capitalize;
      line-height: initial;
    }
    &:hover{
      .elementHover{
        text-transform: capitalize;
        visibility: visible;
        opacity: 1;
        bottom: -35px;
        line-height: initial;
      }
    }
  }
  .story-id, .sprint-name{
    position: relative;
    display: inline-block;
    transition: all 0.2s ease-in-out;

    .copy-bt, .edit-bt{
      width: 24px;
      height: 22px;
      font-size: 12px;
      position: absolute;
      display: inline-block;
      cursor: pointer;
      padding-top: 4px;
      border: 1px solid #651FFF;
      border-radius: 3px;
      color: #651FFF;
      margin-top: -4px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      margin-left: 4px;
      text-align: center;
    }
    .edit-bt{
      //margin-left: 0;
    }
    &:hover{
      .elementHover{
        visibility: visible;
        opacity: 1;
        bottom: -32px;
        transform: translateX(-50%);
      }
    }
  }
  .editName, .editDescription{
    width:90%;
    position: relative;
    display: inline-block;
    padding: 0 0 3px;
    margin: 0;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #651FFF;

    &:focus, &:active{
      outline: none;
    }
  }
  .story-priority{
    //width: calc(100% - 20px);
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #83889E;
    cursor: pointer;

    .iconb-restrict{ color: #D50000;}
    .iconb-thick-arrow-up{color: #FF1744;}
    .iconb-doublechevron-up{color: #FF6D00;}
    .iconb-doublechevron-down{color: #4CAF50;}
    .iconb-circle-withdot{color: #536DFE;}

    &:hover{
      .elementHover{
        text-transform: capitalize;
        visibility: visible;
        opacity: 1;
        bottom: -35px;
      }
    }
  }
  .moreActions{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
  }
  .moreAction-bt{
    width: 24px;
    height: 22px;
    font-size: 15px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-top: 2px;
    border: 1px solid #651FFF;
    border-radius: 3px;
    color: #651FFF;
    margin-top: -2px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in-out;

    &.makeActive{
      opacity: 1;
      visibility: visible;
    }
  }
  .storyPriority-wrapper, .storyUserList-wrapper, .storyaction-wrapper{
    position: absolute;
    width: 190px;
    display: inline-block;
    top: 21px;
    left: 0;
    padding: 0;
    background: #FFFFFF;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .14);
    border-radius: 4px;
    text-align: left;
    z-index: 1;

    .list-optionWrapper{
      width: 100%;
      max-height:140px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius:4px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }

    .storyPriority-option, .storyUserList-option, .storyaction-option{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 12px;
      color: #83889E;
      cursor: pointer;
      padding: 10px 18px 10px;
      border-bottom: 1px solid rgba(0,0,0,.1);

      em{
        display: inline-block;
        height: 14px;
        margin-right: 5px;
        font-size: 14px;
        vertical-align: top;

        &.iconb-restrict{ color: #D50000;}
        &.iconb-thick-arrow-up{color: #FF1744;}
        &.iconb-doublechevron-up{color: #FF6D00;}
        &.iconb-doublechevron-down{color: #4CAF50;}
        &.iconb-circle-withdot{color: #536DFE;}
      }

      &:last-of-type{
        border-bottom: 0;
      }
    }
    .storyUserList-option{
      padding: 0;
      .nameBlock {
        margin: 0;
        padding: 10px 18px 10px;
        &.userNotActive{
          .nameWithInitials{
            overflow: unset;
          }
          background-color: rgba(0,0,0,.05);
          cursor: not-allowed;
    
          &:hover{
            background-color: rgba(0,0,0,.05);
            .nameWithInitials{
              .ifUserDeallocated{
                left: 3px;
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .rowCheckBox{
    width: 16px;
    height: 18px;
    position: relative;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
  }
  .selectStory-row {
    width: 16px;
    height: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .customCheckForRowSelection {
    width: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;

    .check-toggle {
      width: 13px;
      height: 13px;
      position: relative;
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, .15);
      background-color: #fff;
      border-radius: 2px;
      cursor: pointer;

      &:before {
        content: '';
        width: 8px;
        height: 4px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-left: 1px solid rgba(0, 0, 0, .015);
        border-bottom: 1px solid rgba(0, 0, 0, .015);
        display: block;
        transform: rotate(-47deg);
      }
    }

    input[type='checkbox'].story-selected-checkbox:checked + .check-toggle {
      border-color: #6C00EA;
      background-color: #6C00EA;

      &:before {
        content: "";
        width: 8px;
        height: 4px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        display: block;
        transform: rotate(-47deg);
      }
    }
  }
  .storyUserList-wrapper{
    width: 250px;
    left: -100px;
    .storyUser-search{
      width: 100%;
      position: relative;
      display: inline-block;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding: 0 5px;

      .storyUser-searchInput{
        width: 100%;
        height: 36px;
        position: relative;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        color: #3C3E49;
        padding: 0 15px 0 40px;
        border-radius: 3px;
        border:none;
        background-color: transparent;

        &:focus, &:active{
          outline: none;
          box-shadow: none;
        }
        &::-webkit-input-placeholder {
          color: #AFB2C0;
        }
        &:-ms-input-placeholder {
          color: #AFB2C0;
        }
        &::placeholder {
          color: #AFB2C0;
        }
      }
      .storyUser-searchButton{
        width: 30px;
        height: 33px;
        position: absolute;
        top: 2px;
        left: 13px;
        z-index: 1;
        background-color: #fff;
        text-align: center;
        border: none;

        &:focus, &:active{
          outline: none;
        }

        em{
          color: #83889E;
          line-height: 36px;
        }
      }
    }
  }
  .storyaction-wrapper{
    left: -140px;
    padding: 6px 17px;
    .storyaction-option{
      padding: 10px 3px 10px;
    }
  }
  .story-addStory{
    width: auto;
    position: relative;
    display: inline-block;
    padding: 5px 9px 5px;
    font-size: 12px;
    font-weight: 500;
    color: #1890FF;
    text-align: center;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #1890FF;
    transition: all 0.3s ease-in-out;

    &:hover{
      background-color: #1890FF;
      color: #fff;
    }
    &:focus, &:active{
      outline: none;
    }
  }
}
.releaseVersion-tableRow{
  padding: 15px 0 10px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  transition: all 0.2s ease-in-out;

  @for $i from 1 through $userInitialsColorsLength {
    &:nth-of-type(#{$userInitialsColorsLength}n + #{$i}){
      .userInitials{
        background-color: nth($userInitialsBackgroundColors, $i);
        color: nth($userInitialsColors, $i);
        border-color: nth($userInitialsBorderColor, $i);
      }
    }
  }

  &:last-of-type{
    border-bottom: none;
    .story-breakup{
      &:before{
        top: calc(100% - 8px) !important;
      }
    }
  }

  &:nth-of-type(1),
  &:nth-of-type(2),
  &:nth-of-type(3){
    .story-progress{

      .story-breakup{
        top: 30px;

        &:before{
          top: -6px !important;
        }
      }

      &:hover{
        .story-breakup{
          top: 20px;
        }
      }
    }
  }

  &:hover{
    background-color: #F8F9FA;
    .moreAction-bt{
      visibility: visible;
      opacity: 1;
    }
    .story-id, .sprint-name{
      .copy-bt, .edit-bt{
        display: inline-block;
        visibility: visible;
        opacity: 1;
      }
    }
    .story-summary{
      .editTitle{
        visibility: visible;
        opacity: 1;
      }
    }
    .rowCheckBox{
      visibility: visible;
      opacity: 1;
    }
    .versionStatus {
      .update-loader {
        background-color: #F8F9FA !important;
      }
    }
  }
}
.releaseTable{
  .tableHead-col1,
  .tableHead-col2,
  .tableHead-col3,
  .tableHead-col4,
  .tableHead-col5,
  .tableHead-col6,
  .tableHead-col7,
  .tableHead-col8,
  .tableHead-col9,
  .tableHead-col10,
  .tableHead-col11,
  .tableHead-col12{
    text-align: left
  }

  &.bulk-selection-on {
    .rowCheckBox{
      visibility: visible;
      opacity: 1;
    }
  }
}
.tableHead-col1,
.tableHead-col2,
.tableHead-col3,
.tableHead-col4,
.tableHead-col5,
.tableHead-col6,
.tableHead-col7,
.tableHead-col8,
.tableHead-col9,
.tableHead-col10,
.tableHead-col11,
.tableHead-col12{
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: center
}
.tableHead-col5{
  width: 8%;
}
.tableHead-col4{
  width: 16%;
}
.tableHead-col3{
  width: 5%;
}
.tableHead-col2{
  width: 4%;
}
.tableHead-col1{
  width: 30px;
  &.noCheck{
    width: 24px;
  }
}
.tableHead-col6{
  width: 7%;
}
.tableHead-col7{
  width: 7%;
}
.tableHead-col8{
  width: 10%;
  text-align: left;
}
.tableHead-col9{
  width: calc(38% - 30px);
  &.sm{
    width: calc(30% - 30px);
  }
}
.tableHead-col10{
  width: 9%;
}
.tableHead-col11{
  width: 23%;
}

.tableHead-col9, .tableHead-col4, .tableHead-col11{text-align: left}
.projectSprintDetail-wrapper{
  .sprintBacklogStories-block, .sprint-wrapper{
    .topInfo-section {
      width: 100%;
      height: auto;
      position: relative;
      display: inline-block;
      padding: 0;
      color: #3C3E49;
      top: 0;
      right: 0;
      // z-index: 130;
      margin-bottom: 12px;
      .topActionButtons{
        border-bottom: 1px solid rgba(0,0,0,.1);
        background-color: #F8F9FA;
        padding: 10px 10px 3px;
        border-radius: 12px 12px 0 0;
        .tableTitle {
          margin-right: 10px;
          font-size: 14px;
          font-weight: 500;
          color: #3C3E49;
          border-bottom: none;
          padding: 8px 2px 8px;
        }
        .sprint-sorting{
          margin-right: 7px;
          .selectedFilter {
            height: 33px;
            font-size: 13px;
            font-weight: 400;
            padding: 9px 11px;
          }
        }
        
        .integrationSection-search {
          width: 160px;
          float: left;
          .integrationSection-searchInput {
            height: 32px;
            padding: 0 8px 0 33px;
          }
        }
        .fliterButton-wapper {
          margin-right: 20px;
          .filterButton {
            font-weight: 400;
            background-color: transparent;
            padding: 8px 11px;
            height: 33px;
            .filterbutton-title{
              font-weight: 400;
            }
            &:hover{
              background-color: #ECEDF0;
            }
            &.buttonSelected{
              background-color: #F3EBFF;
              color: #651FFF;
            }
          }
        }
      }
    }
  }
  .sprintBacklogStories-block{
    &.collapseBacklog{
      width: 50px !important;
      border: none !important;
      .topInfo-section {
        .topActionButtons{
          height: 50px;
          background-color: #F3EBFF;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          .tableTitle, .integrationSection-search, .fliterButton-wapper, .sprint-sorting{
            display: none;
          }
          .collapse-expand-button{
            float: none;
            display: flex;
            margin-top: 8px;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .releaseVersion-table{ 
        width: 48px;
        border: 1px solid #ECEDF0;
        overflow: hidden;
        border-radius: 12px;
        background-color: #F8F9FA;
      }
      .releaseVersion-tableHead, .releaseVersion-tableContent{
        display: none;
      }
    }
  }
  .sprintStories-block {
    &.expandMore{
      width: calc(100% - 70px) !important;
    }
    &.fullexpand{
      width: calc(100% - 18px) !important;
    }
  }
  .releaseVersion-table{
    height: calc(100vh - 242px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius:2px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(60, 62, 73, 0.2);
      border-radius: 8px;
    }

    .hscroll{
      min-width: 1024px;
      height: 100%;
    }

    .releaseVersion-tableHead{
      position: sticky;
      top: 0;
      left: 0;
      z-index: 111;
      background: #fff;
    }

    .releaseVersion-tableContent{
      height: calc(100% - 47px);;
      overflow: unset;
      border-bottom: none;

      &.noBacklog{
        height: calc(100vh - 216px);
      }

      &::-webkit-scrollbar {
        width: 4px;
        border-radius:4px;
      }
      &::-webkit-scrollbar-track {
        background: #F8F9FA;
      }
      &::-webkit-scrollbar-thumb {
        background: #b4b2b2;
        opacity:0.2;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b2b2;
        opacity:0.2;
      }
    }
  }
  .ifNoSprint-exits {
    width: 82%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(71vh - 107px);
    justify-content: center;
    align-items: center;
}
}

.addSprintPopup{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index:1111;

  .addNewEpic-backdrop{
    width:100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:11;
    background-color: rgba(0,0,0,.3);
  }

  .addSprintPopup-wrapper{
    width: 100%;
    max-width: 540px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #fff;
    box-shadow: 0 10px 80px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px 30px;
    z-index: 12;

    .create-sprintLoader{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      z-index: 112;
      border-radius: 8px;

      img{
        max-width: 90px;
        height: auto;
      }
    }

    .closePopup{
      width: 24px;
      height: 24px;
      position: absolute;
      top: 19px;
      right: 20px;
      font-size: 12px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      cursor: pointer;
      z-index: 11;
    }
    .addSprintPopup-content, .addSprintForm, .addSprintForm-block{
      width: 100%;
      position: relative;
      display: inline-block;
      color: #000000;
    }

    .addSprintForm-block{
      padding: 10px 0;
    }
    .mainTitle{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .inputTitle{
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      padding: 0;
      margin: 0 0 7px;

      sup{
        color: red;
      }
    }
    .addSprint-input{
      width: 100%;
      height: 34px;
      position: relative;
      display: inline-block;
      font-size: 13px;
      padding: 0 12px;
      border-radius: 3px;
      border: 1px solid #DFE0E6;
      background-color: transparent;
      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
    }
    .disableCal{
      width: 100%;
      height: 38px;
      position: absolute;
      display: inline-block;
      z-index: 1;
      top: 35px;
      left: 0;
      cursor: not-allowed;
    }
    .cal-btn{
      position: absolute;
      display: inline-block;
      left: 0;
      top: 35px;
      text-align: right;
      width: 100%;
      height: 34px;

      .calIcon{
        position: relative;
        display: inline-block;
        cursor: pointer;
      }

      em.iconb-calendar{
        font-size: 18px;
        display: inline-block;
        height: 22px;
        vertical-align: middle;
        color: #83889E;
        margin: 8px 10px 0 0;
      }
      &.calendarDisabled{
        cursor: not-allowed;
      }
    }
    .addSprint-description{
      width: 100%;
      height: 90px;
      position: relative;
      display: inline-block;
      font-size: 13px;
      padding:13px;
      border-radius: 3px;
      border: 1px solid #DFE0E6;
      background-color: transparent;
      resize: none;
      overflow: hidden;
      overflow-y: auto;
      word-break: break-word;
      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
    }
    .actionWrapper{
      width: 100%;
      position: relative;
      display: inline-block;
      text-align: right;
      padding: 0;
      line-height:normal;
      margin-top: 34px;

      .actionButton{
        width: 95px;
        height: 34px;
        position: relative;
        float: right;
        font-weight: 500;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding: 10px 10px;
        border-radius: 3px;
        background-color: #6C00EA;
        border: 1px solid #6C00EA;
        cursor: pointer;
        margin-left: 30px;

        &.cancel{
          color: #000;
          background-color: #fff;
          border-color: #000;
          transition: all .2s ease-in-out;

          // &:hover{
          //   color: #fff;
          //   background-color: #6C00EA;
          //   border-color: #6C00EA;
          // }
        }

        &:active, &:focus{
          outline: none;
          box-shadow: none;
        }
        &:disabled{
          cursor: not-allowed;
          opacity:0.5;
        }
      }
    }
  }
}
.relatedTo-list {
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  padding: 12px;
  margin-bottom: 3px;
  background: #F5F5F5;
  border-radius: 4px;
  transition: all .2s ease-in-out;

  &.make-margin{
    margin: 7px 0;
  }

  .relatedTo-storyId {
    position: relative;
    display: inline-block;
    font-weight: 500;
  }

  .reset-related{
    width: 14px;
    height: 14px;
    position: absolute;
    top: 15px;
    right: 10px;
    display: inline-block;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
  }

  &.with-link:hover {
    cursor: pointer;
    color: #6c00ea;
    text-decoration: underline;
    .relatedTo-storyId{
      text-decoration: underline;
    }
  }
}

.error-1{
  position: absolute;
  right: 3px;
  bottom: -8px;
  display: inline-block;
  font-size: 10px;
  color: red;
}
.makeLayerDown{
  .projectSprintDetail-wrapper {
    .holding-wrapper {
      z-index: 118 !important;
    }
  }
}


$chartBackgroundColors: #E6F7FF, #FFF0F6, #ECEDF0, #F4EAFF;
$chartBackgroundColorsLength: length($chartBackgroundColors);
$chartColors: #1890FF, #F759AB, #83889E, #9F4DFF;
$chartColorsLength: length($chartColors);

.bulkaction-popUp{
  width:100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index:1112;
}
.bulkaction-popUpBackdrop{
  width:100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:11;
  background-color: rgba(0,0,0,.65);
}
.bulkaction-popUp-wrapper{
  width: 100%;
  max-width: 750px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #FFFFFF;
  box-shadow: 0 10px 80px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 35px;
  z-index:12;

  &.deleteStories{

    .selectedStoriesList{
      .selectedStoriesList-left{
        .story-NameBox{
          padding: 5px 3px;
          .selected-storyName{
            font-size: 14px;
          }
          .selected-storyEpicName{
            font-size: 11px;
            font-weight: 400;
          }
        }
      }
    }
  }
  &.without_list{
    max-width: 560px;
    border-radius: 12px;
    padding: 20px 25px;
  }
  &.duplicateStories{
    .selectedStoriesList{
      .selectedStoriesList-left{
        width: calc(100% - 120px);
        .story-NameBox{
          padding: 5px 3px;
          .selected-storyName{
            font-size: 14px;
          }
          .selected-storyEpicName{
            font-size: 11px;
            font-weight: 400;
          }
        }
      }
      .selectedStoriesList-right{
        width: 120px;
        .selected-storyEpicName{
          background: #f4eaff;
          border-radius: 3px;
          position: relative;
          padding: 3px 7px;
          color: #9f4dff;
          font-size: 9px;
          font-weight: 400;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .bulkaction-popUp-title{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 12px;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    color: #000;
  }
  .bulkaction-popUp-title2{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  .bulkaction-popUp-Text1{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 15px;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    color: #000;
    ul{
      margin: 0;
      padding: 15px 10px;
      li{
        display: block;
        padding: 5px 0px;
      }
    }
    .reason-list-block{
      width: 100%;
      position: relative;
      display: inline-block;
      ol{
        margin: 7px 0 0;
        padding-inline-start: 15px;
        li{
          padding:2px 0;
        }
      }
    }
  }

  .bulkaction-popUp-text{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 15px;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    color: #000;
  }
  .bulkaction-indicateInfo{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0;
    margin-bottom: 12px;
    margin-left: -3px;

    .indicateInfo-icon-box{
      width: 24px;
      position: relative;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      border-color: #6C00EA;
      height: 22px;

      .indicateInfo-typeIcon{
        width: 22px;
        height: 22px;
        font-size: 23px;
        position: relative;
        display: inline-block;
        text-align: center;
        color: #6c00ea;
        border-radius: 50%;
        line-height: 20px;
      }
    }
    .bulkaction-indicateInfoText{
      width: calc(100% - 58px);
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-left: 7px;
      font-size: 13px;
      font-weight: normal;
      color: #777777;
      font-style: italic;
    }
  }
  .selectedEpic-box{
    width: 100%;
    position: relative;
    padding: 0;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 4px;
    }

    .selectedEpic-list{
      width: auto;
      height: 29px;
      position: relative;
      display: inline-block;
      padding: 6px 10px;
      border-radius: 3px;
      margin: 0 15px 10px 0;

      &.featureList{
        background: #F4EAFF;
        color: #9F4DFF;
      }
      &.labelList{
        background: #ECEDF0;
        color: #83889E;
      }

      &.noList-selected{
        display: none;
      }

      .selectedEpic-name{
        width: calc(100% - 15px);
        position: relative;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        vertical-align: middle;
        text-align: left;
        padding-right: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .removeEpic-name{
        width: 15px;
        position: relative;
        display: inline-block;
        font-size: 9px;
        color: #9B9FB1;
        vertical-align: middle;
        cursor: pointer;
        height: 15px;
        text-align: center;
        padding-top: 2px;
      }
    }
  }

  .ifNoLabelFound{
    width: 100%;
    height: 178px;
    position: relative;
    display: inline-block;

    .forAdding-label{
      width: auto;
      position: relative;
      padding: 10px 4px;
      border: none;
      background-color: transparent;
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      border-radius: 0 5px 5px 0;

      &:focus, &:active{
        outline: none;
        box-shadow: none;
      }
    }
  }

  .bulkaction-popUp-epicListing{
    width: 100%;
    max-height: 278px;
    position: relative;
    display: inline-block;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      border-radius:2px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
  }

  .epicSelectionlist-wrapper{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 10px 0;

    .epicSelection-list{
      width: 100%;
      position: relative;
      display: inline-block;
      padding: 0 15px;

      .highlight-inactiveEpic{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding-right: 5px;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 111;
        cursor: not-allowed;
        border-radius: 4px;

        .inactive-text{
          float: right;
          padding: 5px 15px;
          background-color: #ffffff;
          font-size: 12px;
          border-radius: 4px;
          margin-top: 8px;
        }
      }

      &.itSelected{
        background-color: #F8F9FA;
      }

      .epicSelection-radio{
        width: 23px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 7px 0;
        margin-right: 5px;
        margin-top: 9px;

        .customRadioFor-epicSelection{
          width: 22px;
          height: 20px;
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle;

          .radio-toggleEpicSelection{
            width: 14px;
            height: 14px;
            position: relative;
            display: inline-block;
            border: 1px solid rgba(0,0,0,.15);
            background-color: #fff;
            border-radius: 20px;
            cursor: pointer;

            &:before{
              content: '';
              width:6px;
              height: 6px;
              position: absolute;
              top: 3px;
              left: 3px;
              border-radius: 50%;
              background-color: transparent;
              display: block;
            }
          }

          input[type='radio'].epic-selected-radio:checked + .radio-toggleEpicSelection{
            background-color: #fff;

            &:before{
              content: '';
              width: 6px;
              height: 6px;
              position: absolute;
              top: 3px;
              left: 3px;
              display: block;
              border-radius: 50%;
              background-color: #6C00EA;
            }
          }
        }
      }

      .epicSelection-check{
        width: 25px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 16px 0;
        margin-right: 9px;

        .customCheckFor-epicSelection{
          width: 22px;
          height: 20px;
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle;

          .check-toggleEpicSelection{
            width: 14px;
            height: 14px;
            position: relative;
            display: inline-block;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background-color: #fff;
            border-radius: 2px;
            cursor: pointer;
            margin-top: 5px;

            &:before{
              content: '';
              width:8px;
              height: 4px;
              position: absolute;
              top: 3px;
              left: 2px;
              border-left: 2px solid rgba(0,0,0,.015);
              border-bottom: 2px solid rgba(0,0,0,.015);
              display: block;
              transform: rotate(-47deg);
            }
          }

          input[type='checkbox'].epic-selected-checkbox:checked + .check-toggleEpicSelection{
            border-color: #6C00EA;
            background-color: #6C00EA;

            &:before{
              content: '';
              width: 8px;
              height: 4px;
              position: absolute;
              top: 3px;
              left: 2px;
              border-left: 2px solid #fff;
              border-bottom: 2px solid #fff;
              display: block;
              transform: rotate(-47deg);
            }
          }
        }
      }

      .epicSelection-epicName{
        max-width: calc(100% - 54px);
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 4px;
        margin-bottom: 0;
        cursor: pointer;

        .starIcon{
          width: 18px;
          height: 18px;
          display: inline-block;
          border-radius: 50%;
          color: #000000;
          font-size: 18px;
          text-align: center;
          line-height: 18px;
          vertical-align: middle;
        }
        .epicName{
          width: auto;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          padding: 3px 10px;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 8px;
          border-radius: 3px;
          color: #000000;

          //&.featureList{
          //  background: #F4EAFF;
          //  color: #9F4DFF;
          //}
          //&.labelList{
          //  background: #ECEDF0;
          //  color: #83889E;
          //}
        }
      }
    }
  }

  .moveSelectionlist-wrapper{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 10px;

    @for $i from 1 through $chartColorsLength {
      &:nth-of-type(#{$chartColorsLength}n + #{$i}){
        .moveSelection-list{
          .moveSelection-epicName{
            .moveName-initials{
              background-color: nth($chartBackgroundColors, $i);
              color: nth($chartColors, $i);
            }
          }
        }
      }
    }

    .moveDeviceList-wrapper{
      width: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 10px;

      &:last-of-type{
        margin-bottom: 0;
      }

      .moveSelection-list{
        padding: 3px 24px;
        border-bottom: 1px solid rgba(0,0,0,.1);
      }

      .deviceType{
        width: 100%;
        position: relative;
        display: inline-block;
        font-size: 14px;
        color: #3C3E49;
        font-weight: 500;
        margin: 7px 0 0;

        em{
          font-size: 18px;
          display: inline-block;
          height: 18px;
          margin-right: 3px;
          vertical-align: text-bottom;
        }
      }
    }

    .moveSelection-list{
      width: 100%;
      position: relative;
      display: inline-block;

      &.userNotActive{
        background-color: rgba(0,0,0,.05);
        //padding-left: 7px;
        border-radius: 4px;
        padding: 6px 0;
      }

      .moveSelection-radio{
        width: 23px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 7px 0;
        margin-right: 5px;
        margin-top: 9px;

        .customRadioFor-moveSelection{
          width: 22px;
          height: 20px;
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle;

          .radio-toggleMoveSelection{
            width: 14px;
            height: 14px;
            position: relative;
            display: inline-block;
            border: 1px solid rgba(0,0,0,.15);
            background-color: #fff;
            border-radius: 20px;
            cursor: pointer;

            &:before{
              content: '';
              width:6px;
              height: 6px;
              position: absolute;
              top: 3px;
              left: 3px;
              border-radius: 50%;
              background-color: transparent;
              display: block;
            }
          }

          input[type='radio'].move-selected-radio:checked + .radio-toggleMoveSelection{
            background-color: #fff;

            &:before{
              content: '';
              width: 6px;
              height: 6px;
              position: absolute;
              top: 3px;
              left: 3px;
              display: block;
              border-radius: 50%;
              background-color: #6C00EA;
            }
          }
        }
      }

      .moveSelection-check{
        width: 25px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 16px 0;
        margin-right: 0;

        .customCheckFor-moveSelection{
          width: 22px;
          height: 20px;
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle;

          .check-toggleMoveSelection{
            width: 14px;
            height: 14px;
            position: relative;
            display: inline-block;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background-color: #fff;
            border-radius: 2px;
            cursor: pointer;
            margin-top: 5px;

            &:before{
              content: '';
              width:8px;
              height: 4px;
              position: absolute;
              top: 3px;
              left: 2px;
              border-left: 2px solid rgba(0,0,0,.015);
              border-bottom: 2px solid rgba(0,0,0,.015);
              display: block;
              transform: rotate(-47deg);
            }
          }

          input[type='checkbox'].move-selected-checkbox:checked + .check-toggleMoveSelection{
            border-color: #6C00EA;
            background-color: #6C00EA;

            &:before{
              content: '';
              width: 8px;
              height: 4px;
              position: absolute;
              top: 3px;
              left: 2px;
              border-left: 2px solid #fff;
              border-bottom: 2px solid #fff;
              display: block;
              transform: rotate(-47deg);
            }
          }
        }
      }

      .moveSelection-epicName{
        max-width: calc(100% - 54px);
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 4px;
        margin-bottom: 0;
        cursor: pointer;

        .starIcon{
          width: 18px;
          height: 18px;
          display: inline-block;
          border-radius: 50%;
          background-color: #6C00EA;
          color: #fff;
          font-size: 11px;
          text-align: center;
          line-height: 18px;
          vertical-align: middle;
        }
        .project-icon{
          width: 28px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          margin: 14px 10px 14px 0;
          border: 1px solid #DFE0E6;
          border-radius: 3px;

          img{
            width: 16px;
            height: 16px;
          }
        }
        .moveName-initials{
          width: 28px;
          height: 28px;
          display: inline-flex;
          border-radius: 50%;
          font-size: 11px;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          margin-right: 10px;
        }
        .moveName, .moveName2{
          width: calc(100% - 38px);
          position: relative;
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          color: #212121;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .nameTitle{
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            color: #212121;
          }

          .ifUserDeallocated{
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-size: 11px;
            color: #212121;
            font-weight: 500;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 6px 14px;
            line-height: normal;
            margin-left: 10px;
          }
        }
        .moveName2{
          width:100%;
        }
      }
    }
  }

  .search-box{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0;
    margin-bottom: 25px;
    margin-top: 20px;

    .bulkaction-epicSearch{
      width: 100%;
      height: 52px;
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      color: #7d7d7d;
      background-color: #fff;
      padding: 0 45px 0 15px;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      border-radius: 5px;

      &:focus, &:active{
        outline: none;
        box-shadow: none;
      }
    }
    .bulkaction-epicSearchButton{
      width: 40px;
      height: 48px;
      position: absolute;
      top: 1px;
      right: 3px;
      border: none;
      background-color: #fff;
      z-index: 1;

      em{
        font-size: 18px;
        color: #83889E;
        line-height: 54px;
      }

      &:focus, &:active{
        outline: none;
        box-shadow: none;
      }
    }
  }

  .bulkaction-popUp-actionBtn{
    width: 100%;
    position: relative;
    display: inline-block;
    text-align: right;
    padding: 15px 0 0;
    border-radius: 0;
    line-height: normal;
    font-weight: 500;

    .bulkaction-popUp-cancel{
      width: 90px;
      height: 40px;
      position: relative;
      float: right;
      color: #6C00EA;
      font-size: 14px;
      text-align: center;
      padding: 12px 10px;
      background-color: transparent;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      margin-right: 30px;

      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
    }

    .bulkaction-popUp-submit{
      width: 170px;
      height: 40px;
      position: relative;
      float: right;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      border-radius: 2px;
      background-color: #6C00EA;
      border: 1px solid #6C00EA;
      cursor: pointer;

      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
      &:disabled{
        cursor: not-allowed;
        opacity:0.5;
      }
    }


    .bulkaction-popUp-cancel-s{
      width: 90px;
      height: 40px;
      position: relative;
      float: right;
      color: #333;
      font-size: 14px;
      text-align: center;
      padding: 12px 10px;
      background-color: transparent;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      margin-right: 30px;
      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
    }

    .bulkaction-popUp-submit-s{
      width: 170px;
      height: 40px;
      position: relative;
      float: right;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 11px 10px;
      border-radius: 2px;
      background-color: #6C00EA;
      cursor: pointer;

      &:active, &:focus{
        outline: none;
        box-shadow: none;
      }
      &:disabled{
        cursor: not-allowed;
        opacity:0.5;
      }
    }

  }

  .conditionForChild{
    width: auto;
    position: relative;
    float: left;
    margin-top: 12px;

    .conditionForChild-title{
      width: auto;
      position: relative;
      float: left;
      font-size: 14px;
      color: #8C8C8C;
      font-weight: 400;
    }
  }
  .forChildSelection-check{
    width: 17px;
    position: relative;
    float: left;
    padding: 0;
    margin-right: 8px;
    text-align: left;

    .customCheckFor-deleteSelection{
      width: 17px;
      height: 17px;
      position: relative;
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;

      .check-toggleDeleteSelection{
        width: 16px;
        height: 16px;
        position: relative;
        display: inline-block;
        border: 1px solid rgba(0,0,0,.15);
        background-color: #fff;
        border-radius: 2px;
        cursor: pointer;
      }

      input[type='checkbox'].delete-selected-checkbox:checked + .check-toggleDeleteSelection{
        border-color: #6C00EA;
        background-color: #6C00EA;

        &:before{
          content: '';
          width: 8px;
          height: 5px;
          position: absolute;
          top: 3px;
          left: 3px;
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: block;
          transform: rotate(-47deg);
        }
      }
    }
  }

  .ifNoSoriesForAccept{
    width: 100%;
    height: 120px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .selectedStory_list{
    width: 100%;
    position: relative;
    display: inline-block;
    padding-left: 70px;

    .bulkaction-popUp-Text1{
      font-size: 12px;
      line-height: 18px;
    }

    .selectedStoriesList-wrapper {
      .selectedStoriesList{
        padding: 7px 0px;
        width: 97%;
        .selectedStoriesList-left{

          .story-typeIcon{
            width: 32px;
            line-height: 32px;

            em{
              font-size: 14px;
            }
          }
          .story-NameBox{
            width: calc(100% - 32px);
            padding: 5px 10px;

            .selected-storyName{
              font-size: 13px;
            }
          }
        }
      }
    }
    
  }

  .selectedStoriesList-wrapper{
    width: 100%;
    max-height: 320px;
    position: relative;
    display: inline-block;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      border-radius:2px;
    }
    &::-webkit-scrollbar-track {
      background: #F8F9FA;
    }
    &::-webkit-scrollbar-thumb {
      background: #b4b2b2;
      opacity:0.2;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b4b2b2;
      opacity:0.2;
    }
  }

  .selectedStoriesList{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 15px 10px;
    border-bottom: 1px solid rgba(0,0,0,.1);

    &:last-of-type{
      border-bottom: none;
    }

    .selectedStoriesList-left{
      width: calc(100% - 56px);
      position: relative;
      display: inline-block;
      vertical-align: top;

      .story-typeIcon{
        width: 32px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        line-height: 32px;

        em{
          font-size: 18px;
          vertical-align: middle;
          color: #9B9FB1;
        }
      }
      .story-NameBox{
        width: calc(100% - 32px);
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding: 5px 10px;

        .selected-storyName{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: normal;
          word-break: break-all;
        }
        .selected-storyEpicName{
          width: 100%;
          position: relative;
          display: inline-block;
          font-size: 13px;
          font-weight: 600;
          color: #6C00EA;
          margin-top: 7px;
          word-break: break-all;
        }
      }
    }
    .selectedStoriesList-right{
      width: 56px;
      position: relative;
      display: inline-block;
      vertical-align: top;

      .deleteSelection-check{
        width: 40px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 5px 0;
        margin-left: 14px;
        border-left: 1px dashed rgba(0, 0, 0, 0.1);
        text-align: center;

        .customCheckFor-deleteSelection{
          width: 22px;
          height: 20px;
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: middle;

          .check-toggleDeleteSelection{
            width: 20px;
            height: 20px;
            position: relative;
            display: inline-block;
            border: 1px solid rgba(0,0,0,.15);
            background-color: #fff;
            border-radius: 2px;
            cursor: pointer;

            &:before{
              content: '';
              width:11px;
              height: 7px;
              position: absolute;
              top: 4px;
              left: 4px;
              border-left: 2px solid rgba(0,0,0,.015);
              border-bottom: 2px solid rgba(0,0,0,.015);
              display: block;
              transform: rotate(-47deg);
            }
          }

          input[type='checkbox'].delete-selected-checkbox:checked + .check-toggleDeleteSelection{
            border-color: #6C00EA;
            background-color: #6C00EA;

            &:before{
              content: '';
              width: 11px;
              height: 7px;
              position: absolute;
              top: 4px;
              left: 4px;
              border-left: 2px solid #fff;
              border-bottom: 2px solid #fff;
              display: block;
              transform: rotate(-47deg);
            }
          }
        }
      }
    }
  }
}

.storyBoard-column.cdk-drag-preview {
  background: #fff;
  padding: 0;
  .drag_column_header {
    position: relative;
    background-color: #ECEDF0;
    padding: 13px 15px 5px 35px;
    border-radius: 4px;
    height: 44px;
    .expand-collapse-btn {
      position: absolute;
      right: 0;
      height: 28px;
      width: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: calc(50% - 16px);
      cursor: pointer;
      border-radius: 4px;
      font-size: 12px;
      transition: all 0.5s;
      .iconb-left-arrow {
        line-height: 0;
        transition: all 0.5s;
      }
    }
  }
  .storycolumn {
    padding: 15px 10px;
  }
  .add-toDo.add-storyBtn {
    text-decoration: none;
    float: right;
    padding: 0;
    cursor: pointer;
    font-size: 12px;
    margin-top: 2px;
    color: #83889E;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    position: absolute;
    right: 31px;
    top: 18px;
  }
  .drag_column_header {
    .expand-collapse-btn {
      .elementHover-extra {
        display: none;
      }
    }
  }

  &.shrinked {
    padding: 0;
    width: 48px;
    .storycolumn {
      margin-top: 59px;
      background: #F8F9FA;
      border-radius: 6px;
      overflow: hidden;
      height: calc(100vh - 182px);
      .projectStory {
        opacity: 0;
        visibility: hidden;
      }
      &:hover{
        &::-webkit-scrollbar-thumb {
          background: #F8F9FA;
        }
      }
    }
    .add-toDo.add-storyBtn {
      display: none;
    }
    .epiccolumn {
      .projectEpic {
        display: none;
      }
    }
    &:first-of-type {
      .drag_column_header {
        padding-left: 0;
      }
    }
    .drag_column_header {
      padding-left: 0;
      padding-right: 0;
      padding-top: 80px;
      background: transparent;
      white-space: nowrap;
      .add-feature {
        display: none;
      }
      .featureStatus-toggle {
        display: none;
      }
      .toggle-title {
        writing-mode: vertical-rl;
        width: 48px;
        display: flex;
        align-items: center;
        color: #3C3E49;
        .showTotalCount {
          margin: 0;
          margin-top: 8px;
          color: #83889E;
          font-size: 11px;
        }
      }
      .expand-collapse-btn {
        width: 48px;
        height: 44px;
        right: 0;
        top: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: none;
        background: #ECEDF0;
        color: #3C3E49;
        .iconb-left-arrow {
          transform: rotate(180deg);
          line-height: 0;
        }
      }
    }
    .column_header {
      display: none;
    }
  }
}

@keyframes spinner-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contentWrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 0 10px 0 20px;

  .contentWrapper-inner {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
    .storyTitle-input {
      border: 1px solid #d0d2da;
      border-radius: 3px;
      margin: 0;
      width: 100%;
      min-height: 48px;
      line-height: 18px;
      &::-webkit-input-placeholder {
        line-height: 30px !important;
      }
      &::-moz-placeholder {
        line-height: 30px !important;
      }
      &:-ms-input-placeholder {
        line-height: 30px !important;
      }
      &::placeholder {
        line-height: 30px !important;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }

  .description-format-wrapper,
  .format-type-list,
  .format-type-title,
  .format-type-description {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .format-type-list {
    border-bottom: 1px solid #dfe0e6;
    &:last-of-type {
      border: none;
    }
  }
  .description-format-wrapper {
    border-radius: 3px;
    border: 1px solid #dfe0e6;
  }
  .format-type-title,
  .format-type-description {
    width: 60px;
    color: #83889e;
    font-size: 13px;
  }
  .dark-color {
    color: #000;
    font-weight: 500;
  }
  .format-type-description {
    width: calc(100% - 60px);
    vertical-align: top;
    border-left: 1px solid #dfe0e6;

    .elementHover-table {
      transform: none;
      left: 0;
      &:before {
        left: 17px;
      }
    }
    &:hover {
      .format-inout:disabled + .elementHover-table {
        visibility: visible;
        opacity: 1;
        bottom: -32px;
        transform: none;
      }
    }
  }
  .format-type-title {
    padding: 9px 8px;
    // &::before{
    //   content: ":";
    //   position: absolute;
    //   top: -1px;
    //   right: 4px;
    //   color: #83889E;
    // }
  }
  .format-inout {
    width: 100%;
    position: relative;
    display: block;
    min-height: 30px;
    background-color: #fff;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 0;
    border: none;
    color: #000000;
    resize: none;
    padding: 9px 15px;
    white-space: pre-line;
  
    &:focus, &:active{
      outline: none;
    }
    &::-webkit-input-placeholder {
      color: #f5222d;
    }
    &:-ms-input-placeholder {
      color: #f5222d;
    }
    &::placeholder {
      color: #f5222d;
    }
    // &::-webkit-input-placeholder {
    //   color: #c0c1c3;
    // }
    // &:-ms-input-placeholder {
    //   color: #c0c1c3;
    // }
    // &::placeholder {
    //   color: #c0c1c3;
    // }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.if-empty {
      &::-webkit-input-placeholder {
        color: #f5222d;
      }
      &:-ms-input-placeholder {
        color: #f5222d;
      }
      &::placeholder {
        color: #f5222d;
      }
    }

    &:empty {
      &.if-no-given {
        &:before {
          content: "<Conditions>";
        }
      }
      &.if-no-when {
        &:before {
          content: "<Action performed>";
        }
      }
      &.if-no-then {
        &:before {
          content: "<Expected results>";
        }
      }
      &.if-no-as-a {
        &:before {
          content: "<type of user>";
        }
      }
      &.if-no-i-want {
        &:before {
          content: "<perform some task>";
        }
      }
      &.if-no-so-that {
        &:before {
          content: "<achieve some goal>";
        }
      }
    }

    &.if-no-given,
    &.if-no-when,
    &.if-no-then,
    &.if-no-so-that,
    &.if-no-i-want,
    &.if-no-as-a {
      &:before {
        color: #f5222d;
      }
    }
  }

  .labelText {
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin: 0 0 10px 0;
    padding-top: 3px;

    .infoHoverIcon{
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 4px;
      vertical-align: middle;
      background-color: #999;
      border-radius: 50%;
      cursor: pointer;
      line-height: 12px;
      text-align: center;
      margin-right: 0;
      top: -1px;
    
      img{
        max-width: 8px;
        height: auto;
      }
      &.related_to_hover{
        .elementHover{
          width: 328px;
          top: 10px;
          transform: translateX(-28%);
          &:before {
            left: 28%;
          }
        }
        &:hover{
        .elementHover{
          transform: translateX(-28%);
        }
      }
      }
      .elementHover{
        top: -10px;
        line-height: 16px;
        text-align: left;
        padding: 5px 9px 5px;
        font-weight: 400;
        width: 200px;
        white-space: pre-wrap;
        bottom: auto;
      }
      &:hover{
        .elementHover{
          visibility: visible;
          opacity: 1;
          transform: translateX(-50%);
          top: calc(100% + 7px);
          bottom: auto;
        }
      }
    }

    &.forDes {
      font-size: 13px;
      font-weight: 400;
      color: #000;
      margin-bottom: 8px;
    }
    &.addtional-note {
      font-size: 13px;
      font-weight: 400;
      color: #000;
      margin: 0;
      padding-top: 10px;
      padding-bottom: 8px;
    }
    .disable-create-subtask {
      width: 26px;
      height: 26px;
      position: absolute;
      top: 0;
      right: -4px;
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: not-allowed;

      &.is-subtask-block-btn {
        right: 28px;
      }

      .elementHover-extra {
        width: 160px;
        color: #ffffff;
        font-size: 11px;
        letter-spacing: -0.01em;
        padding: 7px 12px 5px;
        border-radius: 4px;
        background-color: #3c3e49;
        display: block;
        position: absolute;
        top: 45px;
        right: -5px;
        opacity: 0;
        pointer-events: none;
        /* white-space: nowrap; */
        transition: all 0.3s ease;
        z-index: 111;

        &:before {
          content: "";
          width: 7px;
          height: 7px;
          display: block;
          position: absolute;
          top: -1px;
          right: 12px;
          transform: rotate(45deg) translateX(-50%);
          background-color: #3c3e49;
        }
      }

      &:hover .elementHover-extra {
        visibility: visible;
        opacity: 1;
        top: 32px;
      }
    }

    .saveAddedFiles {
      // width: 70px;
      position: absolute;
      float: right;
      top: 0;
      right: 36px;
      margin-top: 0;
      text-align: center;
      font-size: 12px;
      color: #fff;
      padding: 5px 12px;
      border: none;
      font-weight: 500;
      background-color: #651fff;
      border-radius: 3px;
      cursor: pointer;

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }

    .subtak-action-wrapper {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &.open {
      .subtak-buttons-dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
      .iconb-more-vert {
        line-height: 0;
        font-size: 14px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #83889e;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.3s;
        &:hover {
          background-color: #F8F9FA;
        }
      }
      .subtak-buttons-dropdown {
        position: absolute;
        right: 0;
        top: calc(100% + 3px);
        z-index: 10;
        background: #FFFFFF;
        border: 1px solid #DFE0E6;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
        border-radius: 4px;
        width: 188px;
        padding: 4px 10px;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        .subtask-btn {
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          -webkit-appearance: none;
          border: 0;
          background: transparent;
          padding: 9px 0;
          border-bottom: 1px solid #DFE0E6;
          font-size: 12px;
          line-height: 14px;
          color: #83889E;
          &.disabled, &[disabled] {
            opacity: 0.6;
            cursor: not-allowed;
          }
          &:last-child {
            border-bottom: 0;
          }
          em {
            margin-right: 8px;
            line-height: 0;
            &.iconb-bulk-accept {
              font-size: 14px;
            }
          }
        }
      }
    }

    .subtask-button {
      &.is-subtask-block-btn {
        right: 28px !important;
      }
    }
    .subtaskSorting-wrapper {
      &.is-subtask-block-btn {
        right: 60px !important;
      }
    }

    .subtask-button,
    .add-attach,
    .add-criteria {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: -4px;
      font-size: 22px;
      font-weight: 500;
      color: #83889e;
      cursor: pointer;
      border: 1px solid #fff;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;

      &:hover {
        font-size: 14px;
        background-color: #651fff;
        border: 1px solid #651fff;
        color: #fff;
      }
      .forFiletype {
        width: 1px;
        height: 1px;
        position: fixed;
        left: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
      }
    }
    .add-criteria {
      right: 4px;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    sub {
      font-size: 11px;
      color: #e84850;
      vertical-align: super;
    }
    .learnMore {
      position: relative;
      display: inline-block;
      color: #651fff;
      margin-left: 12px;
      cursor: pointer;
      text-decoration: underline;
      font-size: 14px;
      vertical-align: middle;
      line-height: 17px;
    }
  }

  .scrollableContentWrapper {
    max-height: 340px;
    overflow: auto;
    margin: 0 -10px;
    padding: 0 10px;
    border-radius: 3px;
    cursor: text;
    &:not(.hover-transparent) {
      cursor: inherit;
      &:hover {
        background-color: #e8e8e8;
      }
    }

    &.sm-height {
      max-height: 75px;
    }
    &.max-height-inherit {
      max-height: inherit;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(60, 62, 73, 0.2);
      border-radius: 100px;
      border: 4px solid #fff;
    }

    .custom-checkbox-wrapper {
      .custom-checkbox-row {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ol {
      padding-left: 20px;
      li {
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p {
      &:last-child {
        margin: 0;
        white-space: pre-line;
      }
    }
  }

  .showActivity {
    width: auto;
    height: 28px;
    position: absolute;
    top: 0;
    right: 0;
    // padding-left: 15px;
    font-size: 11px;
    color: #676b7e;
    text-align: center;
    background: #ecedf0;
    border-radius: 2px;
    border: none;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
    }
  }

  .demoVideo-Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 12px;
    line-height: 16px;
    color: #83889E;
    .icon {
      font-size: 16px;
      line-height: 0;
      margin-right: 12px;
      img {
        animation: spinner-anim 3s infinite linear;
      }
    }
  }

  .demoVideo-list {
    padding: 15px 0;
    .demoVideo-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .video-name {
        display: flex;
        align-items: center;
        color: #3C3E49;
        width: 70%;
        em {
          line-height: 0;
          color: #83889E;
          transform: rotate(40deg);
        }
        .name {
          padding-left: 8px;
          width: calc(100% - 16px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .file-display {
        display: flex;
        justify-content: flex-end;
        width: 30%;
        .download-file {
          margin-top: 6px;
          margin-right: 6px;
          text-decoration: none;
        }
        .file-name {
          color: #6200EA;
          font-size: 12px;
          line-height: 20px;
          padding: 1px 7px;
          border: 1px solid #ECEDF0;
          border-radius: 20px;
          max-width: calc(100% - 30px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }

  .criteria-list-wrapper,
  .criteria-list,
  .criteria-title {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .criteria-list {
    padding: 8px 0 10px;

    &:last-of-type {
      border-bottom: none;
      // padding: 15px 0 10px;
    }
  }
  .criteria-list-wrapper {
    padding-top: 10px;
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .criteria-title {
    .elementHover-table {
      transform: none;
      left: 0;
      &:before {
        left: 17px;
      }
    }
    &:hover {
      .criteria-title-input:disabled + .elementHover-table {
        visibility: visible;
        opacity: 1;
        bottom: -32px;
        transform: none;
      }
    }
  }
  .delete-criteria-hover {
    width: auto;
    color: #ffffff;
    font-size: 11px;
    letter-spacing: -0.01em;
    padding: 5px 9px 3px;
    border-radius: 4px;
    background-color: #3c3e49;
    display: block;
    position: absolute;
    bottom: -35px;
    right: 0;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    transition: all 0.3s ease;
    z-index: 111;

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      display: block;
      position: absolute;
      top: -1px;
      right: 12px;
      transform: rotate(45deg) translateX(-50%);
      background-color: #3c3e49;
    }
  }
  .delete-criteria:hover + .delete-criteria-hover {
    visibility: visible;
    opacity: 1;
    bottom: -25px;
  }
  .criteria-action-buttons {
    position: absolute;
    top: -5px;
    right: 0px;
    display: flex;
    .delete-criteria-button{
      position: relative;
      right: 0;
      top: 0;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .delete-criteria-button{
    width: 30px;
    height: 30px;
    position: absolute;
    top: -5px;
    right: 0px;
    display: inline-block;
    // &.is-copy-button {
    //   // right: 30px;
    // }
  }
  .delete-criteria {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #83889e;
    font-size: 16px;
    border: none;
    background-color: transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #000;
    }
    &:focus,
    &:active {
      outline: none;
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .criteria-title-input{
    width: calc(100% - 65px);
    position: relative;
    display: inline-block;
    font-size: 13px;
    color: #000;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 8px;

    &:disabled {
      cursor: not-allowed;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .ifReadOnly {
    top: 54px;
  }
  .descriptionInput {
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 13px;
    color: #000;
    //padding: 0 13px;
    //padding-left: 13px;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 23px;
    //margin-left: -13px;
    resize: none;
    white-space: pre-line;
    word-break: break-word;
    border: solid 1px #e9e9f0;
    transition: all 0.2s ease-in-out;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border: solid 1px #e9e9f0;
    }
  }
  .descriptionView {
    width: 100%;
    position: relative;
    z-index: 11;
    display: inline-block;
    font-size: 13px;
    color: #000;
    //padding:0 13px;
    //padding-left: 13px;
    border-radius: 4px;
    margin-bottom: 13px;
    border: solid 1px #dfe0e6;
    background-color: #ffffff;
    line-height: 23px;
    white-space: pre-line;
    word-break: break-word;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #e9e9f0;
      cursor: text;
      border: solid 1px #e9e9f0;
    }
  }
  .featureAndLabelWrapper {
    width: 100%;
    position: relative;
    display: inline-block;
    border-radius: 4px;
  }
  .featureAndLabelWrapper-list {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 9px;
    // margin-bottom: 10px;

    &:empty {
      display: none;
    }

    .infoBox {
      width: auto;
      //max-width: 95%;
      height: 28px;
      position: relative;
      display: inline-block;
      color: #ffffff;
      vertical-align: middle;
      margin: 8px 8px 0 0;
      padding: 4px 12px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      border-radius: 3px;

      .forRemove {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -100%;
        left: 0;
        padding-top: 3px;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        cursor: pointer;

        em {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          font-size: 12px;
          color: #055585;
          background-color: #ffffff;
          display: inline-block;
          line-height: 26px;
          margin-left: calc(50% - 9px);
        }
      }
      &:hover {
        transition: all 0.3s ease-in-out;

        .forRemove {
          top: 0;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
      }

      em {
        font-size: 20px;
        color: #ffffff;
        //vertical-align: middle;
        float: left;
      }
      .enteredText {
        width: auto;
        max-width: 675px;
        position: relative;
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        color: #fff;
        vertical-align: middle;
        padding: 2px 10px 0 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        float: left;
      }

      &.ifLabel {
        background-color: #ecedf0;
        .enteredText {
          color: #676b7e;
        }
      }
      &.ifFeature {
        background-color: #f0f5ff;
        .enteredText {
          color: #1d39c4;
        }
      }
    }
  }

  .inputfeature-label-name {
    // width: 100%;
    width: 175px;
    position: relative;
    display: inline-block;

    .addFeature {
      height: 30px;
      padding-top: 10px;
      vertical-align: middle;
      position: relative;
      display: inline-block;
      font-size: 13px;
      color: #757575;
      cursor: pointer;
    }

    .enterFeatureLabel-name {
      width: 100%;
      height: 30px;
      padding-top: 10px;
      vertical-align: middle;
      position: relative;
      display: inline-block;
      font-size: 13px;
      color: #262626;
      font-weight: 400;
      // padding: 0 13px;
      border: none;
      background-color: transparent;

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .selectFeatureName {
    width: 100%;
    max-height: 200px;
    position: absolute;
    left: 0;
    padding: 10px 15px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e9e9f0;
    background-color: #ffffff;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    overflow: auto;

    &.open-selectFeatureName {
      transition: all 0.3s ease-in-out;
      z-index: 111;
      visibility: visible;
      opacity: 1;
    }

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: -5px;
      right: 20px;
      background-color: #fff;
      z-index: 2;
      transform: rotate(45deg);
    }

    .selectFeatureName-list {
      //width: 100%;
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      color: #757575;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin: 10px 7px 10px 0;

      .if_featureInactive {
        width: 100%;
        height: 36px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: not-allowed;
        z-index: 111;
      }

      .label-class {
        width: 100%;
        position: relative;
        display: inline-block;
        color: #676b7e;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #ecedf0;
        border-radius: 4px;
        padding: 9px 24px;

        em {
          width: 20px;
          position: relative;
          display: inline-block;
          font-size: 16px;
          vertical-align: text-bottom;
          margin-right: 5px;
        }
      }
      .epic-class {
        width: 100%;
        position: relative;
        display: inline-block;
        color: #1d39c4;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #f0f5ff;
        border-radius: 4px;
        padding: 9px 24px;

        em {
          width: 20px;
          position: relative;
          display: inline-block;
          font-size: 16px;
          vertical-align: text-bottom;
          margin-right: 5px;
        }
      }
      &:hover {
        transition: all 0.3s ease-in-out;
        color: #6c00ea;
      }
    }
  }
}

.textAdded{
  position: relative;
  display: inline-block;
  background-color: green;
}

.textRemoved{
  position: relative;
  display: inline-block;
  background-color: red;
}
